import React from "react";

//Images:
import signupImage from "assets/images/login/signin-image.png";

//Material UI:
import { Box, Grid } from "@mui/material";

//Components:
import FormPasswordAccountViewComponent from "./component/FormPasswordAccountViewComponent";
import BaseFirstStepsComponent from "utils/components/base-first-steps/BaseFirstStepsComponent";

function PasswordAccountViewComponent() {
    return (
        <BaseFirstStepsComponent>
            <Grid
                container
                sx={{
                    pb: { xs: 0, md: 2 },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                }}
            >
                <Grid
                    item
                    md={12}
                    lg={"auto"}
                    sx={{ display: { xs: "none", md: "block" } }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                        }}
                    >
                        <img
                            title="Sign Up Image"
                            alt="Persona contestando una llamada encima del logo de Dalefon"
                            src={signupImage}
                            className={"signup-img"}
                        />
                    </Box>
                </Grid>

                <FormPasswordAccountViewComponent />
            </Grid>
        </BaseFirstStepsComponent>
    );
}

export default PasswordAccountViewComponent;
