import axiosAuthInstance from "../../../services/api/authentication";

import {
    GET_BRANDS_DEVICES,
    GET_BRANDS_DEVICES_ERROR,
} from "_models/redux/types";

export const listBrandsDevices = (params) => async (dispatch) => {
    try {
        const response = await axiosAuthInstance.get(
            `/api/leads/devices/brands`
        );

        const { data } = response;

        dispatch({
            type: GET_BRANDS_DEVICES,
            payload: data,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: GET_BRANDS_DEVICES_ERROR,
            payload: e || {},
        });
    }
};
