import React from "react";
import { Grid } from "@mui/material";

const ProgressBar = (props) => {
    const { bgcolor, completed } = props;

    const containerStyles = {
        height: 26,
        width: "100%",
        backgroundColor: "#FFFFFF",
        borderRadius: 50,
        marginBottom: 15,
        border: "1px solid rgb(213 205 226)",
    };

    const fillerStyles = {
        height: "100%",
        width: `${completed}%`,
        backgroundColor: bgcolor,
        borderRadius: "inherit",
        textAlign: "right",
    };

    const labelStyles = {
        padding: "0 10px 10px",
        color: "#f8f8f8",
        fontWeight: "bold",
        fontSize: 13,
    };

    return (
        <Grid style={containerStyles}>
            <Grid style={fillerStyles}>
                <span style={labelStyles}>{`${completed}%`}</span>
            </Grid>
        </Grid>
    );
};

export default ProgressBar;
