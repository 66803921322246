import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getOffersAction } from "_models/redux/get-offers/action";
import { useLocation, useNavigate } from "react-router-dom";
import { useProductsCardsHook } from "utils/components/flows-products/useProductsCardsHook";
import { useFlowsProductsHook } from "utils/components/flows-products/useFlowsProductsHook";
import FlowsProductsComponent from "utils/components/flows-products/FlowsProductsComponent";
import { cleanGetOffersAction } from "_models/redux/get-offers/clean";

const PurchaseProductsPage = (props) => {
    const history = useNavigate();
    const { state } = useLocation();

    const { getOffers, getOffersAction, cleanGetOffersAction } = props;
    const { getOffersObj } = getOffers || "undefined";
    const { data } = getOffersObj || "undefined";
    const { products } = data || "undefined";

    const [tabIndex, setTabIndex] = useState(0);
    const [tabSubcategory, setTabSubcategory] = useState(0);

    const [errorMessage, setErrorMessage] = useState();

    const [loadingCircular, setLoadingCircular] = useState(false);

    /*** Arreglos de subcategorías de los productos ***/

    const [classicSorted, setClassicSorted] = useState([]);
    const [plusSorted, setPlusSorted] = useState([]);

    const [shareSorted, setShareSorted] = useState([]);
    const [notShareSorted, setNotShareSorted] = useState([]);

    const params = {
        state,
        getOffers,
        products,
        tabIndex,
        setTabIndex,
        tabSubcategory,
        setTabSubcategory,
        errorMessage,
        setErrorMessage,
        loadingCircular,
        setLoadingCircular,
        classicSorted,
        setClassicSorted,
        plusSorted,
        setPlusSorted,
        shareSorted,
        setShareSorted,
        notShareSorted,
        setNotShareSorted,
        getOffersAction,
    };

    const { handleTabChange, handleSubcategoryChange } =
        useFlowsProductsHook(params);

    useEffect(() => {
        if (getOffersObj?.response?.status === 401) {
            history("/purchase");
        }
    }, [getOffersObj]);

    useEffect(() => {
        return () => {
            //limpieza
            cleanGetOffersAction();
        };
    }, []);

    const {} = useProductsCardsHook(params);

    return (
        <FlowsProductsComponent
            state={state}
            tabIndex={tabIndex}
            tabSubcategory={tabSubcategory}
            errorMessage={errorMessage}
            loadingCircular={loadingCircular}
            classicSorted={classicSorted}
            plusSorted={plusSorted}
            shareSorted={shareSorted}
            notShareSorted={notShareSorted}
            handleTabChange={handleTabChange}
            handleSubcategoryChange={handleSubcategoryChange}
            products={products}
            flow={"purchase"}
        />
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    getOffers: state.getOffers,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    getOffersAction,
    cleanGetOffersAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PurchaseProductsPage);
