import { Grid } from "@mui/material";
import React from "react";
import ForgotPasswordComponent from "./component/AddPhoneComponent";

const ForgotPasswordPageComponent = () => {
    return (
        <Grid container className={"forgot-password-page"}>
            <Grid item className={"forgot-password-component-container"}>
                <ForgotPasswordComponent />
            </Grid>
        </Grid>
    );
};

export default ForgotPasswordPageComponent;
