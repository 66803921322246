import React from "react";
import { Container, Grid } from "@mui/material";
import qrActivationImage from "assets/images/landings/esim-activation/header.png";
import Logo from "assets/images/logo.png";

const EsimActivationHeader = (props) => {
    const { esimDetails } = props || {};

    return (
        <Container>
            <Grid container className="qr-header">
                <Grid item xs={12} md={7}>
                    <img src={Logo} className={"logo"} />
                    <p>{esimDetails?.name || "Hola"}, tu eSIM</p>
                    <h1>ya está aquí</h1>
                </Grid>
                <Grid item xs={12} md={5}>
                    <img
                        src={qrActivationImage}
                        className={"qr-header-image"}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

export default EsimActivationHeader;
