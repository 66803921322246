import { useContext } from "react";
import { MyContext } from "modules/layout/context";
import * as React from "react";
import SubnavigationRoutes from "router/routes/subnavigation";
import CssBaseline from "@mui/material/CssBaseline";

const MyComponent = () => {
    const myContext = useContext(MyContext);

    const {
        sendMyMethod,

        /*** INICIO () ***/
        drawerWidth,
        openedMixin,
        closedMixin,
        DrawerHeader,
        AppBar,
        Drawer,
        /*** FIN () ***/

        /*** INICIO () ***/
        activeStyle,
        activeClassName,
        theme,
        open,
        setOpen,
        openMenu,
        setOpenMenu,
        handleClick,
        handleDrawerOpen,
        handleDrawerClose,
        /*** FIN () ***/
    } = myContext || {};

    return (
        <div>
            <CssBaseline />
            <SubnavigationRoutes />
        </div>
    );
};

export default MyComponent;
