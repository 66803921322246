import { connect } from "react-redux";

import { Grid } from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";

import PrepaidAuthnInvoicePresentational from "./components/PrepaidAuthnInvoicePresentational";

import { usePrepaidAuthnInvoiceContainer } from "./hooks/usePrepaidAuthnInvoiceContainer";

import { PrepaidAuthnInvoiceContext } from "./data/contexts/prepaidAuthnInvoiceContext";

import { getStoredLegalsAction } from "_models/redux/get-stored-legals/action";
import { cleanGetStoredLegalsAction } from "_models/redux/get-stored-legals/clean";

import { postPrepaidAuthnInvoiceAction } from "_models/redux/post-prepaid-authn-invoice/action";
import { cleanPostPrepaidAuthnInvoiceAction } from "_models/redux/post-prepaid-authn-invoice/clean";

import { listRFCAction } from "_models/redux/legal-data/action";
import { cleanGetListRfcAction } from "_models/redux/legal-data/clean";

const PrepaidAuthnInvoicePage = (props) => {
    const location = useLocation();
    const search = location.search;
    const searchParams = new URLSearchParams(search);
    const id_transaction = searchParams.get("it");

    const { prepaidAuthnInvoiceValues } = usePrepaidAuthnInvoiceContainer(
        props,
        id_transaction
    );

    return (
        <PrepaidAuthnInvoiceContext.Provider value={prepaidAuthnInvoiceValues}>
            <Grid>
                {/* <h1>PrepaidAuthnInvoicePage</h1> */}
                <PrepaidAuthnInvoicePresentational />
            </Grid>
        </PrepaidAuthnInvoiceContext.Provider>
    );
};

const mapStateToProps = (state) => ({
    getStoredLegals: state?.getStoredLegals,
    postPrepaidAuthnInvoice: state?.postPrepaidAuthnInvoice,

    listRFC: state?.listRFC,
});

const mapDispatchToProps = {
    getStoredLegalsAction,
    cleanGetStoredLegalsAction,

    postPrepaidAuthnInvoiceAction,
    cleanPostPrepaidAuthnInvoiceAction,

    listRFCAction,
    cleanGetListRfcAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrepaidAuthnInvoicePage);
