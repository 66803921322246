export const boxContainerCardNoPlans = (styleCard) => {
    const styleBg = () => {
        switch (styleCard) {
            case "Lite":
                return "linear-gradient( 9deg, rgb(61, 39, 112) 0%, rgb(99, 37, 234) 100% )";

            case "Classic":
                return "linear-gradient(150deg, #6917A2 0%, #3D2770 100%)";

            case "Plus":
                return "linear-gradient(4deg, rgba(14, 4, 48, 1) 0%, rgba(59, 33, 93, 1) 100%)";

            default:
                break;
        }
    };
    return {
        textAlign: "center",
        px: 0,
        pt: 3,
        pb: 1,
        background: styleBg,
    };
};

export const typographyBestSelling = {
    textAlign: "center",
    mt: -7,
    mx: { xs: 8, md: 8, lg: 10 },
    py: 1.5,
    px: 2,
    fontWeight: "bold",
    position: "relative",
    zIndex: 1,
};

export const typographyDuration = {
    fontSize: 35,
    fontWeight: "bold",
};

export const typographyBeforePrice = {
    fontSize: 15,
    mb: 2.5,
    fontWeight: "500",
};

export const typographyPrice = {
    fontSize: 85,
    fontWeight: "bold",
    lineHeight: 1,
};

export const typographyPricePerMonth = {
    color: "#6df1fe",
    fontSize: 15,
    mb: 2,
    mt: 0.5,
    fontWeight: "600",
};

export const typographyPlusGB = {
    fontSize: 23,
    lineHeight: 1,
    fontWeight: "500",
};

export const boxContainerImg = {
    textAlign: "right",
    p: 0,
    mr: { xs: -2.8, md: -3 },
    my: -5.5,
};

export const boxContainerNoShareAndShareWifi = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 1,
    mt: 0.5,
    mb: 1.5,
};

export const typographyNoShareAndShareWifi = {
    fontSize: 14,
    color: "white",
};

export const boxContainerMinAndSMS = {
    display: "flex",
    justifyContent: "center",
    gap: 1,
    alignItems: "center",
    mb: 4,
    fontSize: 14,
};

export const boxContainerButton = {
    display: "flex",
    justifyContent: "center",
    mb: 2,
};

export const typographyNote = { fontSize: 9, mb: 2, mt: 1, mx: 2 };
