import React, { useState } from "react";
import {
    Button,
    Grid,
    TextField,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
} from "@mui/material";
import updateImage from "assets/images/modals/update-client-data.png";
import responsiveUpdateImage from "assets/images/modals/update-client-data-responsive.png";

const ModalUpdateClientDataComponent = (props) => {
    const { isLoading, setIsLoading, setIsDialogOpen } = props || {};

    const [gender, setGender] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [optionalEmail, setOptionalEmail] = useState("");
    const [genderError, setGenderError] = useState("");
    const [dateOfBirthError, setDateOfBirthError] = useState("");

    const handleGenderChange = (event) => {
        setGender(event.target.value);
    };

    const handleOptionalEmailChange = (event) => {
        setOptionalEmail(event.target.value);
    };

    const handleUpdateData = () => {
        // Restablecer los mensajes de error
        const errors = {
            gender: "",
            dateOfBirth: "",
        };

        // Validación del campo de género
        if (!gender) {
            errors.gender = "Este campo es obligatorio";
        }

        // Validación del campo de fecha de nacimiento
        if (!dateOfBirth) {
            errors.dateOfBirth = "Este campo es obligatorio";
        }

        // Validación de fecha de nacimiento (no más de 100 años atrás).
        const selectedDate = new Date(dateOfBirth);
        const maxDate = new Date();
        maxDate.setFullYear(maxDate.getFullYear() - 100);

        if (selectedDate > new Date() || selectedDate < maxDate) {
            errors.dateOfBirth = "La fecha no es válida.";
        }

        // Mostrar todos los mensajes de error
        setGenderError(errors.gender);
        setDateOfBirthError(errors.dateOfBirth);

        if (errors.gender || errors.dateOfBirth) {
            setIsLoading(false);
            return;
        }

        // Si no hay errores, continuar con la actualización de datos
        setIsLoading(true);

        // Preparar el objeto de datos para enviar
        const dataToUpdate = {
            gender,
            birthdate: dateOfBirth,
        };

        // Agregar el correo electrónico solo si no está vacío
        if (optionalEmail) {
            dataToUpdate.email = optionalEmail;
        }

        // Continuar con la actualización de datos
        props.onDataUpdate(dataToUpdate);
    };

    return (
        <Grid container>
            <Grid item xs={12} md={6} className="left-column">
                <h1>Actualiza</h1>
                <h1>tus datos</h1>
                <img src={updateImage} alt="Update Image" />
            </Grid>
            <Grid item xs={12} md={6}>
                <h1 className="modal-title">Actualiza tus datos</h1>
                <Grid container>
                    <Grid item xs={6} md={12}>
                        <h3 className="modal-subtitle">¿Cuál es tu género?</h3>
                        <FormControl mb={2}>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={gender}
                                onChange={handleGenderChange}
                            >
                                <FormControlLabel
                                    value="F"
                                    control={<Radio />}
                                    label="Mujer"
                                    sx={{ color: "white" }}
                                />
                                <FormControlLabel
                                    value="M"
                                    control={<Radio />}
                                    label="Hombre"
                                    sx={{ color: "white" }}
                                />
                                <FormControlLabel
                                    value="X"
                                    control={<Radio />}
                                    label="No binario"
                                    sx={{ color: "white" }}
                                />
                                <FormControlLabel
                                    value="-"
                                    control={<Radio />}
                                    label="Prefiero no responder"
                                    sx={{ color: "white" }}
                                />
                            </RadioGroup>
                            {genderError && (
                                <span style={{ color: "red" }}>
                                    {genderError}
                                </span>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={12} className="modal-image">
                        <img
                            src={responsiveUpdateImage}
                            alt="Responsive Update Image"
                        />
                    </Grid>
                </Grid>
                <div>
                    <TextField
                        id="date"
                        label="Fecha de nacimiento"
                        type="date"
                        className="modal-textfield"
                        value={dateOfBirth}
                        onChange={(e) => setDateOfBirth(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {dateOfBirthError && (
                        <div style={{ color: "red" }}>{dateOfBirthError}</div>
                    )}
                </div>

                <TextField
                    fullWidth
                    className="modal-textfield"
                    id="optionalEmail"
                    variant="outlined"
                    placeholder="Correo electrónico (opcional)"
                    value={optionalEmail}
                    onChange={handleOptionalEmailChange}
                />
                <Grid container mt={3}>
                    <Grid
                        item
                        xs={6}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                    >
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            className={"secondary-button"}
                            onClick={() => setIsDialogOpen(false)}
                        >
                            Ahora no
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                    >
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            className={"button"}
                            onClick={handleUpdateData}
                        >
                            {isLoading ? "Cargando..." : "Actualizar"}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

/** Exporta el componente **/
export default ModalUpdateClientDataComponent;
