import React from "react";
import { connect } from "react-redux";
import { PurchaseContext } from "./context";
import PurchaseComponent from "./PurchaseComponent";
import { loginAction } from "_models/redux/login/action";
import { HomeContainerController } from "modules/purchase/hook/homeContainerController";

const PurchaseContainer = ({ loginAction, login }) => {
    const { loginValues } = HomeContainerController({ loginAction, login });

    return (
        <PurchaseContext.Provider value={loginValues}>
            <PurchaseComponent />
        </PurchaseContext.Provider>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    login: state.login,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    loginAction,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(PurchaseContainer);
