import React from "react";
import moment from "moment";

function Modal(props) {
    const { item, setOpenModal } = props || undefined;
    return (
        <div className="modalBackground">
            <div className="modalContainer">
                <div className="titleCloseBtn">
                    <button
                        onClick={() => {
                            setOpenModal(false);
                        }}
                    >
                        X
                    </button>
                </div>
                <div className="modal-title">
                    <h1>{item?.title}</h1>
                </div>
                <div className="modal-body">
                    <p>{item?.description}</p>
                    <small>
                        Vigencia hasta el {moment(item?.valid_to).format("LL")}
                    </small>
                </div>
                <div className="modal-footer">
                    <button>¡Obténlo ahora!</button>
                </div>
            </div>
        </div>
    );
}

export default Modal;
