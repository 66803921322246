import React from "react";
import { Container, Grid } from "@mui/material";
import promotionsImage from "assets/images/promotions/promotions-header.png";

const PromotionsHeader = (props) => {
    return (
        <div className={"header"}>
            <Container>
                <Grid container className="promotions-header">
                    <Grid item xs={12} md={8} className="promo-text">
                        <h1>¡Descubre nuestras promociones!</h1>
                        <p>
                            Aprovecha las <span>mejores</span> ofertas que
                            Dalefon tiene para ti
                        </p>
                    </Grid>
                    <Grid item xs={12} md={4} className="promo-image">
                        <img
                            src={promotionsImage}
                            className={"promotions-header-image"}
                        />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default PromotionsHeader;
