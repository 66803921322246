import React, { useContext, useState, useEffect } from "react";

//React router dom:
import { useNavigate } from "react-router-dom";

//Context:
import { LoginContext } from "../context";

//Images:
import loginImage from "assets/images/login/login-image.png";

//Css:
import "../../../assets/styles/css/login/login.css";

//Material UI:
import { Box, Grid, IconButton, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import VisibilityIcon from "@mui/icons-material/Visibility";

//Styles MUI:
import {
    boxFormTitle,
    containerFormLogin,
    containerImageLogin,
    gridContainerLogin,
    iconArrowBackIos,
    typographyFormTitle,
} from "../styles-mui/loginStyleMUI";

//Components:
import CustomButtonComponent from "utils/templates/CustomButtonComponent";
import BaseFirstStepsComponent from "utils/components/base-first-steps/BaseFirstStepsComponent";
import FieldComponent from "widgets/field/component";
import ErrorMessageComponent from "widgets/errorMessage/component";
import SignUpLinkComponent from "widgets/signup/component";

const LoginComponent = () => {
    const loginContext = useContext(LoginContext);
    const history = useNavigate();
    const { pressed } = useState(false);

    const { loading } = loginContext || {
        variant: undefined,
        progress: undefined,
    };

    const [shownPassword, setShownPassword] = useState("password");

    const setPasswordFieldType = () => {
        if (shownPassword === "text") {
            setShownPassword("password");
        } else {
            setShownPassword("text");
        }
    };

    useEffect(() => {
        window.onpopstate = () => {
            if (pressed) history(`/login`);
            else history("/");
        };
    }, [pressed]);

    return (
        <BaseFirstStepsComponent>
            <Grid container sx={gridContainerLogin}>
                <Grid
                    item
                    md={12}
                    lg={"auto"}
                    sx={{ display: { xs: "none", lg: "block" } }}
                >
                    <Box sx={containerImageLogin}>
                        <img
                            title="Login"
                            alt="Login"
                            src={loginImage}
                            className={"image-login"}
                        />
                    </Box>
                </Grid>

                <Grid item md={12} lg={5} xl={4} sx={containerFormLogin}>
                    <form
                        noValidate
                        autoComplete="off"
                        onSubmit={loginContext.handleSubmit(
                            loginContext.sendLogin
                        )}
                    >
                        <Box sx={boxFormTitle}>
                            <a href="/client">
                                <ArrowBackIosIcon sx={iconArrowBackIos} />
                            </a>
                            <Typography sx={typographyFormTitle}>
                                Inicia sesión
                            </Typography>
                        </Box>

                        <Typography
                            className={"login-instruction-container"}
                            sx={{ mt: 2, mb: 4, textAlign: "start" }}
                        >
                            Ingresa tus datos y <span>dale</span> más megas a tu{" "}
                            <span>fon.</span>
                        </Typography>

                        <Box sx={{ mb: 4 }}>
                            <FieldComponent
                                fullWidth={true}
                                fieldContext={loginContext}
                                fielEndIcon=""
                                fieldStartIcon=""
                                fieldClassName="user-phone-number-textfield"
                                fieldName="direct_number"
                                fieldLabel="Número celular"
                                fieldPlaceholder="Número Dalefon a 10 dígitos"
                                fieldDisabled={false}
                                fileldDefaultValue=""
                                fieldRequired={{
                                    required:
                                        "El número telefónico es requerido",
                                }}
                                fieldError={
                                    <ErrorMessageComponent
                                        errorParams={loginContext || {}}
                                        errorType={"direct_number"}
                                    />
                                }
                            />
                        </Box>

                        <Box sx={{ mb: 3 }}>
                            <FieldComponent
                                fullWidth={true}
                                fieldType={shownPassword}
                                fieldContext={loginContext}
                                fielEndIcon={
                                    <IconButton>
                                        <VisibilityIcon />
                                    </IconButton>
                                }
                                fieldStartIcon=""
                                fieldClassName="user-password-textfield"
                                fieldName="password"
                                fieldLabel="Contraseña"
                                fieldPlaceholder="Contraseña"
                                fieldDisabled={false}
                                fileldDefaultValue=""
                                fieldRequired={{
                                    required: "La contraseña es requerida",
                                }}
                                fieldError={
                                    <ErrorMessageComponent
                                        errorParams={loginContext || {}}
                                        errorType={"password"}
                                    />
                                }
                                fieldEndAdornmentCallback={setPasswordFieldType}
                            />
                        </Box>

                        <Box>
                            <CustomButtonComponent
                                async={true}
                                loading={loading}
                                bgColor={"violet"}
                                text={"Iniciar sesión"}
                            />
                        </Box>

                        <Box
                            className={"forgot-password-link-container"}
                            sx={{ textAlign: "center", mt: 2 }}
                        >
                            <a
                                href="/forgot-password"
                                className={"forgot-password-link"}
                            >
                                Olvidé mi contraseña
                            </a>
                        </Box>

                        <Box
                            className={"login-register"}
                            sx={{ textAlign: "center" }}
                        >
                            <span
                                className={
                                    loginContext.loginResponseCls.container ||
                                    ""
                                }
                            >
                                <h5
                                    className={
                                        loginContext.loginResponseCls.text || ""
                                    }
                                >
                                    {loginContext.loginResponse || ""}
                                </h5>
                            </span>
                        </Box>

                        <SignUpLinkComponent />
                    </form>
                </Grid>
            </Grid>
        </BaseFirstStepsComponent>
    );
};

export default LoginComponent;
