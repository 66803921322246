export const gridItemContainerCard = {
    backgroundColor: "white",
    borderRadius: 10,
    p: 4
};

export const typographyTitleCard = {
    fontSize: 24,
    fontWeight: "bold",
    fontFamily: "Poppins-Medium",
    textAlign: "left",
    mb: 2,
};

export const typographySubtitleCard = {
    fontSize: 16,
    textAlign: "left",
    mb: 2,
};