import ImeiSimResponseComponent from "./LeadImeiSimResponseComponent";
import ImeiEsimResponseComponent from "./LeadImeiEsimResponseComponent";
import ImeiIncompatibleResponseComponent from "./LeadImeiIncompatibleResponseComponent";
import ImeiNotFoundResponseComponent from "./LeadImeiNotFoundResponseComponent";

const ImeiResponseComponent = ({ compatibility }) => {
    // SIM => 1
    // ESIM => 2
    // INCOMPATIBLE => 3
    // NOT FOUND => 4

    return compatibility === "sim" ? (
        <ImeiSimResponseComponent />
    ) : compatibility === "esim" ? (
        <ImeiEsimResponseComponent />
    ) : compatibility === "incompatible" ? (
        <ImeiIncompatibleResponseComponent />
    ) : compatibility === "not-found" ? (
        <ImeiNotFoundResponseComponent />
    ) : null;
};

export default ImeiResponseComponent;
