import {
    GET_PROMOTIONS,
    GET_PROMOTIONS_ERROR,
    GET_PROMOTIONS_CLEAN,
    GET_PROMOTIONS_ERROR_CLEAN,
} from "_models/redux/types";

const initialState = {
    getPromotionsObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PROMOTIONS:
            return {
                ...state,
                getPromotionsObj: action.payload,
                loading: true,
            };
        case GET_PROMOTIONS_ERROR:
            return {
                ...state,
                getPromotionsObj: action.payload,
                loading: false,
            };
        case GET_PROMOTIONS_CLEAN:
        case GET_PROMOTIONS_ERROR_CLEAN:
            return {
                ...state,
                getPromotionsObj: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
