import { GET_LANDING_ESIM, GET_LANDING_ESIM_ERROR } from "_models/redux/types";

import axiosAuthInstance from "services/api/authentication";

export const getLandingEsimAction = (params) => async (dispatch) => {
    const { uuid, order } = params || {};

    try {
        const response = await axiosAuthInstance.get(
            `/api/payments/${uuid}/orders/${order}/esim-details`
        );

        const { data, status } = response || {};

        dispatch({
            type: GET_LANDING_ESIM,
            status: status || {},
            payload: data,
        });
    } catch (e) {
        console.error(e);

        const { response } = e || {};
        const { data, status } = response || {};

        dispatch({
            type: GET_LANDING_ESIM_ERROR,
            status: status || {},
            payload: data || {},
        });
    }
};
