import {
    INITREGISTER,
    INITREGISTER_ERROR,
    INITREGISTER_CLEAN,
    INITREGISTER_CLEAN_ERROR,
} from "_models/redux/types";

const initialState = {
    initRegisterObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case INITREGISTER:
        case INITREGISTER_CLEAN:
            return {
                ...state,
                initRegisterObj: action.payload,
                loading: true,
            };
        case INITREGISTER_ERROR:
        case INITREGISTER_CLEAN_ERROR:
            return {
                ...state,
                initRegisterObj: action.payload,
                loading: false,
            };
        case INITREGISTER_CLEAN:
        case INITREGISTER_CLEAN_ERROR:
            return {
                ...state,
                initRegisterObj: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
