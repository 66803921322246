import { useEffect } from "react";

export const useFlowsProductsHook = (props) => {
    const {
        state,
        getOffers,
        setTabIndex,
        setTabSubcategory,
        setErrorMessage,
        setLoadingCircular,
        getOffersAction,
    } = props || {};

    useEffect(() => {
        const { loading, getOffersObj } = getOffers || {};

        if (!loading) {
            const { name } = getOffersObj || {};
            if (name === "AxiosError") {
                setLoadingCircular(false);
                setErrorMessage(
                    "Por el momento no se encontraron paquetes, inténtelo más tarde."
                );
            } else {
                setErrorMessage("");
            }
        } else {
            const { data } = getOffersObj || {};
            if (typeof data != "undefined") {
                setLoadingCircular(false);
                const { products } = data || {};

                if (
                    typeof products != "undefined" &&
                    Array.isArray(products) &&
                    products.length === 0
                ) {
                    setErrorMessage(
                        "Por el momento no se encontraron paquetes, inténtelo más tarde."
                    );
                } else {
                    setErrorMessage("");
                }
            } else {
                setErrorMessage("");
            }
        }
    }, [getOffers]);

    useEffect(() => {
        setLoadingCircular(true);
        getOffersAction(state);
    }, []);

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
        if (newTabIndex === 0) {
            setTabSubcategory(0);
        } else {
            setTabSubcategory(1);
        }
    };
    const handleSubcategoryChange = (event, newTabSubcategoryIndex) => {
        setTabSubcategory(newTabSubcategoryIndex);
    };

    return { handleTabChange, handleSubcategoryChange };
};
