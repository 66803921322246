import React, { useEffect } from "react";
import { Grid, InputAdornment, TextField } from "@mui/material";
import { useField } from "./hook";

const FieldComponent = (props = {}) => {
    const {
        fullWidth,
        fieldContext,
        fielEndIcon,
        fieldStartIcon,
        fieldClassName,
        fieldName,
        fieldLabel,
        fieldPlaceholder,
        fieldDisabled,
        fileldDefaultValue,
        fieldRequired,
        fieldError,

        fieldType,
        fieldEndAdornmentCallback,
    } = props;

    const {} = useField({});

    return (
        <Grid item xs={12} className={"custom-field"}>
            <TextField
                fullWidth={fullWidth ? true : false}
                type={typeof fieldType == "undefined" ? "text" : fieldType}
                className={"custom-textfield " + fieldClassName || ""}
                label={fieldLabel || ""}
                placeholder={fieldPlaceholder || ""}
                disabled={fieldDisabled}
                defaultValue={fileldDefaultValue || ""}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {fieldStartIcon || ""}
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            onClick={
                                typeof fieldEndAdornmentCallback != "undefined"
                                    ? () => fieldEndAdornmentCallback()
                                    : null
                            }
                        >
                            {fielEndIcon || ""}
                        </InputAdornment>
                    ),
                }}
                {...fieldContext.register(fieldName || "", fieldRequired || {})}
            />
            {fieldError}
        </Grid>
    );
};

export default FieldComponent;
