import { useContext } from "react";

import { Grid, Box, Typography, Button } from "@mui/material";

import SIMCompatible from "assets/images/template/sim-compatible.png";

import HelperFormInputComponent from "utils/helpers/forms/inputs/helper-form-input-component";

import { LeadContext } from "../../data/contexts/leadContext";

const ImeiSimResponseComponent = () => {
    const leadContext = useContext(LeadContext);
    const { dispatchLeadData } = leadContext || {};

    const {
        leadData,
        formValues,
        municipalityOnChangeHandler,
        neighborhoodOnChangeHandler,
        municipalityOnFocusHandler,
        neighborhoodOnFocusHandler,
        stateOnChangeHandler,
        stateOnFocusHandler,
        formSubmmited,
    } = leadContext || {};
    const {
        inputs,
        municipalitiesOptions,
        neighborhoodsOptions,
        municipalityOptionSelected,
        neighborhoodOptionSelected,
        stateOptionSelected,
    } = leadData || {};
    const { register, control, errors } = formValues || {};
    const { shipmentInputs } = inputs || {};

    return (
        <Grid
            container
            className="lead-recovery-wrapper lead-container-congratulations"
        >
            <Grid item md={6} xs={12} className="lead-recovery-imei-section">
                <Box
                    sx={{
                        py: { xs: 2, md: 4 },
                        px: { xs: 0, md: 6 },
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: { xs: 25, md: 35 },
                            textAlign: "center",
                        }}
                    >
                        <span className="poppins-semibold-font">
                            ¡Felicidades!
                        </span>
                    </Typography>

                    <Typography
                        sx={{
                            fontSize: { xs: 18, md: 25 },
                            textAlign: "center",
                        }}
                    >
                        <span className="poppins-font">
                            Tu equipo es compatible con{" "}
                        </span>
                        <span className="poppins-semibold-font">SIM</span>
                    </Typography>

                    <Box
                        className="lead-recovery-imei-element"
                        sx={{
                            display: { xs: "none", md: "flex" },
                            my: 2,
                            textAlign: "center",
                        }}
                    >
                        <img
                            src={SIMCompatible}
                            alt="Imagen de incompatibilidad"
                        />
                    </Box>
                </Box>
            </Grid>
            <Grid
                item
                md={6}
                xs={12}
                className="lead-recovery-section"
                sx={{
                    background: "#ffffff",
                    borderEndEndRadius: "20px",
                    borderStartEndRadius: "20px",
                    borderStartStartRadius: { xs: "20px", md: "0px" },
                    borderEndStartRadius: { xs: "20px", md: "0px" },
                    color: "#2f2b31",
                    py: { xs: 0, md: 2 },
                }}
            >
                <Box
                    sx={{
                        pt: { xs: 3, md: 5 },
                        pb: { xs: 2, md: 4 },
                        px: { xs: 3, md: 6 },
                    }}
                >
                    <Typography>
                        <span className="poppins-regular-font">
                            Ingresa la dirección en donde recibirás tu SIM
                        </span>
                    </Typography>
                    {shipmentInputs?.map((item, index) => {
                        const { id } = item || {};

                        if (id === "state") {
                            return (
                                <HelperFormInputComponent
                                    register={register}
                                    control={control}
                                    key={index}
                                    id={item.id}
                                    validations={item.validations}
                                    placeholder={item.placeholder}
                                    // cls={item.cls}
                                    onChangeHandler={stateOnChangeHandler}
                                    onFocusHandler={stateOnFocusHandler}
                                    type={item.type}
                                    error={item.error}
                                    label={item.label}
                                    options={item.options}
                                    containerCls={item.containerCls}
                                    value={stateOptionSelected}
                                    submmited={formSubmmited}
                                    input={"shipment -> " + item.id}
                                    valueProgrammatically={
                                        item.valueProgrammatically
                                    }
                                />
                            );
                        } else if (id === "municipality") {
                            return (
                                <HelperFormInputComponent
                                    register={register}
                                    control={control}
                                    key={index}
                                    id={item.id}
                                    validations={item.validations}
                                    placeholder={item.placeholder}
                                    // cls={item.cls}
                                    onChangeHandler={
                                        municipalityOnChangeHandler
                                    }
                                    onFocusHandler={municipalityOnFocusHandler}
                                    type={item.type}
                                    error={item.error}
                                    label={item.label}
                                    options={municipalitiesOptions}
                                    containerCls={item.containerCls}
                                    value={municipalityOptionSelected}
                                    submmited={formSubmmited}
                                    input={"shipment -> " + item.id}
                                    valueProgrammatically={
                                        item.valueProgrammatically
                                    }
                                />
                            );
                        } else if (id === "neighborhood") {
                            return (
                                <HelperFormInputComponent
                                    register={register}
                                    control={control}
                                    key={index}
                                    id={item.id}
                                    validations={item.validations}
                                    placeholder={item.placeholder}
                                    // cls={item.cls}
                                    onChangeHandler={
                                        neighborhoodOnChangeHandler
                                    }
                                    onFocusHandler={neighborhoodOnFocusHandler}
                                    type={item.type}
                                    error={item.error}
                                    label={item.label}
                                    options={neighborhoodsOptions}
                                    containerCls={item.containerCls}
                                    value={neighborhoodOptionSelected}
                                    submmited={formSubmmited}
                                    input={"shipment -> " + item.id}
                                    valueProgrammatically={
                                        item.valueProgrammatically
                                    }
                                />
                            );
                        } else {
                            return (
                                <HelperFormInputComponent
                                    register={register}
                                    control={control}
                                    key={index}
                                    id={item.id}
                                    validations={item.validations}
                                    placeholder={item.placeholder}
                                    // cls={item.cls}
                                    onChangeHandler={item.onChangeHandler}
                                    onFocusHandler={item.onFocusHandler}
                                    type={item.type}
                                    error={item.error}
                                    label={item.label}
                                    options={item.options}
                                    containerCls={item.containerCls}
                                    value={item.value}
                                    // input={"shipment -> " + item.id}
                                    errors={errors}
                                    valueProgrammatically={
                                        item.valueProgrammatically
                                    }
                                />
                            );
                        }
                    })}
                </Box>

                {/* <Grid
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mb: 2,
                    }}
                >
                    <Button
                        type="button"
                        variant="text"
                        onClick={() =>
                            dispatchLeadData({
                                property: "compatibility",
                                value: undefined,
                            })
                        }
                        sx={{
                            fontSize: { xs: 16, md: 18 },
                            color: "#fd45d9",
                            textTransform: "none",
                            mt: { xs: 2, md: 0 },
                        }}
                    >
                        <span className="poppins-semibold-font">
                            ¡Inténtalo con otro equipo!
                        </span>
                    </Button>
                </Grid> */}
            </Grid>
        </Grid>
    );
};

export default ImeiSimResponseComponent;
