export async function postRequest(url, datos) {
    const requestOptions = {
        method: "POST",
        headers: {
            "X-Api-Key": `${process.env.REACT_APP_X_API_KEY}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(datos),
    };

    try {
        const response = await fetch(url, requestOptions);
        return response;
    } catch (error) {
        console.error("Error durante POST:", error);
        throw error;
    }
}
