export const getValidations = () => {
    return {
        formValidations: {
            legal_stored_id: { required: "El RFC es requerido" },
            legal_id: { required: "El RFC es requerido" },
            legal_name: {
                required: "La razón social es requerida",
            },
            legal_zipcode: {
                required: "El código postal es requerido",
                minLength: {
                    value: 5,
                    message:
                        "El código posttal debe contener al menos 5 dígitos",
                },
            },
            legal_email: {
                required: "El correo electrónico es requerido",
                pattern: {
                    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/,
                    message:
                        "El valor ingresado no tiene formato de correo electrónico",
                },
            },
            legal_tax_regime_id: {
                required: "El régimen fiscal es requerido",
            },
            legal_cfdi_use_id: {
                required: "El uso de cfdi es requerido",
            },
            legal_card_type_id: {
                required: "El tipo de pago es requerido",
            },
        },
    };
};
