import React, { useState, useEffect } from "react";
import { useContext } from "react";
import {
    Grid,
    Button,
    TextField,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { InvoiceContext } from "../data/invoiceContext";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import InputLabel from "@mui/material/InputLabel";
import headerImage from "assets/images/landings/autofacturacion/facturacion.png";
import { useLocation } from "react-router-dom";
import helpId from "assets/images/help/help-id.png";
import HelperLoaderPageComponent from "utils/helpers/loaders/pages/helper-loader-page-component";
import { useFormValues } from "modules/invoice-landing/hooks/invoiceUseformMethods";
import CustomRecaptchaComponent from "./CustomRecaptchaComponent";
import { useNavigate } from "react-router-dom";

const InvoiceByStepStatusComponent = (props) => {
    const history = useNavigate();
    const invoiceContext = useContext(InvoiceContext);
    const { invoiceStep1, dispatchInvoiceData } = invoiceContext;
    const { dispatchInvoiceDataStep1, stepOneInvoice, stepOneInvoiceAction } =
        invoiceStep1 || {};
    const location = useLocation();
    const { formValues } = useFormValues();
    const { handleSubmit, register, errors } = formValues || {};
    const [selectedOption, setSelectedOption] = useState(null);
    const [helpModalOpen, setHelpModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [parameters, setParamters] = useState();
    const [token, setToken] = useState();

    const reCaptchaAction = "get_transaction_invoice_status";

    const [callActionFlag, setCallActionFlag] = useState();

    const containerProps = {
        callActionFlag,
        setToken,
        reCaptchaAction,
    };

    // Nuevo estado para los parámetros de la URL
    const [urlParams, setUrlParams] = useState({
        purchase_type: "",
        order_number: "",
    });

    const helpImages = {
        helpId: helpId,
        // otraAyuda: otraAyudaImage,
    };

    const handleHelpIconClick = (content) => {
        setModalContent(content);
        setHelpModalOpen(true);
    };

    useEffect(() => {
        if (stepOneInvoice?.payload?.stamped === false) {

            dispatchInvoiceData({
                property: "stepCounter",
                value: 2,
            });

            setIsLoading(false);
        } else if(stepOneInvoice?.payload?.stamped === true){
            history("/factura/"+stepOneInvoice?.payload?.uuid+"/descarga");
        
        } else if (stepOneInvoice?.payload?.detail) {
            setCallActionFlag(false);
            setHelpModalOpen(true);
            setModalContent(stepOneInvoice.payload.detail);
        }
    }, [stepOneInvoice]);

    const registerOptions = {
        simOrderNumber: {
            required: "Este campo es requerido",
        },
        phoneNumber: {
            required: "Este campo es requerido",
        },
        transactionId: {
            required: "Este campo es requerido",
        },
    };

    const onSubmit = (e) => {
        setParamters(e);
        setIsLoading(true);
        setCallActionFlag(true);
    };

    useEffect(() => {
        const { simOrderNumber, transactionId, phoneNumber } = parameters || {};
        const commonPayload = {
            recaptcha_token: token,
        };

        const fetchData = async () => {
            try {
                if (selectedOption === "sim_purchase") {
                    const payload = {
                        ...commonPayload,
                        purchase_type: selectedOption,
                        order_number: simOrderNumber || urlParams.order_number,
                    };
                    const response = await stepOneInvoiceAction(payload);
                    dispatchInvoiceDataStep1({
                        property: "selectedOption",
                        value: selectedOption,
                    });
                    dispatchInvoiceDataStep1({
                        property: "simOrderNumber",
                        value: simOrderNumber,
                    });
                } else if (selectedOption === "prepaid_purchase") {
                    const payload = {
                        ...commonPayload,
                        purchase_type: selectedOption,
                        direct_number: phoneNumber || urlParams.direct_number,
                        prepaid_transaction_id:
                            transactionId || urlParams.prepaid_transaction_id,
                    };
                    const response = await stepOneInvoiceAction(payload);
                    dispatchInvoiceDataStep1({
                        property: "selectedOption",
                        value: selectedOption,
                    });
                    dispatchInvoiceDataStep1({
                        property: "phoneNumber",
                        value: phoneNumber,
                    });
                    dispatchInvoiceDataStep1({
                        property: "transactionId",
                        value: transactionId,
                    });
                }
            } catch (error) {
                console.error("error");
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [token]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const purchaseTypeParam = queryParams.get("purchase_type");
        const orderNumberParam = queryParams.get("order_number");
        const prepaidTransactionIdParam = queryParams.get(
            "prepaid_transaction_id"
        );
        const directNumberParam = queryParams.get("direct_number");

        if (purchaseTypeParam && orderNumberParam) {
            // Lógica para la compra de SIM / eSIM
            setSelectedOption(purchaseTypeParam);
            setUrlParams({
                purchase_type: purchaseTypeParam,
                order_number: orderNumberParam,
            });
            dispatchInvoiceDataStep1({
                property: "selectedOption",
                value: selectedOption,
            });
            dispatchInvoiceDataStep1({
                property: "orderNumberParam",
                value: orderNumberParam,
            });
            onSubmit();
        } else if (
            purchaseTypeParam === "prepaid_purchase" &&
            prepaidTransactionIdParam &&
            directNumberParam
        ) {
            // Lógica para la recarga
            setSelectedOption(purchaseTypeParam);
            setUrlParams({
                purchase_type: purchaseTypeParam,
                prepaid_transaction_id: prepaidTransactionIdParam,
                direct_number: directNumberParam,
            });
            dispatchInvoiceDataStep1({
                property: "selectedOption",
                value: selectedOption,
            });
            dispatchInvoiceDataStep1({
                property: "phoneNumber",
                value: directNumberParam,
            });
            dispatchInvoiceDataStep1({
                property: "transactionId",
                value: prepaidTransactionIdParam,
            });
            onSubmit();
        }
    }, []);
    

    return (
        <Grid container className="invoice-content">
            <form onSubmit={handleSubmit(onSubmit)} className="invoice-flow">
                <Grid container mb={10} display={"flex"} alignItems={"center"}>
                    <Grid item xs={12} md={6}>
                        <img src={headerImage} className={"invoice-img"} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Grid item xs={12}>
                            <h1
                                style={{
                                    fontSize: "38px",
                                    fontWeight: "bold",
                                    marginBottom: "0px",
                                    fontFamily: "Mulish-SemiBold",
                                    letterSpacing: "1px",
                                }}
                            >
                                Genera tu factura
                            </h1>
                            <p
                                style={{
                                    fontSize: "17px",
                                    lineHeight: "28px",
                                }}
                            >
                                En Dalefon estamos para ayudarte, genera tu
                                factura ingresando el tipo de compra que
                                realizaste.
                            </p>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            className="invoice-form step-1"
                            mt={5}
                        >
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <h4>Selecciona tu tipo de compra</h4>
                                </Grid>
                                <Grid item container spacing={2}>
                                    <Grid item>
                                        <FormControlLabel
                                            value="sim_purchase"
                                            control={
                                                <Radio
                                                    className="radio-input"
                                                    onClick={() =>
                                                        setSelectedOption(
                                                            "sim_purchase"
                                                        )
                                                    }
                                                    checked={
                                                        selectedOption ===
                                                        "sim_purchase"
                                                    }
                                                />
                                            }
                                            label="Compra de SIM / eSIM"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            value="prepaid_purchase"
                                            control={
                                                <Radio
                                                    className="radio-input"
                                                    onClick={() =>
                                                        setSelectedOption(
                                                            "prepaid_purchase"
                                                        )
                                                    }
                                                    checked={
                                                        selectedOption ===
                                                        "prepaid_purchase"
                                                    }
                                                    sx={{
                                                        color: "#FFFFFF",
                                                        "&.Mui-checked": {
                                                            color: "#ff28dd",
                                                        },
                                                    }}
                                                />
                                            }
                                            label="Recarga"
                                        />
                                    </Grid>
                                </Grid>

                                {selectedOption === "sim_purchase" && (
                                    <Grid item mb={2}>
                                        <InputLabel
                                            htmlFor="component-simple"
                                            className="InputLabel"
                                        >
                                            Número de orden
                                            <IconButton
                                                onClick={() =>
                                                    handleHelpIconClick(
                                                        "Ayuda para Número de orden"
                                                    )
                                                }
                                            >
                                                <HelpOutlineIcon className="help" />
                                            </IconButton>
                                        </InputLabel>
                                        <TextField
                                            {...register(
                                                "simOrderNumber",
                                                registerOptions.simOrderNumber
                                            )}
                                            fullWidth
                                            id="filled-basic"
                                            label="Ingresa el número de orden"
                                            variant="outlined"
                                            className="invoice-texfield"
                                            inputProps={{
                                                style: { color: "black" },
                                            }}
                                            defaultValue={
                                                urlParams.order_number || ""
                                            }
                                        />
                                        {errors.simOrderNumber && (
                                            <span style={{ color: "red" }}>
                                                {errors.simOrderNumber.message}
                                            </span>
                                        )}
                                    </Grid>
                                )}

                                {selectedOption === "prepaid_purchase" && (
                                    <Grid
                                        item
                                        container
                                        direction="column"
                                        spacing={2}
                                    >
                                        <Grid item>
                                            <InputLabel
                                                htmlFor="component-simple"
                                                className="InputLabel"
                                            >
                                                Número telefónico
                                                <IconButton
                                                    onClick={() =>
                                                        handleHelpIconClick(
                                                            "Ayuda para Número telefónico"
                                                        )
                                                    }
                                                >
                                                    <HelpOutlineIcon className="help" />
                                                </IconButton>
                                            </InputLabel>
                                            <TextField
                                                {...register(
                                                    "phoneNumber",
                                                    registerOptions.phoneNumber
                                                )}
                                                fullWidth
                                                id="filled-basic"
                                                label="Ingresa el número telefónico"
                                                variant="outlined"
                                                className="invoice-texfield"
                                                inputProps={{
                                                    style: {
                                                        color: "black",
                                                    },
                                                }}
                                                defaultValue={
                                                    urlParams.direct_number ||
                                                    ""
                                                }
                                            />
                                            {errors.phoneNumber && (
                                                <span style={{ color: "red" }}>
                                                    {errors.phoneNumber.message}
                                                </span>
                                            )}
                                        </Grid>
                                        <Grid item mb={2}>
                                            <InputLabel
                                                htmlFor="component-simple"
                                                className="InputLabel"
                                            >
                                                ID de transacción
                                                <IconButton
                                                    onClick={() =>
                                                        handleHelpIconClick(
                                                            "helpId"
                                                        )
                                                    }
                                                >
                                                    <HelpOutlineIcon className="help" />
                                                </IconButton>
                                            </InputLabel>
                                            <TextField
                                                {...register(
                                                    "transactionId",
                                                    registerOptions.transactionId
                                                )}
                                                fullWidth
                                                id="outlined-basic"
                                                label="Ingresa el ID de transacción"
                                                variant="outlined"
                                                className="invoice-texfield"
                                                inputProps={{
                                                    style: {
                                                        color: "black",
                                                    },
                                                }}
                                                defaultValue={
                                                    urlParams.prepaid_transaction_id ||
                                                    ""
                                                }
                                            />
                                            {errors.transactionId && (
                                                <span style={{ color: "red" }}>
                                                    {
                                                        errors.transactionId
                                                            .message
                                                    }
                                                </span>
                                            )}
                                        </Grid>
                                    </Grid>
                                )}

                                <Grid item xs={12} mt={5}>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            fullWidth
                                            className="invoice-btn"
                                        >
                                            Continuar
                                        </Button>

                                        {isLoading && (
                                            <HelperLoaderPageComponent color="text-primary" />
                                        )}
                                    </Box>
                                </Grid>

                                <Grid item mt={5}>
                                    <CustomRecaptchaComponent
                                        containerProps={containerProps}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            {/* Help Modal */}
            <Dialog
                open={helpModalOpen}
                onClose={() => setHelpModalOpen(false)}
            >
                <DialogTitle>
                    {stepOneInvoice?.payload?.detail ? "Advertencia" : "Ayuda"}
                </DialogTitle>

                <DialogContent style={{ textAlign: "center" }}>
                    {/* Contenido del modal */}
                    {modalContent && (
                        <img
                            src={helpImages[modalContent]}
                            alt={modalContent}
                            style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                margin: "auto",
                                display: "block",
                            }}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setHelpModalOpen(false)}>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default InvoiceByStepStatusComponent;
