import React from "react";

//Material UI:
import { Container, Box } from "@mui/material";

//Images:
import logoImage from "../../../assets/images/logo.png";

//CSS:
import "../../../assets/styles/css/utils/components/logo/logo.css";

function LogoComponent() {
    return (
        <Container maxWidth={"lg"} sx={{ px: { xs: 0, lg: 15, xl: 0 } }}>
            <Box
                className="container-logo"
                sx={{
                    textAlign: { xs: "center", lg: "start" },
                    pt: { xs: 4, md: 6 },
                }}
            >
                <img
                    src={logoImage}
                    className={"image-logo-dalefon"}
                    alt="Logo Dalefon"
                />
            </Box>
        </Container>
    );
}

export default LogoComponent;
