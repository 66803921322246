import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

import { useContext } from "react";
import { useForm } from "react-hook-form";

import {
    Button,
    Checkbox,
    CircularProgress,
    Container,
    Grid,
    MenuItem,
} from "@mui/material";

import HelperFormInputComponent from "utils/helpers/forms/inputs/helper-form-input-component";

import { PrepaidAuthnInvoiceContext } from "../data/contexts/prepaidAuthnInvoiceContext";

import { usePrepaidAuthnInvoiceForm } from "../hooks/usePrepaidAuthnInvoiceForm";
import { LoadingButton } from "@mui/lab";

const PrepaidAuthnInvoiceForm = (props) => {
    const {
        onSubmit,
        legalInputs,
        legalInputsRegistered,
        loading,
        submmited,
        responseComponent,
    } = usePrepaidAuthnInvoiceForm(props);
    
    const prepaidAuthnInvoiceContext = useContext(PrepaidAuthnInvoiceContext);
    const { variables_ } = prepaidAuthnInvoiceContext || {};
    const { reduxValues } = variables_ || {};
    const { listRFC } = reduxValues || {};
    const { listRFCObj } = listRFC || {};
    const { legal_ids } = listRFCObj || {};

    const { reducerValues } = variables_ || {};
    const { prepaidAuthnReducer } = reducerValues || {};
    const { legalStoredOptions, legalStoredSelected } =
        prepaidAuthnReducer || {};

    const {
        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {legal_ids?.length > 0 && legalStoredSelected !== 1 ? (
                    <>
                        {legalInputsRegistered?.map((item, index) => {
                            if (item?.id === "legal_stored_id") {
                                return (
                                    <Grid container>
                                        <HelperFormInputComponent
                                            key={index}
                                            id={item.id}
                                            type={item.type}
                                            value={item.value}
                                            options={legalStoredOptions}
                                            placeholder={item.placeholder}
                                            containerCls={item.containerCls}
                                            cls={item.cls}
                                            // label={item.label}
                                            // error={item.error}
                                            errors={errors}
                                            control={control}
                                            register={register}
                                            validations={item.validations}
                                            onChangeHandler={
                                                item.onChangeHandler
                                            }
                                            onFocusHandler={item.onFocusHandler}
                                            submmited={submmited}
                                            valueProgrammatically={
                                                item.valueProgrammatically
                                            }
                                        />
                                    </Grid>
                                );
                            } else {
                                return (
                                    <Grid container>
                                        <HelperFormInputComponent
                                            key={index}
                                            id={item.id}
                                            type={item.type}
                                            value={item.value}
                                            options={item.options}
                                            placeholder={item.placeholder}
                                            containerCls={item.containerCls}
                                            cls={item.cls}
                                            // label={item.label}
                                            // error={item.error}
                                            errors={errors}
                                            control={control}
                                            register={register}
                                            validations={item.validations}
                                            onChangeHandler={
                                                item.onChangeHandler
                                            }
                                            onFocusHandler={item.onFocusHandler}
                                            submmited={submmited}
                                            valueProgrammatically={
                                                item.valueProgrammatically
                                            }
                                        />
                                    </Grid>
                                );
                            }
                        })}
                    </>
                ) : (
                    <>
                        {legal_ids?.length > 0 ? (
                            <>
                                {legalInputsRegistered?.map((item, index) => {
                                    if (item?.id === "legal_stored_id") {
                                        return (
                                            <Grid container>
                                                <HelperFormInputComponent
                                                    key={index}
                                                    id={item.id}
                                                    type={item.type}
                                                    value={item.value}
                                                    options={legalStoredOptions}
                                                    placeholder={
                                                        item.placeholder
                                                    }
                                                    containerCls={
                                                        item.containerCls
                                                    }
                                                    cls={item.cls}
                                                    // label={item.label}
                                                    // error={item.error}
                                                    errors={errors}
                                                    control={control}
                                                    register={register}
                                                    validations={
                                                        item.validations
                                                    }
                                                    onChangeHandler={
                                                        item.onChangeHandler
                                                    }
                                                    onFocusHandler={
                                                        item.onFocusHandler
                                                    }
                                                    submmited={submmited}
                                                    valueProgrammatically={
                                                        item.valueProgrammatically
                                                    }
                                                />
                                            </Grid>
                                        );
                                    }
                                })}
                            </>
                        ) : null}

                        {legalInputs?.map((item, index) => {
                            return (
                                <Grid container>
                                    <HelperFormInputComponent
                                        key={index}
                                        id={item.id}
                                        type={item.type}
                                        value={item.value}
                                        options={item.options}
                                        placeholder={item.placeholder}
                                        containerCls={item.containerCls}
                                        cls={item.cls}
                                        // label={item.label}
                                        // error={item.error}
                                        errors={errors}
                                        control={control}
                                        register={register}
                                        validations={item.validations}
                                        onChangeHandler={item.onChangeHandler}
                                        onFocusHandler={item.onFocusHandler}
                                        submmited={submmited}
                                        valueProgrammatically={
                                            item.valueProgrammatically
                                        }
                                    />
                                </Grid>
                            );
                        })}
                    </>
                )}

                <Grid container>
                    <LoadingButton
                        loading={loading}
                        loadingPosition="center"
                        variant="outlined"
                        className="compatibility-btn"
                        // onClick={() => onSubmit(true)}
                        type="submit"
                    >
                        <span className="poppins-font">Continuar</span>
                    </LoadingButton>
                </Grid>

                <Grid container>{responseComponent}</Grid>
            </form>
        </>
    );
};

export default PrepaidAuthnInvoiceForm;
