import { Grid } from "@mui/material";

import PrepaidAuthnInvoiceForm from "./PrepaidAuthnInvoiceForm";

const PrepaidAuthnInvoiceContent = () => {
    return (
        <Grid container>
            <PrepaidAuthnInvoiceForm />
        </Grid>
    );
};

export default PrepaidAuthnInvoiceContent;
