import { FORGOT_PASSWORD, FORGOT_PASSWORD_ERROR } from "_models/redux/types";

import axiosAuthInstance from "../../../services/api/authentication";

export const forgotPasswordAction = (params) => async (dispatch) => {
    const { direct_number, recaptcha_token, platform, notification_type } =
        params || {
            user_phone_number: undefined,
        };

    try {
        const response = await axiosAuthInstance.post(
            `/api/v2/clients/${direct_number}/forgot-password`,
            { recaptcha_token, platform, notification_type }
        );

        const { data } = response;

        dispatch({
            type: FORGOT_PASSWORD,
            payload: data,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: FORGOT_PASSWORD_ERROR,
            payload: e || {},
        });
    }
};
