import {
    CLEAN_CHECKING_COMPATIBLE_DEVICE,
    CLEAN_CHECKING_COMPATIBLE_DEVICE_ERROR,
} from "_models/redux/types";

export const cleanCheckingDeviceAction = () => async (dispatch) => {
    try {
        dispatch({
            type: CLEAN_CHECKING_COMPATIBLE_DEVICE,
            payload: [],
        });
    } catch (e) {
        dispatch({
            type: CLEAN_CHECKING_COMPATIBLE_DEVICE_ERROR,
            payload: [],
        });
    }
};
