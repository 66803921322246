import { useState, useContext } from "react";

import { InvoiceContext } from "../data/invoiceContext";

import { registerList } from "../data/invoiceRegisterOptions";

import { cfdiUseList } from "../data/invoiceCfdiUseOptions";
import { taxRegimeList } from "../data/invoiceTaxRegimeOptions";
import { taxTypeCardList } from "../data/invoiceTypeCard";

import { formInputs } from "../data/invoiceFormInputs";

export const useFormInputsHook = ({
    watch,
    reset,
    control,
    setValue,
    getValues,
    register,
    handleSubmit,
    setError,
    errors,
}) => {
    // const invoiceContext = useContext(InvoiceContext);
    // const { invoiceStep2 } = invoiceContext;
    // const { invoiceDataStep2 } = invoiceStep2 || {};

    // const { formValues } = invoiceDataStep2;
    // const { errors } = formValues;

    const { registerOptions } = registerList() || {};

    const { cfdiUseOptions } = cfdiUseList();

    const { taxTypeCardOptions } = taxTypeCardList();

    const { taxRegimeOptions } = taxRegimeList();

    const [cfdiOptionSelected, setcfdiOptionSelected] = useState({});
    const [taxRegimeSelected, setTaxRegimeSelected] = useState({});
    const [taxTypeCardSelected, setTaxTypeCardSelected] = useState({});

    const onFocusHandler = () => {};
    const onChangeHandler = () => {};

    const { inputs } = formInputs({
        errors,
        registerOptions,
        handlers: {
            onFocusHandler,
            onChangeHandler,
        },
        cfdiUses: {
            cfdiUseOptions,
            cfdiOptionSelected,
            setcfdiOptionSelected,
        },
        taxRegimes: {
            taxRegimeOptions,
            taxRegimeSelected,
            setTaxRegimeSelected,
        },
        taxTypeCardList: {
            taxTypeCardOptions,
            taxTypeCardSelected,
            setTaxTypeCardSelected,
        },
    });

    return { inputs };
};
