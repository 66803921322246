import { useReducer } from "react";

import { useFormValues } from "./invoiceUseformMethods";

import { useInvoiceReducer } from "../data/useInvoiceReducer";

export const useInvoiceStep3 = (props) => {

    //Form Library Method
    const { formValues } = useFormValues();

    //Redux Method and Props
    const {
        postInvoiceSimPurchase,
        postInvoiceSimPurchaseAction,
        cleanPostInvoiceSimPurchaseAction,
        invoicePageDownloadAction,
        InvoicePageDownload
        
    } = props || {};

    const initialInvoiceDataStep3 = {
        formValues,

        pageLoaded: false,

        reCaptchaKey: process.env.REACT_APP_RECAPTCHA_ENTERPRISE || "",
        reCaptchaAction: "generate_download_links",
        reCaptchaToken: undefined,
        reCaptchaSubmit: undefined,

        formResponse: undefined,
        loaderResponse: undefined,
        sectionResponse: "form",
    };
    const [invoiceDataStep3, dispatchInvoiceDataStep3] = useReducer(
        useInvoiceReducer,
        initialInvoiceDataStep3
    );

    const invoiceStep3 = {
        invoiceDataStep3,
        dispatchInvoiceDataStep3,
        invoicePageDownloadAction,
        postInvoiceSimPurchase,
        postInvoiceSimPurchaseAction,
        cleanPostInvoiceSimPurchaseAction,
        InvoicePageDownload
    };
    return {
        invoiceStep3,
    };
};
