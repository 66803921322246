import { COMPLETEPROCESS, COMPLETEPROCESS_ERROR } from "_models/redux/types";

import axiosAuthInstance from "../../../services/api/authentication";

export const completeProcessAction = (params) => async (dispatch) => {
    const { prepaidData, tokenView } = params || "";
    const { payment, email_ticket } = prepaidData;

    try {
        const response = await axiosAuthInstance.post(
            `/api/leads/steps/v2/complete-process`,
            { email_ticket, payment },
            {
                headers: {
                    Authorization: `Bearer ${tokenView}`,
                },
            }
        );

        dispatch({
            type: COMPLETEPROCESS,
            payload: response,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: COMPLETEPROCESS_ERROR,
            payload: e || {},
        });
    }
};
