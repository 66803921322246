import {
    GET_LANDING_ESIM,
    GET_LANDING_ESIM_ERROR,
    CLEAN_GET_LANDING_ESIM,
    CLEAN_GET_LANDING_ESIM_ERROR,
} from "_models/redux/types";

const initialState = {
    loaded: undefined,
    payload: undefined,
    status: undefined,
    state: undefined,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_LANDING_ESIM:
            return {
                ...state,
                loaded: true,
                payload: action?.payload || {},
                status: action?.status || null,
                state: "success",
            };
        case GET_LANDING_ESIM_ERROR:
            return {
                ...state,
                loaded: true,
                payload: action?.payload || {},
                status: action?.status || null,
                state: "error",
            };
        case CLEAN_GET_LANDING_ESIM:
            return {
                ...state,
                loaded: false,
                payload: action?.payload || undefined,
                status: undefined,
                state: "success",
            };
        case CLEAN_GET_LANDING_ESIM_ERROR:
            return {
                ...state,
                loaded: false,
                payload: action?.payload || undefined,
                status: undefined,
                state: "error",
            };
        default:
            return state;
    }
}
