import {
    CREATE_INVOICE_STEP_2,
    CREATE_INVOICE_STEP_2_ERROR,
} from "_models/redux/types";

import axiosAuthInstance from "services/api/authentication";

export const createInvoiceStep2Action = (params) => async (dispatch) => {
    try {
        const {
            purchase_type,
            order_number,
            prepaid_transaction_id,
            direct_number,
            recaptcha_token,
        } = params || {};

        const requestData = {
            purchase_type,
            order_number,
            prepaid_transaction_id,
            direct_number,
            recaptcha_token,
        };

        const response = await axiosAuthInstance.post(
            "/api/transactions/invoices",
            {
                params: requestData,
            }
        );

        const { data, status } = response || {};

        dispatch({
            type: CREATE_INVOICE_STEP_2,
            status: status || {},
            payload: data,
        });
    } catch (error) {
        console.error(error);

        const { response } = error || {};
        const { data, status } = response || {};

        dispatch({
            type: CREATE_INVOICE_STEP_2_ERROR,
            status: status || {},
            payload: data || {},
        });
    }
};
