import { INVOICE_DOWNLOAD, INVOICE_DOWNLOAD_ERROR } from "_models/redux/types";

import axiosAuthInstance from "services/api/authentication";

export const invoicePageDownloadAction = (params) => async (dispatch) => {
    try {
        const { uuid, recaptcha_token } = params || {};

        const response = await axiosAuthInstance.post(
            "/api/transactions/invoices/generate-links",
            { uuid, recaptcha_token }
        );

        const { data, status } = response || {};

        dispatch({
            type: INVOICE_DOWNLOAD,
            status: status || {},
            payload: data,
        });
    } catch (error) {
        console.error(error);

        const { response } = error || {};
        const { data, status } = response || {};

        dispatch({
            type: INVOICE_DOWNLOAD_ERROR,
            status: status || {},
            payload: data || {},
        });
    }
};
