import React from "react";
import { connect } from "react-redux";
import { MenuContext } from "./data/context";
import MenuComponent from "./components/MenuComponent";
import { loginAction } from "_models/redux/login/action";
import { cleanPurchaseContactInfoAction } from "_models/redux/add-purchase-contact-info/clean";
import { cleanCheckImeiAction } from "_models/redux/check-imei/clean";
import { cleanGetOffersAction } from "_models/redux/get-offers/clean";
import { cleanOfferSelectionAction } from "_models/redux/offer-selection/clean";
import { cleanPaymentAction } from "_models/redux/payment/clean";
import { cleanShippinghAddressAction } from "_models/redux/shipping-address/clean";
import { cleanMunicipalitiesAction } from "_models/redux/municipalities/clean";
import { cleanNeighborhoodsAction } from "_models/redux/neighborhoods/clean";
import { cleanPaymentConfirmAction } from "_models/redux/payment-confirm/clean";
import { cleanCompleteProcessAction } from "_models/redux/complete-process/clean";
import { HomeContainerController } from "./hooks/useHomeContainerHook";

const MenuContainer = ({
    login,
    beCustomer,
    checkImei,
    getOffers,
    offerSelection,
    shippingAddress,
    municipalitiesData,
    neighborhoodsData,
    payment,
    paymentConfirm,
    completeProcess,

    loginAction,
    cleanPurchaseContactInfoAction,
    cleanCheckImeiAction,
    cleanGetOffersAction,
    cleanOfferSelectionAction,
    cleanPaymentAction,
    cleanShippinghAddressAction,
    cleanMunicipalitiesAction,
    cleanNeighborhoodsAction,
    cleanPaymentConfirmAction,
    cleanCompleteProcessAction,
}) => {
    const { loginValues } = HomeContainerController({
        login,
        beCustomer,
        checkImei,
        getOffers,
        offerSelection,
        shippingAddress,
        municipalitiesData,
        neighborhoodsData,
        payment,
        paymentConfirm,
        completeProcess,
        loginAction,
        cleanPurchaseContactInfoAction,
        cleanCheckImeiAction,
        cleanGetOffersAction,
        cleanOfferSelectionAction,
        cleanPaymentAction,
        cleanShippinghAddressAction,
        cleanMunicipalitiesAction,
        cleanNeighborhoodsAction,
        cleanPaymentConfirmAction,
        cleanCompleteProcessAction,
    });

    return (
        <MenuContext.Provider value={loginValues}>
            <MenuComponent />
        </MenuContext.Provider>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    login: state.login,

    beCustomer: state.beCustomer,
    checkImei: state.checkImei,
    getOffers: state.getOffers,
    offerSelection: state.offerSelection,
    shippingAddress: state.shippingAddress,
    municipalitiesData: state.municipalitiesData,
    neighborhoodsData: state.neighborhoodsData,
    payment: state.payment,
    paymentConfirm: state.paymentConfirm,
    completeProcess: state.completeProcess,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    loginAction,

    cleanPurchaseContactInfoAction,
    cleanCheckImeiAction,
    cleanGetOffersAction,
    cleanOfferSelectionAction,
    cleanShippinghAddressAction,
    cleanMunicipalitiesAction,
    cleanNeighborhoodsAction,
    cleanPaymentAction,
    cleanPaymentConfirmAction,
    cleanCompleteProcessAction,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
