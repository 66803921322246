import { useState, useEffect } from "react";

//React Router Dom:
import { useNavigate, useLocation } from "react-router-dom";

export default function useSuccessPageHook(props) {
    let navigate = useNavigate();
    const [dateOfOperation, setDateOfOperation] = useState("");

    const { state } = useLocation();

    const { prepaid } = state || {};
    const {
        amount,
        product_name,
        prepaid_id,
        product_duration,
        operation_date,
    } = prepaid || {};

    const { cleanPaymentAction } = props || {};

    const routeSuccess = () => {
        navigate("/");
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        return () => {
            if (typeof cleanPaymentAction == "function") cleanPaymentAction();
        };
    }, [cleanPaymentAction]);

    useEffect(() => {
        const date = new Date(operation_date);
        setDateOfOperation(
            date.toLocaleDateString("es-MX", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
            })
        );

        return () => {};
    }, [operation_date]);

    const infoProduct = [
        /* {
            title: "Opción de pago",
            value: "Al contado",
        },
        {
            title: "Mensualidad",
            value: "$ 0.00",
        }, */
        {
            title: "Fecha de operación",
            value: dateOfOperation,
        },
        {
            title: "No. Autorización",
            value: prepaid_id,
        },
    ];

  return {
    infoProduct,
    routeSuccess,
    product_name,
    amount,
    product_duration,
    navigate,
    state
  }
}
