import React from "react";

//Material UI:
import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";

//Styles MUI:
import { textFieldItemValue, typographyItem } from "./styles-mui/selectPaymentMethodStylesMUI";

function SelectPaymentMethodComponent({register, watch, errors, valuesMetodo}) {
    return (
        <>
            <Typography sx={typographyItem}>
                Seleccione método de pago *
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="select-metodo-pago">
                    Seleccione método de pago
                </InputLabel>
                <Select
                    required
                    {...register("payment_method_id")}
                    fullWidth
                    sx={textFieldItemValue}
                    labelId="select-metodo-pago"
                    value={watch("payment_method_id")}
                    label="Seleccione método de pago"
                >
                    {valuesMetodo?.map((option) => (
                        <MenuItem key={option?.id} value={option?.id || 0}>
                            {option?.id + " - " + option?.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
}

export default SelectPaymentMethodComponent;
