import {
    GET_STORED_LEGALS,
    GET_STORED_LEGALS_ERROR,
} from "_models/redux/types";

import axiosAuthInstance from "services/api/authentication";

export const getStoredLegalsAction = (params) => async (dispatch) => {
    try {
        //TODO: Change this to the correct params and endpoint
        const { client_token, id_transaction } = params || {};
        const requestData = { id_transaction };
        const response = await axiosAuthInstance.get(
            `/api/stored-legals/${client_token}`,
            {
                params: requestData,
            }
        );

        const { data, status } = response || {};

        dispatch({
            type: GET_STORED_LEGALS,
            status: status || {},
            payload: data,
        });
    } catch (e) {
        console.error(e);

        const { response } = e || {};
        const { data, status } = response || {};

        dispatch({
            type: GET_STORED_LEGALS_ERROR,
            status: status || {},
            payload: data || {},
        });
    }
};
