import React from "react";

//Assets:
import purchaseImage from "assets/images/purchase/purchase.png";

//Models:
import { initRegisterAction } from "_models/redux/initRegister/action";
import { addPurchaseContactInfoAction } from "_models/redux/add-purchase-contact-info/action";
import { cleanPurchaseContactInfoAction } from "_models/redux/add-purchase-contact-info/clean";
import { cleanInitRegister } from "_models/redux/initRegister/clean";

//Redux:
import { connect } from "react-redux";

//Material UI:
import { Box, Grid, Typography } from "@mui/material";

//CSS:
import "assets/styles/css/purchase/purchase.css";

//Styles MUI:
import {
    boxContainerImg,
    gridContainerContent,
    gridContainerPurchase,
    gridItemContainerImg,
    gridItemTitle,
    typographySubTitle,
    typographyTitle,
} from "./styles-mui/purchaseStylesMUI";

//Components:
import BaseFirstStepsComponent from "utils/components/base-first-steps/BaseFirstStepsComponent";

//Hooks:
import usePurchaseHook from "./hook/usePurchaseHook";
import PurchaseFormComponent from "./components/PurchaseFormComponent";

const PurchaseComponent = ({
    addPurchaseContactInfoAction,
    cleanPurchaseContactInfoAction,
    initRegisterAction,
    addPurchaseContactInfo,
    initRegister,
}) => {
    const {
        getLoading,
        handleSubmit,
        onSubmit,
        submitLoading,
        register,
        registerOptions,
        contactFullName,
        setContactFullName,
        contactPhone,
        setContactPhone,
        contactEmail,
        setContactEmail,
        checkedConditions,
        handleChangeConditions,
        detail,
        errors,
    } = usePurchaseHook({
        addPurchaseContactInfoAction,
        cleanPurchaseContactInfoAction,
        initRegisterAction,
        cleanInitRegister,
        addPurchaseContactInfo,
        initRegister,
    });

    return (
        <BaseFirstStepsComponent>
            <Grid
                container
                sx={gridContainerPurchase}
                className="container-purchase"
            >
                <Grid item xs={12} sx={gridItemTitle}>
                    <Typography sx={typographyTitle}>
                        Quiero ser cliente Dalefon
                    </Typography>
                    <Typography sx={typographySubTitle}>
                        Escribe tu nombre y número de teléfono para comenzar tu
                        experiencia
                    </Typography>
                </Grid>
                <Grid container sx={gridContainerContent}>
                    <Grid item md={12} lg={"auto"} sx={gridItemContainerImg}>
                        <Box sx={boxContainerImg}>
                            <img
                                className="purchase-image"
                                alt="Un celular con el fondo del logo de Dalefon"
                                src={purchaseImage}
                            />
                        </Box>
                    </Grid>

                    <PurchaseFormComponent
                        getLoading={getLoading}
                        initRegister={initRegister}
                        addPurchaseContactInfo={addPurchaseContactInfo}
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmit}
                        register={register}
                        registerOptions={registerOptions}
                        errors={errors}
                        contactFullName={contactFullName}
                        setContactFullName={setContactFullName}
                        contactPhone={contactPhone}
                        setContactPhone={setContactPhone}
                        contactEmail={contactEmail}
                        setContactEmail={setContactEmail}
                        checkedConditions={checkedConditions}
                        handleChangeConditions={handleChangeConditions}
                        detail={detail}
                        submitLoading={submitLoading}
                    />
                </Grid>
            </Grid>
        </BaseFirstStepsComponent>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    addPurchaseContactInfo: state.addPurchaseContactInfo,
    initRegister: state.initRegister,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    addPurchaseContactInfoAction,
    cleanPurchaseContactInfoAction,
    initRegisterAction,
    cleanInitRegister,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(PurchaseComponent);
