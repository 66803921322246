import deparam from "deparam.js";
import { setLeadDataHelper } from "_controllers/helpers/setLeadData";
import { getLeadDataHelper } from "_controllers/helpers/getLeadData";

export const handleLeadUrlDataHelper = (params = {}, from = "default") => {
    let leadDataParams = { cid: 1, chid: 1 };

    if (typeof window !== "undefined") {
        const { location } = window || {};
        const { search } = location || {};
        const searchParams = deparam(search);
        const data = getLeadDataHelper();

        if (!Number.isNaN(parseInt(searchParams?.cid))) {
            leadDataParams.cid = searchParams.cid;
        } else if (data?.cid) {
            leadDataParams.cid = data.cid;
        }

        if (!Number.isNaN(parseInt(searchParams?.chid))) {
            leadDataParams.chid = searchParams.chid;
        } else if (data?.chid) {
            leadDataParams.chid = data.chid;
        }

        setLeadDataHelper(leadDataParams, "root");
    }

    return leadDataParams;
};
