import React from "react";

//CSS:
import "assets/styles/css/utils/components/cards/card-prepaid/card-prepaid.css";

// Material Ui:
import { Grid, Box, Typography } from "@mui/material";

//Assets:
import imgShareWifi from "assets/images/utils/components/cards/hotspot-share.svg";
import imgNoShareWifi from "assets/images/utils/components/cards/no-hotspot.svg";
import imgSocialmedia from "assets/images/utils/components/cards/social-media.svg";
import imgShadowCard from "assets/images/utils/components/cards/shadow-card.png";

//Icons:
import { LuGift } from "react-icons/lu";
import { BsCart3 } from "react-icons/bs";

//React Icons:
import { FaCheck } from "react-icons/fa6";

//Styles MUI:
import {
    TypographyValidity,
    boxContainer,
    boxContainerButton,
    boxContainerImg,
    boxContainerMessageFreeSIMAndShipping,
    boxContainerMinAndSMS,
    boxContainerNoShareAndShareWifi,
    gridItemContainer,
    typographyBestSelling,
    typographyGB,
    typographyGBSpan,
    typographyNoShareAndShareWifi,
    typographyNote,
    typographyPlusGB,
    typographyPrice,
} from "./styles-mui/cardPrepaidStylesMUI";

//Hooks:
import useCardPrepaidHook from "./hooks/useCardPrepaidHook";

//Components:
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

const CardPrepaidComponent = ({
    element,
    oneElement = false,
    bestSelling,
    routeRecarga,
}) => {
    const { totalGB, totalGBSocialMedia, messageFreeSIMAndShipping } =
        useCardPrepaidHook(element);
    return (
            <Grid
                item
                xs={11}
                md={oneElement === true ? 12 : 5}
                lg={oneElement === true ? 12 : 3.6}
                xl={oneElement === true ? 12 : 2.5}
                sx={gridItemContainer(element)}
                className="container-card-prepaid"
            >
                <Box
                    className={`card-prepaids ${
                        bestSelling ? "card-prepaids-best-selling" : null
                    }`}
                    sx={boxContainer}
                >
                    {/* Titulo Más vendido */}
                    {bestSelling ? (
                        <Typography
                            className="title-best-selling"
                            sx={typographyBestSelling}
                        >
                            MÁS VENDIDO
                        </Typography>
                    ) : null}

                    {/* Mensaje de envío y SIM gratis */}
                    {element.freeSim === false ||
                    element.freeShipping === false ? (
                        <Box sx={boxContainerMessageFreeSIMAndShipping}>
                            <Box sx={{ fontSize: 20 }}>
                                {element.freeSim === false ? <LuGift /> : null}
                                {element.freeShipping === false ? (
                                    <BsCart3 />
                                ) : null}
                            </Box>
                            <Box>
                                <Typography sx={{ fontSize: 11 }}>
                                    {messageFreeSIMAndShipping()}
                                </Typography>
                            </Box>
                        </Box>
                    ) : null}

                    {/* Sección precio */}
                    <Typography sx={typographyPrice}>
                        ${element.price}
                    </Typography>

                    {/* Sección de GB */}
                    <Typography sx={typographyGB}>
                        {totalGB}GB
                        <Typography variant="span" sx={typographyGBSpan}>
                            *
                        </Typography>
                    </Typography>

                    {totalGBSocialMedia > 0 ? (
                        <>
                            {/* Sección más GB para redes sociales */}
                            <Typography sx={typographyPlusGB}>
                                + Redes Sociales
                            </Typography>

                            {/* Redes sociales */}
                            <Box sx={boxContainerImg}>
                                <img
                                    src={imgSocialmedia}
                                    alt="Redes sociales"
                                    className="img-social-media-card-prepaid"
                                />
                            </Box>
                        </>
                    ) : null}

                    {/* Vigencia */}
                    {element.product_duration ? (
                        <Typography sx={TypographyValidity}>
                            {element.product_duration} días de vigencia
                        </Typography>
                    ) : null}

                    {/* No Share and Share Wifi */}
                    <Box sx={boxContainerNoShareAndShareWifi}>
                        <img
                            src={
                                element.hotspot ? imgShareWifi : imgNoShareWifi
                            }
                            alt={`Imagen sobre una antena que ${
                                element.hotspot ? "comparte Wifi" : "NO "
                            }comparte Wifi`}
                            width={15}
                            height={15}
                            className="circular-icon"
                        />
                        <Typography sx={typographyNoShareAndShareWifi}>
                            {element.hotspot
                                ? "Comparte datos"
                                : "No comparte datos"}
                        </Typography>
                    </Box>

                    {/* Minutos y SMS */}
                    <Box sx={boxContainerMinAndSMS}>
                        <FaCheck className="circular-icon" />
                        <Typography>Minutos y SMS</Typography>
                    </Box>

                    {/* Botón */}
                    <Box sx={boxContainerButton}>
                        <Box sx={{ width: "60%" }}>
                            <CustomButtonComponent
                                text={"¡Lo quiero!"}
                                height={50}
                                bgColor={"primary-pink"}
                                onClick={() => routeRecarga()}
                            />
                        </Box>
                    </Box>

                    {/* Nota */}
                    <Typography sx={typographyNote}>
                        *Al adquirir este producto, estás comprando un{" "}
                        {element.name}
                    </Typography>
                </Box>
                {/* <img
                src={imgShadowCard}
                alt="shadow"
                className="img-shadow-card-prepaid"
            /> */}
            </Grid>
    );
};

export default CardPrepaidComponent;
