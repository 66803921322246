import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { VerificationPin } from "react-verification-pin";
import loginImg from "assets/images/login/login-image.png";
import logoImage from "assets/images/logo.png";
import { useForm } from "react-hook-form";
import { AppContext } from "_models/context/app";
import { validatePinAction } from "_models/redux/validate-pin/action";
import { useNavigate, useLocation } from "react-router-dom";

const SecurityCodeSignUpPage = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { contactPhone, gettingType, data } = location.state || {};

    const { validatePin } = props;
    const [pin, setPin] = useState();
    const appContext = useContext(AppContext);
    const { setpinCodeNumber } = appContext;

    const [status, setStatus] = useState("process");
    const [attemptedValidation, setAttemptedValidation] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (validatePin?.status === 200) {
            setStatus("success");
            navigate(`/reset-password/set-password`, {
                state: { contactPhone, pin },
            });
        } else if (validatePin?.status > 200 && attemptedValidation) {
            setStatus("error");
        }
    }, [validatePin, navigate, contactPhone, pin, attemptedValidation]);

    const handleOnFinish = (code) => {
        setStatus("wait");
        setAttemptedValidation(true);
        setPin(code);

        const post = {
            code: parseInt(code),
            directNumber: contactPhone,
        };
        props.validatePinAction(post);
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = () => {
        setLoading(true);
    };

    const handleGoBack = () => {
        navigate("/forgot-password/media-selection-pin", {
            state: { contactPhone, gettingType, data },
        });
    };

    return (
        <Grid container className="signup-page">
            <Grid container className="signup-component-container">
                <Grid
                    container
                    className="signup-component"
                    display="flex"
                    alignItems="center"
                >
                    <Grid item xs={12} mb={2}>
                        <img
                            src={logoImage}
                            className="logo-dalefon-image left"
                            alt="Logo"
                        />
                    </Grid>
                    <Grid item md={12} lg={6}>
                        <img
                            title="Security Code Image"
                            alt="Security Code Image"
                            src={loginImg}
                            className="login-img"
                        />
                    </Grid>

                    <Grid
                        item
                        md={12}
                        lg={6}
                        mt={{ xs: 5, md: 2 }}
                        className="signup-form"
                    >
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid
                                item
                                xs={12}
                                className="signup-text-container set-password"
                            >
                                <Grid
                                    item
                                    xs={12}
                                    className="signup-field-container pin"
                                    mt={2}
                                >
                                    <VerificationPin
                                        {...register("pinCode", {
                                            required: "Pin es requerido",
                                        })}
                                        fullWidth
                                        type="number"
                                        inputsNumber={5}
                                        name="pinCode"
                                        status={status}
                                        title="Código de seguridad"
                                        subTitle="Ingresa el código que hemos enviado a tu número celular."
                                        onFinish={handleOnFinish}
                                        onChange={(value) => {
                                            setpinCodeNumber(value);
                                            if (value.length === 0) {
                                                setAttemptedValidation(false);
                                                setStatus("wait");
                                            }
                                        }}
                                    />
                                    <Grid item xs={12}>
                                        <span>
                                            {errors.pinCode && (
                                                <p className="required-incidence">
                                                    {errors.pinCode.message}
                                                </p>
                                            )}
                                        </span>
                                    </Grid>

                                    <Grid container className="messages">
                                        <Grid item xs={12} md={8}>
                                            <span>
                                                <p className="required-incidence">
                                                    {/* {detail} */}
                                                </p>
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <span>
                                                {status === "error" &&
                                                    validatePin?.status >
                                                        200 && (
                                                        <div className="required-incidence">
                                                            <button
                                                                onClick={
                                                                    handleGoBack
                                                                }
                                                            >
                                                                Regresar
                                                            </button>
                                                        </div>
                                                    )}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className="signup-loader">
                                {/* Agregar un indicador de carga si es necesario */}
                            </Grid>
                            <Grid item xs={12} mb={2}>
                                <LoadingButton
                                    className="signup-button"
                                    color="primary"
                                    variant="contained"
                                    // type="submit"
                                    loading={loading}
                                    onClick={handleOnFinish}
                                    loadingPosition="center"
                                >
                                    Continuar
                                </LoadingButton>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
                {/* Agregar el componente FormFooterComponent aquí si es necesario */}
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    validatePin: state?.validatePin,
});

const mapDispatchToProps = {
    validatePinAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SecurityCodeSignUpPage);
