export const gridItemCollapseCards = {
    p: "25px 0 5px",
    borderTop: "1px solid #3333",
};

export const typographyPercentage = {
    margin: "0 0 15px",
    fontWeight: 900,
    fontSize: 17,
    color: "#52456a",
};

export const boxContainerServiceItem = {
    width: "100%",
    margin: "0 0 10px",
    fontWeight: 700,
    color: "#52456a",
};
