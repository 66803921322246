import { ACCOUNTINFO, ACCOUNTINFO_ERROR } from "_models/redux/types";

const initialState = {
    accountInfodObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ACCOUNTINFO:
            return {
                ...state,
                accountInfodObj: action.payload,
                loading: true,
            };
        case ACCOUNTINFO_ERROR:
            return {
                ...state,
                accountInfodObj: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}
