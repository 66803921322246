import React from "react";

//Material UI:
import { Box, Grid, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { TextField } from "@mui/material";

//Images:
import forgotPasswordImage from "assets/images/login/login-image.png";

//Styles MUI:
import {
    boxFormTitle,
    containerFormAddPhone,
    containerImageAddPhone,
    gridContainerAddPhone,
    iconArrowBackIos,
    typographyFormTitle,
} from "../styles-mui/addPhoneStyleMUI";

//CSS:
import "../../../assets/styles/css/forgot-password/components/add-phone.css";

//Components:
import CustomButtonComponent from "utils/templates/CustomButtonComponent";
import SignUpLinkComponent from "widgets/signup/component";
import BaseFirstStepsComponent from "utils/components/base-first-steps/BaseFirstStepsComponent";
import { useForgotPasswordController } from "utils/components/Common/hook/useForgotPasswordController";

const ForgotPasswordComponent = (props) => {
    const {
        errorMessage,
        handleTextFieldChange,
        handleContinueClick,
        textFieldValue,
    } = useForgotPasswordController();

    return (
        <BaseFirstStepsComponent>
            <Grid container sx={gridContainerAddPhone}>
                <Grid
                    item
                    md={12}
                    lg={"auto"}
                    sx={{ display: { xs: "none", lg: "block" } }}
                >
                    <Box sx={containerImageAddPhone}>
                        <img
                            title="Forgot Password Image"
                            alt="Una ancianita sentada en el logo de Dalefon"
                            src={forgotPasswordImage}
                            className={"login-img"}
                        />
                    </Box>
                </Grid>

                <Grid item md={12} lg={5} xl={5} sx={containerFormAddPhone}>
                    <Box sx={boxFormTitle}>
                        <a href="/login">
                            <ArrowBackIosIcon sx={iconArrowBackIos} />
                        </a>
                        <Typography sx={typographyFormTitle}>
                            ¿Olvidaste tu contraseña?
                        </Typography>
                    </Box>

                    <Typography
                        className={"add-phone-instruction-container"}
                        sx={{ mt: 2, mb: 3, textAlign: "start" }}
                    >
                        Enviaremos un <span>código de seguridad</span> a tu
                        número celular para restablecerla.
                    </Typography>

                    <Box sx={{ mb: 3 }}>
                        <TextField
                            label="Número Dalefon a 10 dígitos"
                            type="text"
                            defaultValue=""
                            onChange={handleTextFieldChange}
                            value={textFieldValue}
                            size="small"
                            sx={{
                                width: "100%",
                                "& .MuiInputBase-input": {
                                    fontSize: "20px",
                                },
                            }}
                        />
                        {errorMessage && (
                            <p style={{ color: "red" }}>{errorMessage}</p>
                        )}
                    </Box>

                    <Box sx={{ mb: 1 }}>
                        <CustomButtonComponent
                            async={true}
                            onClick={handleContinueClick}
                            bgColor={"violet"}
                            text={"Enviar"}
                        />
                    </Box>

                    <SignUpLinkComponent />
                </Grid>
            </Grid>
        </BaseFirstStepsComponent>
    );
};

export default ForgotPasswordComponent;
