import React from "react";

//Moment:
import moment from "moment";
import "moment/locale/es";

//Material UI:
import { Box, Grid } from "@mui/material";

//Components:
import ProgressBar from "modules/portal/components/progress-bar";

//Material UI Styles:
import {
    gridItemConsumed,
    gridItemValidity,
    serviceItemFooter,
} from "./styles-mui/valuesContentStylesMUI";

function ValuesContentCardComponent(myProps) {
    const { item, index, dataExpireDate, graphLabel, colorsList } =
        myProps || {};

    if (item !== undefined && index !== undefined) {
        const { total, unused } = item || {};

        if (total > 0) {
            const consumed = total - unused;

            return (
                <>
                    <Grid container>
                        <Grid item xs={6} sx={gridItemConsumed}>
                            <h5>
                                Consumidos: {consumed} {graphLabel}
                            </h5>
                        </Grid>
                        <Grid item xs={6} sx={gridItemValidity}>
                            <h5>
                                Vigencia{" "}
                                {dataExpireDate
                                    ? moment(
                                          dataExpireDate[index],
                                          "YYYYMMDDhmmss"
                                      ).format("DD/MM/YYYY")
                                    : ""}
                            </h5>
                        </Grid>
                    </Grid>
                    <ProgressBar
                        key={"color-" + index}
                        bgcolor={colorsList[index % colorsList.length]}
                        completed={Math.round(100 - (consumed * 100) / total)}
                    />
                    <Box sx={serviceItemFooter}>
                        <span>
                            Te quedan {Math.round(unused).toLocaleString("en")}{" "}
                            {graphLabel} de{" "}
                            {Math.round(total).toLocaleString("en")}{" "}
                            {graphLabel}
                        </span>
                    </Box>
                </>
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
}

export default ValuesContentCardComponent;
