import React from "react";
import { Grid } from "@mui/material";

import NumberChangedComponent from "./components/number-changed-component";

const NumberChangedPage = () => {
    return (
        <Grid className={"number-changed-page"}>
            <NumberChangedComponent />
        </Grid>
    );
};

export default NumberChangedPage;
