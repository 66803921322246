export default function initObject() {
    return {
        url: "",
        request: "",
        action: "",
        type: { res: null, error: null },
        data: {},
        token: false,
        auth: null,
    };
}
