export const prepaidAuthnInvoiceFormInputs = (params) => {
    const { formVariables, optionsSelected, handlers } = params || {};

    const {
        legalStoredOptions,

        cfdiUseOptions,
        taxRegimeOptions,
        cardTypeOptions,
        formValidations,
    } = formVariables || {};

    const {
        cdfiUseOptionSelected,
        taxRegimeOptionSelected,
        cardTypeOptionSelected,

        legalStoredSelected,
    } = optionsSelected || {};

    const {
        onFocusHandler,
        onChangeHandler,

        cfdiUseOnFocusHandler,
        cfdiUseOnChangeHandler,
        taxRegimeOnFocusHandler,
        taxRegimeOnChangeHandler,
        cardTypeOnFocusHandler,
        cardTypeOnChangeHandler,

        legalStoredOnFocusHandler,
        legalStoredOnChangeHandler,
    } = handlers || {};

    const formInputs = {
        legalInputsRegistered: [
            {
                type: "select",
                id: "legal_stored_id",
                label: "RFC *",
                placeholder: "RFC *",
                containerCls: "",
                onFocusHandler: legalStoredOnFocusHandler,
                onChangeHandler: legalStoredOnChangeHandler,
                validations: formValidations?.legal_stored_id,
                cls: "form-control",
                options: [],
                value: legalStoredSelected,
                valueProgrammatically: true,
            },
            {
                type: "select",
                id: "legal_card_type_id",
                label: "Uso de cfdi *",
                placeholder: "Uso de cfdi *",
                containerCls: "",
                onFocusHandler: cardTypeOnFocusHandler,
                onChangeHandler: cardTypeOnChangeHandler,
                validations: formValidations?.legal_card_type_id,
                cls: "form-control",
                options: cardTypeOptions,
                value: cardTypeOptionSelected,
                valueProgrammatically: true,
            },
        ],
        legalInputs: [
            {
                type: "text",
                id: "legal_id",
                label: "RFC del receptor *",
                placeholder: "RFC del receptor *",
                containerCls: "",
                onFocusHandler: onFocusHandler,
                onChangeHandler: onChangeHandler,
                validations: formValidations?.legal_id,
            },
            {
                type: "text",
                id: "legal_name",
                label: "Razón social *",
                placeholder: "Razón social *",
                containerCls: "",
                onFocusHandler: onFocusHandler,
                onChangeHandler: onChangeHandler,
                validations: formValidations?.legal_name,
            },
            {
                type: "number",
                id: "legal_zipcode",
                label: "Código postal *",
                placeholder: "Código postal *",
                containerCls: "",
                onFocusHandler: onFocusHandler,
                onChangeHandler: onChangeHandler,
                validations: formValidations?.legal_zipcode,
            },
            {
                type: "email",
                id: "legal_email",
                label: "Correo electrónico para recibir la factura *",
                placeholder: "Correo electrónico para recibir la factura *",
                containerCls: "",
                onFocusHandler: onFocusHandler,
                onChangeHandler: onChangeHandler,
                validations: formValidations?.legal_email,
            },
            {
                type: "select",
                id: "legal_tax_regime_id",
                label: "Régimen fiscal *",
                placeholder: "Régimen fiscal *",
                containerCls: "",
                onFocusHandler: taxRegimeOnFocusHandler,
                onChangeHandler: taxRegimeOnChangeHandler,
                validations: formValidations?.legal_tax_regime_id,
                cls: "form-control",
                options: taxRegimeOptions,
                value: taxRegimeOptionSelected,
                valueProgrammatically: true,
            },
            {
                type: "select",
                id: "legal_cfdi_use_id",
                label: "Uso de cfdi *",
                placeholder: "Uso de cfdi *",
                containerCls: "",
                onFocusHandler: cfdiUseOnFocusHandler,
                onChangeHandler: cfdiUseOnChangeHandler,
                validations: formValidations?.legal_cfdi_use_id,
                cls: "form-control",
                options: cfdiUseOptions,
                value: cdfiUseOptionSelected,
                valueProgrammatically: true,
            },
            {
                type: "select",
                id: "legal_card_type_id",
                label: "Uso de cfdi *",
                placeholder: "Uso de cfdi *",
                containerCls: "",
                onFocusHandler: cardTypeOnFocusHandler,
                onChangeHandler: cardTypeOnChangeHandler,
                validations: formValidations?.legal_card_type_id,
                cls: "form-control",
                options: cardTypeOptions,
                value: cardTypeOptionSelected,
                valueProgrammatically: true,
            },
        ],
    };

    return { formInputs };
};
