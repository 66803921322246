export const styleButton = ({ bgColor, height }) => {
    function colorButton() {
        switch (bgColor) {
            case "pink":
                return "linear-gradient( 180deg, rgb(255, 40, 221) 0%, rgb(155 51 134) 100% )";

            case "purple":
                return "linear-gradient( 180deg, rgba(163, 82, 193, 1) 0%, rgba(127, 23, 178, 1) 100%)";

            case "violet":
                return "linear-gradient( 180deg, rgb(136, 100, 241) 0%, rgb(41 17 112) 100% )";

            case "rose":
                return "#fc2bdc";

            case "primary-purple":
                return "linear-gradient( 90deg, #b427ff 20%, #8677f4 100% )";

            case "secondary-purple":
                return "linear-gradient( 90deg, #8677f4 20%, #b427ff 100% )";

            default:
                return "linear-gradient( 180deg, rgb(255, 40, 221) 0%, rgb(155 51 134) 100% )";
        }
    }
    return {
        borderRadius: 20,
        textTransform: "none",
        background: colorButton,
        color: "white",
        height: height,
        fontSize: { xs: 16, md: 18 },
        "&:hover": {
            background: "#a17fff",
        },
    };
};
