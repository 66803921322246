export const taxTypeCardList = () => {
    return {
        taxTypeCardOptions: [
            { id: "04", name: "Tarjeta de crédito" },
            { id: "28", name: "Tarjeta de débito" },
            {
                id: "03",
                name: "Transferencia electrónica",
            },
        ],
    };
};
