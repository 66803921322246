import {
    LOGINACCOUNT,
    LOGINLOGINACCOUNT_ERROR,
    LOGIN_CLEAN,
    LOGIN_CLEAN_ERROR,
    CLEAN_NEWPASSWORD,
    CLEAN_NEWPASSWORD_ERROR,
} from "_models/redux/types";

const initialState = {
    loginAccountObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGINACCOUNT:
        case LOGIN_CLEAN:
        case CLEAN_NEWPASSWORD:
            return {
                ...state,
                loginAccountObj: action.payload,
                loading: true,
            };
        case LOGINLOGINACCOUNT_ERROR:
        case LOGIN_CLEAN_ERROR:
        case CLEAN_NEWPASSWORD_ERROR:
            return {
                ...state,
                loginAccountObj: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}
