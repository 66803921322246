import { useContext } from "react";

import { Grid, Button, Box, Typography } from "@mui/material";

import Compatibility from "assets/images/lead-recovery/compatibilidad.png";

import { LeadContext } from "../../data/contexts/leadContext";

import WhatsappImage from "assets/images/template/whatsapp.png";
import PhoneImage from "assets/images/footer/phone.svg";

const ImeiNotFoundResponseComponent = () => {
    const leadContext = useContext(LeadContext);
    const { dispatchLeadData, formValues } = leadContext || {};
    const { getValues } = formValues;
    const imei = getValues("imei");

    return (
        <Grid container className="lead-recovery-wrapper ">
            <Grid
                item
                md={7}
                xs={12}
                className="lead-recovery-imei-section response-purple centered lead-container-congratulations"
                sx={{ p: { xs: 2, md: 4 } }}
            >
                <Box>
                    <Typography sx={{ fontSize: { xs: 30, md: 30 }, mb: 3 }}>
                        <span className="poppins-bold-font">¡Ups!</span>
                    </Typography>
                    
                    <Typography sx={{ fontSize: { xs: 20, md: 20 }, mb: 3 }}>
                        <span className="poppins-regular-font">
                            Parece que necesitamos más información de tu equipo
                        </span>
                    </Typography>

                    <Typography
                        sx={{
                            fontSize: { xs: 18, md: 18 },
                            mb: 5,
                            mx: { xs: 0, md: 12 },
                        }}
                    >
                        <span className="poppins-regular-font">
                            Queremos brindarte el mejor servicio. Por favor,
                            ponte en contacto con nosotros para revisar la
                            compatibilidad de tu dispositivo.
                        </span>
                    </Typography>

                    <Typography sx={{ fontSize: { xs: 28, md: 28 }, mb: 2 }}>
                        <span className="poppins-semibold-font">Llámanos</span>
                    </Typography>

                    <Grid container sx={{ mt: { xs: 0, md: 3 } }}>
                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 1,
                                    justifyContent: "center",
                                    mb: 1,
                                }}
                            >
                                <img
                                    src={PhoneImage}
                                    className={"phone-compatibility-image"}
                                    alt="Imagen de teléfono"
                                />
                                <Typography
                                    sx={{ fontSize: { xs: 18, md: 23 } }}
                                >
                                    <span className="poppins-semibold-font">
                                        800 649 0026
                                    </span>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 1,
                                    justifyContent: "center",
                                }}
                            >
                                <img
                                    src={PhoneImage}
                                    className={"phone-compatibility-image"}
                                    alt="Imagen de teléfono"
                                />
                                <Typography
                                    sx={{ fontSize: { xs: 18, md: 23 } }}
                                >
                                    <span className="poppins-semibold-font">
                                        777 102 1804
                                    </span>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Typography
                        sx={{ fontSize: { xs: 18, md: 18 }, mt: 5, mb: 1 }}
                    >
                        <span className="poppins-regular-font">
                            O escríbenos por
                        </span>
                    </Typography>

                    <a
                        href={`https://api.whatsapp.com/send/?phone=5215586483831&text=Hola, Dalefon. Al parecer necesitan más información de mi equipo para ver si soy compatible con ustedes, ¿me podrían ayudar por favor? Mi IMEI es: ${imei}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={WhatsappImage}
                            className={"whatsapp-compatibility-image"}
                            alt="Imagen de WhatsApp"
                        />
                    </a>
                </Box>

                <Button
                    type="button"
                    variant="text"
                    onClick={() =>
                        dispatchLeadData({
                            property: "compatibility",
                            value: undefined,
                        })
                    }
                    sx={{
                        fontSize: { xs: 16, md: 18 },
                        color: "#fd45d9",
                        textTransform: "none",
                        mt: { xs: 2, md: 0 },
                    }}
                >
                    <span className="poppins-semibold-font">
                        ¡Inténtalo con otro equipo!
                    </span>
                </Button>
            </Grid>

            <Grid
                item
                md={5}
                xs={12}
                className="display-flex justify-content-center responsive-hidden"
            >
                <img
                    src={Compatibility}
                    className={"compatibility-image"}
                    alt="Imagen de compatibilidad"
                />
            </Grid>
        </Grid>
    );
};

export default ImeiNotFoundResponseComponent;
