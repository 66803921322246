import {
    NEIGHBORHOODS,
    NEIGHBORHOODS_ERROR,
    NEIGHBORHOODS_CLEAN,
    NEIGHBORHOODS_CLEAN_ERROR,
} from "_models/redux/types";

const initialState = {
    neighborhoodsObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case NEIGHBORHOODS:
            return {
                ...state,
                neighborhoodsObj: action.payload,
                loading: true,
            };
        case NEIGHBORHOODS_ERROR:
            return {
                ...state,
                neighborhoodsObj: action.payload,
                loading: false,
            };
        case NEIGHBORHOODS_CLEAN:
        case NEIGHBORHOODS_CLEAN_ERROR:
            return {
                ...state,
                neighborhoodsObj: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
