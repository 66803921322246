import React from "react";
import { Grid } from "@mui/material";

const EsimActivationFooter = (props) => {
    return (
        <Grid container className="qr-footer">
            <Grid item xs={12}>
                <p>Dalefon © Todos los derechos registrados.</p>
            </Grid>
        </Grid>
    );
};

export default EsimActivationFooter;
