import axiosTokenInstance from "services/api/service-client";
import {
    PROFILE_UPDATED_DEVICE,
    PROFILE_UPDATED_ERROR,
} from "_models/redux/types";

export const profileUpdatedoAction = (params) => async (dispatch) => {
    const { gender, birthdate, email } = params || {};
    try {
        const response = await axiosTokenInstance.patch(
            `/api/clients/profile`,
            { gender, birthdate, email }
        );

        const { data } = response;

        dispatch({
            type: PROFILE_UPDATED_DEVICE,
            payload: data,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: PROFILE_UPDATED_ERROR,
            payload: e || {},
        });
    }
};
