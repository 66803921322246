import React from "react";

//Redux:
import { connect } from "react-redux";
import { cleanCompleteProcessAction } from "../../../_models/redux/complete-process/clean";

//CSS:
import "../../../assets/styles/css/menu/menu.css";

//Material UI:
import { Box, Grid, Typography } from "@mui/material";

//Images.
import menuImage from "assets/images/menu/menu.png";

//Hooks:
import { HomeMenuController } from "../hooks/useHomeMenuHook";

//Styles MUI:
import {
    imageStyles,
    containerMenu,
    typographyTitle,
    gridContainerMenu,
    typographyTitleCard,
    typographyDescription,
    gridItemCard
} from "../styles/componentStyle";

//Components:
import BaseFirstStepsComponent from "utils/components/base-first-steps/BaseFirstStepsComponent";
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

const MenuComponent = ({ completeProcess, cleanCompleteProcessAction }) => {
    const { purchaseEvent, clientEvent } = HomeMenuController({
        completeProcess,
        cleanCompleteProcessAction,
    });

    return (
        <BaseFirstStepsComponent>
            <Grid container sx={gridContainerMenu}>
                <Grid item xs={12} sx={{ my: { xs: 2, md: 4 } }}>
                    <Typography sx={typographyTitle}>
                        Bienvenido a Dalefon
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={10}
                    xl={8}
                    sx={containerMenu}
                    className={"container-menu"}
                >
                    <Box>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={12} sm={6} sx={imageStyles}>
                                <img
                                    src={menuImage}
                                    alt="Teléfono celular con el logo de Dalefon en la pantalla y muchos iconos que hacen ilusión a notificaciones"
                                    className="image-menu"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} sx={gridItemCard}>
                                <Box>
                                    <Typography sx={typographyTitleCard}>
                                        Somos la telefonía morada
                                    </Typography>

                                    <Typography sx={typographyDescription}>
                                        Disfruta de tu contenido favorito y de
                                        los mejores beneficios. Con Dalefon, da
                                        un salto hacia el futuro.
                                    </Typography>

                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <CustomButtonComponent
                                                text="Compra tu SIM"
                                                bgColor="pink"
                                                height="60px"
                                                onClick={purchaseEvent}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CustomButtonComponent
                                                text="Ya soy cliente"
                                                bgColor="violet"
                                                height="60px"
                                                onClick={clientEvent}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </BaseFirstStepsComponent>
    );
};

const mapStateToProps = (state) => ({
    completeProcess: state.completeProcess,
});

const mapDispatchToProps = {
    cleanCompleteProcessAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuComponent);
