import {
    ADDPURCHASECONTACTINFO,
    ADDPURCHASECONTACTINFO_ERROR,
} from "_models/redux/types";

import axiosAuthInstance from "../../../services/api/authentication";

export const addPurchaseContactInfoAction =
    (params = {}) =>
    async (dispatch) => {
        const { token, contactPhone, email, name } = params || "";
        const body = {
            contact_phone: contactPhone,
            email: email,
            name: name,
        };

        try {
            const response = await axiosAuthInstance.post(
                `/api/leads/steps/contact-info`,
                body,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            dispatch({
                type: ADDPURCHASECONTACTINFO,
                payload: response,
            });
        } catch (e) {
            console.error(e);
            const { response } = e || {};
            const { data } = response || {};
            dispatch({
                type: ADDPURCHASECONTACTINFO_ERROR,
                payload: data || {},
            });
        }
    };
