export const gridItemContainerCard = {
    backgroundColor: "white",
    borderRadius: 10,
    p: 4,
};

export const typographyTitleCard = {
    fontSize: 24,
    fontWeight: "bold",
    fontFamily: "Poppins-Medium",
    textAlign: "left",
    mb: 2,
};

export const typographySubtitleCard = {
    fontSize: 16,
    textAlign: "left",
    mb: 2,
};

export const textFieldImei = (isPopoverOpen) => {
    return {
        my: { xs: 1, md: 2 },
        borderRadius: "5px",
        border: isPopoverOpen ? "2px solid #3E0D67" : "0px solid #3E0D67",
        backgroundColor: isPopoverOpen ? "#fff" : "transparent",
    };
};
