import { useContext, useEffect } from "react";

//React Router Dom:
import { useNavigate } from "react-router-dom";

//Redux:
import { connect } from "react-redux";
import { cleanCompleteProcessAction } from "../../../_models/redux/complete-process/clean";

//Context:
import { AppContext } from "_models/context/app";

//Pixels:
import ReactGA from "react-ga4";
import TiktokPixel from "tiktok-pixel";
import FacebookPixel from "react-facebook-pixel";

//Material UI:
import { Grid, Typography, Box } from "@mui/material";

//Styles MUI:
import {
    boxImageForm,
    containerClientMenuForm,
    gridContainerClientMenu,
    gridContainerDescriptionForm,
    gridContainerForm,
    titleClientMenu,
    typographyDescription,
    typographyTitle,
} from "../styles-mui/clientMenuStylesMUI";

// CSS:
import "../../../assets/styles/css/client-menu/client-menu.css";

//Images:
import menuImage from "assets/images/client-menu/menu.png";

//Components:
import CustomButtonComponent from "utils/templates/CustomButtonComponent";
import BaseFirstStepsComponent from "utils/components/base-first-steps/BaseFirstStepsComponent";

const ClientMenuComponent = (props) => {
    /*** DEBUG pixels flag ***/
    const debugPixels = (process.env?.REACT_APP_DEBUG_PIXELS || "1") === "1";

    /*** GOOGLE pixels ***/
    const googlePixelId = process.env?.REACT_APP_PURCHASE_GOOGLE_PIXEL || "";
    const googlePixelOptions = {
        debug_mode: debugPixels,
    };
    const googlePixelTagOptions = {
        debug_mode: debugPixels,
    };

    /*** ***/

    /*** TIKTOK pixels  ***/
    const tiktokPixelId = process.env?.REACT_APP_PURCHASE_TIKTOK_PIXEL || "";
    const tiktokPixelAdvancedMatching = {};
    const tiktokPixelOptions = {
        debug: debugPixels,
    };

    /*** ***/

    /*** FACEBOOK pixels  ***/
    const facebookPixelId =
        process.env?.REACT_APP_PURCHASE_FACEBOOK_PIXEL || "";
    const facebookPixelAdvancedMatching = {};
    const facebookPixelOptions = {
        autoConfig: true,
        debug: debugPixels,
    };

    /*** CLARITY initialize ***/
    // const bingPixelId = process.env?.REACT_APP_PURCHASE_CLARITY_PIXEL;

    /*** ***/

    useEffect(() => {
        /*** GOOGLE initialize ***/
        ReactGA.initialize(
            googlePixelId,
            googlePixelOptions,
            googlePixelTagOptions
        );
        ReactGA.send("pageview");
        /*** ***/

        /*** TIKTOK initialize ***/
        TiktokPixel.init(
            tiktokPixelId,
            tiktokPixelAdvancedMatching,
            tiktokPixelOptions
        );
        TiktokPixel.pageView();
        /*** ***/

        /*** FACEBOOK initialize ***/
        FacebookPixel.init(
            facebookPixelId,
            facebookPixelAdvancedMatching,
            facebookPixelOptions
        );
        FacebookPixel.pageView();
        /*** ***/

        // /*** CLARITY initialize ***/
        // clarity.init(bingPixelId);
        // /*** ***/
    }, []);

    const appContext = useContext(AppContext);
    const { setPressed } = appContext;

    let navigate = useNavigate();

    const signupEvent = () => {
        let ttTrackingOpt = {};
        TiktokPixel.track(
            "ClickButton",
            { description: "Signup Button" },
            ttTrackingOpt
        );
        navigate("/sign-up");
    };

    const loginEvent = () => {
        let ttTrackingOpt = {};
        TiktokPixel.track(
            "ClickButton",
            { description: "Login Button" },
            ttTrackingOpt
        );
        navigate(`/login`);
    };

    useEffect(() => {
        return () => {
            props.cleanCompleteProcessAction();
            window.onpopstate = () => {
                setPressed(false);
            };
        };
    }, []);

    return (
        <BaseFirstStepsComponent>
            <Grid container sx={gridContainerClientMenu}>
                <Grid item xs={12} sx={{ my: { xs: 2, md: 4 } }}>
                    <Typography sx={titleClientMenu}>
                        Ya soy cliente Dalefon
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={10}
                    xl={8}
                    sx={containerClientMenuForm}
                    className={"container-client-menu-form"}
                >
                    <Grid item xs={12} sx={{ mb: 5 }}>
                        <form className="form-client-menu">
                            <Grid container sx={gridContainerForm}>
                                <Grid item xs={12} md={5} lg={6}>
                                    <Box sx={boxImageForm}>
                                        <img
                                            src={menuImage}
                                            className={"image-client-menu"}
                                            alt="Icono de una persona con un fondo morado"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={7} lg={6}>
                                    <Grid
                                        container
                                        sx={gridContainerDescriptionForm}
                                    >
                                        <Grid item xs={12}>
                                            <Typography sx={typographyTitle}>
                                                ¡Hola!
                                            </Typography>
                                            <Typography
                                                sx={typographyDescription}
                                            >
                                                Realiza tus recargas, adquiere
                                                un NoPlan, conoce el detalle de
                                                tus consumos, consulta
                                                promociones ¡y más!
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <CustomButtonComponent
                                                        text="Iniciar sesión"
                                                        bgColor="pink"
                                                        height="60px"
                                                        onClick={loginEvent}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <CustomButtonComponent
                                                        text="Crear cuenta"
                                                        bgColor="violet"
                                                        height="60px"
                                                        onClick={signupEvent}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        </BaseFirstStepsComponent>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    completeProcess: state.completeProcess,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    cleanCompleteProcessAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientMenuComponent);
