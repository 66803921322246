import React from "react";
import { Grid } from "@mui/material";
import Logo from "../../../assets/images/logo.png";
import Phone from "../../../assets/images/footer/phone.svg";
import Email from "../../../assets/images/footer/email.svg";
import Facebook from "../../../assets/images/footer/facebook.svg";
import Instagram from "../../../assets/images/footer/instagram.svg";
import YouTube from "../../../assets/images/footer/youtube.svg";
import Twitter from "../../../assets/images/footer/twitter.svg";
import TikTok from "../../../assets/images/footer/tiktok.svg";
import Whatsapp from "../../../assets/images/footer/whatsapp.svg";
import IOS from "../../../assets/images/footer/ios-store.svg";
import Android from "../../../assets/images/footer/android-store.svg";
import Threads from "../../../assets/images/footer/threads.svg";

const PortalFooter = () => {
    const yearNow = new Date().getFullYear();

    return (
        <div className="footer">
            <Grid container spacing={2} className="footer-container">
                <Grid item xs={12} md={4}>
                    <img src={Logo} className={"logo"} />
                    <Grid item xs={12}>
                        <h2>Somos la telefonía morada</h2>
                        <h3 className="p-b-10">Descarga nuestra App</h3>
                        <a href="https://apps.apple.com/mx/app/mi-dalefon/id1664607139">
                            <img src={IOS} className={"stores p-r"} />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=mx.dalefon.app&hl=es_MX&gl=US&pli=1">
                            <img src={Android} className={"stores p-r"} />
                        </a>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4} className={"legales"}>
                    <h5>Legales</h5>
                    <ul>
                        <li>
                            <a type="button" href="/inicio">
                                Política de uso justo
                            </a>
                        </li>
                        <li>
                            <a
                                type="button"
                                href="https://dalefon.mx/terminos-y-condiciones/"
                            >
                                Términos y Condiciones
                            </a>
                        </li>
                        <li>
                            <a type="button" href="https://dalefon.mx/">
                                Consulta los demás legales en dalefon.mx{" "}
                            </a>
                        </li>
                    </ul>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Grid item xs={12} className="contact">
                        <Grid container>
                            <Grid item xs={12}>
                                <h5>Contáctanos</h5>
                            </Grid>
                            <Grid container className="p-t-30" rowSpacing={2}>
                                <Grid item xs={12} className="data">
                                    <img src={Whatsapp} className={"p-r"} />

                                    <a
                                        href="https://api.whatsapp.com/send/?phone=5215586483831&text&type=phone_number&app_absent=0"
                                        target="_blank"
                                    >
                                        5586483831
                                    </a>
                                </Grid>
                                <Grid item xs={12} className="data">
                                    <img src={Phone} className={"p-r"} />
                                    777 102 1804
                                </Grid>
                                <Grid item xs={12} className="data">
                                    <img src={Email} className={"p-r"} />

                                    <a href="mailto: soporte@dalefon.mx">
                                        soporte@dalefon.mx
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="p-t-30 social-media">
                    <Grid item xs={12}>
                        <h2>Síguenos en</h2>
                    </Grid>
                    <Grid item xs={12} className={"icons"}>
                        <a href="https://www.facebook.com/dalefonmx">
                            <img src={Facebook} className={"icon"} />
                        </a>
                        <a href="https://twitter.com/dalefonmx">
                            <img src={Twitter} className={"icon"} />
                        </a>
                        <a href="https://www.instagram.com/dalefonmx/">
                            <img src={Instagram} className={"icon"} />
                        </a>
                        <a href="https://www.tiktok.com/@dalefonmx">
                            <img src={TikTok} className={"icon"} />
                        </a>
                        <a href="https://www.youtube.com/@dalefonmx">
                            <img src={YouTube} className={"icon"} />
                        </a>
                        <a href="https://www.threads.net/@dalefonmx">
                            <img src={Threads} className={"icon"} />
                        </a>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className="bottom">
                <Grid item xs={12} md={6}>
                    <p>© {yearNow} Dalefon. Todos los derechos reservados.</p>
                </Grid>
                <Grid item xs={12} md={6} className="aviso">
                    <li>
                        <a
                            type="button"
                            href="https://dalefon.mx/aviso-de-privacidad/"
                        >
                            Aviso de privacidad
                        </a>
                    </li>
                </Grid>
            </Grid>
        </div>
    );
};

export default PortalFooter;
