import { useReducer } from "react";

// import { useFormValues } from "./lead-useform-methods";

import { useLeadReducer } from "../data/reducers/useLeadReducer";

export const useLeadMainHook = (props) => {
    //Form Library Method
    // const { formValues } = useFormValues();

    const initialLeadMainReducer = {
        // formValues,

        reCaptchaToken: undefined,
        reCaptchaSubmit: undefined,

        formResponse: undefined,
        loaderResponse: undefined,
        sectionResponse: "form",
    };

    const [leadMainReducer, dispatchLeadMainReducer] = useReducer(
        useLeadReducer,
        initialLeadMainReducer
    );

    const leadMainObj = {
        leadMainReducer,
        dispatchLeadMainReducer,
    };

    return {
        leadMainObj,
    };
};
