import React from "react";

//CSS:
import "assets/styles/css/portal/pages/invoice/invoice-your-prepaid.css";

//Material UI:
import {
    Box,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";

//Styles MUI:
import {
    boxContainerButton,
    boxContainerInfoProduct,
    boxContainerSelected,
    gridContainerInvoiceYourPrepaid,
    typographyDate,
    typographyNameProduct,
    typographyPriceProduct,
    typographyItem,
    typographyTitleInvoice,
    typographyTitleCard,
    boxButton,
} from "./styles-mui/invoiceContentStylesMUI";

//Images:
import imgInvoice from "assets/images/portal/pages/invoice/invoice-your-prepaid/invoice-paper.png";

//Components:
import SelectPaymentMethodComponent from "./SelectPaymentMethodComponent";
import NewDataInvoiceComponent from "./NewDataInvoiceComponent";
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

function InvoiceContentPrepaidComponent({
    product_name,
    amount,
    dateOfOperation,
    handleSubmit,
    postData,
    legal_ids,
    selectRFC,
    handleChangeGenerateInvoice,
    register,
    watch,
    errors,
    valuesMetodo,
    loading,
}) {
    return (
        <Container
            sx={{ backgroundColor: "white", py: { xs: 6, md: 9 } }}
            maxWidth={false}
            className="container-invoice-your-prepaid"
        >
            <Container>
                <Grid container sx={gridContainerInvoiceYourPrepaid}>
                    <Grid item xs={12} sx={{ mb: { xs: 3, md: 5 } }}>
                        <Typography variant="h1" sx={typographyTitleInvoice}>
                            Factura tu recarga
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={5}
                        className="container-info-product"
                    >
                        <Box sx={boxContainerInfoProduct}>
                            <Typography variant="h2" sx={typographyNameProduct}>
                                {product_name}
                            </Typography>
                            <Typography sx={typographyPriceProduct}>
                                ${Number(amount).toLocaleString()}
                            </Typography>
                            <Typography sx={typographyDate}>
                                Fecha de operación: {dateOfOperation}
                            </Typography>
                        </Box>
                        <Box sx={{ display: { xs: "none", md: "flex" } }}>
                            <img
                                src={imgInvoice}
                                alt="Dale 12 meses Plus"
                                className="img-invoice"
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6} sx={{ mt: { xs: 4, md: 0 } }}>
                        <form onSubmit={handleSubmit(postData)}>
                            {legal_ids?.length > 0 && (
                                <Box sx={boxContainerSelected}>
                                    <Typography
                                        variant="h2"
                                        sx={typographyTitleCard}
                                    >
                                        Selecciona datos de facturación
                                    </Typography>
                                    <Typography sx={typographyItem}>
                                        RFC *
                                    </Typography>
                                    <FormControl fullWidth>
                                        <InputLabel id="select-rfc-label">
                                            Seleccionar RFC
                                        </InputLabel>
                                        <Select
                                            required
                                            fullWidth
                                            labelId="select-rfc-label"
                                            value={selectRFC}
                                            label="Seleccionar RFC"
                                            onChange={
                                                handleChangeGenerateInvoice
                                            }
                                        >
                                            <MenuItem
                                                value={"New invoice data"}
                                            >
                                                Nuevos datos de facturación
                                            </MenuItem>
                                            {legal_ids?.map((item, index) => (
                                                <MenuItem
                                                    value={item}
                                                    key={item.legal_id}
                                                >
                                                    {item.legal_id}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    {selectRFC !== "" &&
                                        selectRFC !== "New invoice data" && (
                                            <SelectPaymentMethodComponent
                                                register={register}
                                                watch={watch}
                                                errors={errors}
                                                valuesMetodo={valuesMetodo}
                                            />
                                        )}
                                </Box>
                            )}

                            {selectRFC === "New invoice data" ? (
                                <NewDataInvoiceComponent
                                    valuesMetodo={valuesMetodo}
                                    register={register}
                                />
                            ) : null}

                            <Box sx={boxContainerButton}>
                                <Box sx={boxButton}>
                                    <CustomButtonComponent
                                        text={"Generar factura"}
                                        bgColor={"violet"}
                                        height={"50px"}
                                        async={true}
                                        loading={loading}
                                    />
                                </Box>
                            </Box>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
}

export default InvoiceContentPrepaidComponent;
