import {
    ADDPURCHASECONTACTINFO_CLEAN,
    ADDPURCHASECONTACTINFO_CLEAN_ERROR,
} from "_models/redux/types";

export const cleanPurchaseContactInfoAction = () => async (dispatch) => {
    try {
        dispatch({
            type: ADDPURCHASECONTACTINFO_CLEAN,
            payload: [],
        });
    } catch (e) {
        dispatch({
            type: ADDPURCHASECONTACTINFO_CLEAN_ERROR,
            payload: [],
        });
    }
};
