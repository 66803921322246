export const cardContainer = {
    margin: "20px 0 0",
    display: "flex",
    justifyContent: "center",
};

export const typographyCardItem = {
    color: "#51328b",
    fontSize: "25px",
    margin: "30px 0",
};

export const serviceItemContainer = {
    padding: "15px 20px",
    margin: "0 0 25px",
    borderRadius: "10px",
    border: "1px solid #ebebeb",
    background: "no-repeat 0% 0% #fbf9ff padding-box",
    boxShadow:
        "0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24)",
};

export const serviceItemTitle = {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    fontSize: "23px",
    color: "#4e32a5",
    margin: "0 !important",
};

export const gridItemContainerPackages = {
    mt: { xs: 2, md: 0 },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};
