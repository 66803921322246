import {
    MYLEGALS_ACTION,
    MYLEGALS_ACTION_ERROR,
    CLEAN_MYLEGALS_ACTION,
    CLEAN_MYLEGALS_ACTION_ERROR,
} from "_models/redux/types";

const initialState = {
    loaded: undefined,
    payload: undefined,
    status: undefined,
    state: undefined,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case MYLEGALS_ACTION:
            return {
                ...state,
                loaded: true,
                payload: action?.payload || {},
                status: action?.status || null,
                state: "success",
            };
        case MYLEGALS_ACTION_ERROR:
            return {
                ...state,
                loaded: true,
                payload: action?.payload || {},
                status: action?.status || null,
                state: "error",
            };

        case CLEAN_MYLEGALS_ACTION:
            return {
                ...state,
                loaded: false,
                payload: action?.payload || undefined,
                status: undefined,
                state: "success",
            };
        case CLEAN_MYLEGALS_ACTION_ERROR:
            return {
                ...state,
                loaded: false,
                payload: action?.payload || undefined,
                status: undefined,
                state: "error",
            };

        default:
            return state;
    }
}
