import {
    GET_BRANDS_DEVICES,
    GET_BRANDS_DEVICES_ERROR,
    CLEAN_GET_BRANDS_DEVICES,
    CLEAN_GET_BRANDS_DEVICES_ERROR,
} from "_models/redux/types";

const initialState = {
    brandsDevicesObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_BRANDS_DEVICES:
            return {
                ...state,
                brandsDevicesObj: action.payload,
                loading: true,
            };
        case GET_BRANDS_DEVICES_ERROR:
            return {
                ...state,
                brandsDevicesObj: action.payload,
                loading: false,
            };
        case CLEAN_GET_BRANDS_DEVICES:
        case CLEAN_GET_BRANDS_DEVICES_ERROR:
            return {
                ...state,
                brandsDevicesObj: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
