import React from "react";

//CSS:
import "assets/styles/css/utils/components/flows-products/flows-products.css";

// Material Ui:
import {
    Box,
    CircularProgress,
    Container,
    Grid,
    Tab,
    Tabs,
} from "@mui/material";

//Images:
import eSimCompatibleImage from "assets/images/template/esim-compatible.png";
import simCompatibleImage from "assets/images/template/sim-compatible.png";

//Components:
import BaseFirstStepsComponent from "../base-first-steps/BaseFirstStepsComponent";
import ContentPrepaidsProductsComponents from "./components/ContentPrepaidsProductsComponents";
import ContentNoPlansProductsComponents from "./components/ContentNoPlansProductsComponents";

const FlowsProductsComponent = (props) => {
    const {
        tabIndex,

        errorMessage,
        loadingCircular,

        classicSorted,
        plusSorted,
        shareSorted,
        notShareSorted,

        handleTabChange,

        products,
        state,
        flow,
    } = props || {};

    const { supportsDevicesModels, supportsDevices } = state || {};

    const tabsStyle = {
        "& button": {
            borderRadius: 4,
            color: "white",
            height: { xs: 80, md: 170 },
            width: { xs: 150, md: 210 },
            fontSize: { xs: 18, md: 25 },
            fontFamily: "poppins-Regular",
            mx: { xs: 2, md: 2 },
            background: "linear-gradient(to right bottom, #6A3AC3, #370894)",
            textTransform: "none",
        },
        "& button:hover": {
            background: "linear-gradient(to right bottom, #6A3AC3, #350E81)",
            borderRadius: 4,
            color: "#f9f9f9 !important",
        },
        "& button:focus": {
            borderRadius: 4,
            color: "#5d079a",
        },
        "& button:active": {
            backgroundColor: "rgba(222,79,231,0.4)",
            borderRadius: 4,
            color: "#5d079a",
        },
        "& button.Mui-selected": {
            background: "linear-gradient(to right bottom, #52318F, #370894)",
            borderRadius: 4,
            border: 4,
            borderColor: "white",
            color: "#FFFFFF !important",
            fontFamily: "poppins-Bold",
        },
    };

    return (
        <BaseFirstStepsComponent>
            <Container className={"products-component-container"}>
                <Grid container className="compatibility-image">
                    <Grid
                        item
                        xs={12}
                        md={5}
                        sx={{ display: { xs: "none", md: "block" } }}
                    >
                        <img
                            src={
                                supportsDevicesModels ||
                                supportsDevices === true
                                    ? eSimCompatibleImage
                                    : simCompatibleImage
                            }
                            alt="Es compatible con eSIM"
                        />
                    </Grid>
                    <Grid item xs={12} md={7} className="compatibility-message">
                        <Box>
                            <h1>¡Felicidades!</h1>
                            <h2>
                                Tu equipo es compatible con{" "}
                                {supportsDevicesModels ||
                                supportsDevices === true
                                    ? "eSIM Dalefon"
                                    : "SIM física Dalefon"}{" "}
                            </h2>
                            <p>
                                ¿Estás listo para disfrutar de una nueva
                                conexión?
                            </p>
                        </Box>

                        <Box
                            className="products-tab-container"
                            display={"flex"}
                            justifyContent={{ xs: "center", md: "right" }}
                        >
                            <Tabs
                                value={tabIndex}
                                onChange={handleTabChange}
                                TabIndicatorProps={{
                                    sx: { display: "none" },
                                }}
                                sx={tabsStyle}
                            >
                                <Tab label="Recargas" className="tab main" />
                                <Tab label="No Planes" className="tab main" />
                            </Tabs>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            {loadingCircular ? (
                <CircularProgress className="Circular-progress-product" />
            ) : (
                <>
                    {tabIndex === 0 && (
                        <ContentPrepaidsProductsComponents
                            shareSorted={shareSorted}
                            notShareSorted={notShareSorted}
                            tabIndex={tabIndex}
                            flow={flow}
                        />
                    )}
                    

                    {tabIndex === 1 && (
                        <ContentNoPlansProductsComponents
                            classicSorted={classicSorted}
                            plusSorted={plusSorted}
                            products={products}
                            flow={flow}
                        />
                    )}
                </>
            )}

            <Grid className="title">{errorMessage}</Grid>
        </BaseFirstStepsComponent>
    );
};

export default FlowsProductsComponent;
