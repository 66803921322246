import React from "react";

//React Redux:
import { connect } from "react-redux";

//Redux:
import { getMyLegalsAction } from "_models/redux/my-legals/action";
import { cleanMyLegalsAction } from "_models/redux/my-legals/clean-action";

//Reducers:
import { invoiceAutentificationAction } from "_models/redux/invoice-autentification/action";
import { cleanInvoiceAutentificationAction } from "_models/redux/invoice-autentification/clean";

//Hooks:
import { useInvoiceYourPrepaidHook } from "./hooks/useInvoiceYourPrepaidHook";

//Components:
import PortalFooter from "modules/portal/components/footer";
import PortalNavigation from "modules/portal/components/navigation";
import CustomDialog from "utils/helpers/genericModal/modal";
import InvoiceContentPrepaidComponent from "./components/invoice-your-prepaid/InvoiceContentPrepaidComponent";

function InvoiceYourPrepaidComponent(props) {
    const {
        selectRFC,
        valuesMetodo,
        postData,
        handleChangeGenerateInvoice,
        product_name,
        amount,
        dateOfOperation,
        legal_ids,
        invoiceAutentification,
        isDialogOpen,
        setIsDialogOpen,
        register,
        handleSubmit,
        watch,
        errors,
        loading,
    } = useInvoiceYourPrepaidHook(props);
    return (
        <>
            <PortalNavigation />
            <InvoiceContentPrepaidComponent
                product_name={product_name}
                amount={amount}
                dateOfOperation={dateOfOperation}
                handleSubmit={(e) => handleSubmit(e)}
                postData={postData}
                legal_ids={legal_ids}
                selectRFC={selectRFC}
                handleChangeGenerateInvoice={(e) =>
                    handleChangeGenerateInvoice(e)
                }
                register={register}
                watch={watch}
                errors={errors}
                valuesMetodo={valuesMetodo}
                loading={loading}
            />
            <CustomDialog
                title={
                    invoiceAutentification?.payload?.title ||
                    "Error al generar la factura"
                }
                content={
                    <p>
                        {invoiceAutentification?.payload?.detail ||
                            "Oh no, tu factura no se generó. Inténtalo nuevamente."}
                    </p>
                }
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                fullWidth
                maxWidth="xs"
                backgroundColor="white"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: () => setIsDialogOpen(false),
                        buttonColor: "red",
                        textColor: "white",
                    },
                ]}
            />
            <PortalFooter />
        </>
    );
}

/** Redux map state to props **/
const mapStateToProps = (state) => ({
    myLegals: state?.myLegals,
    invoiceAutentification: state?.invoiceAutentification,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    getMyLegalsAction,
    cleanMyLegalsAction,

    invoiceAutentificationAction,
    cleanInvoiceAutentificationAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceYourPrepaidComponent);
