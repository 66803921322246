import { useContext } from "react";
import { Grid } from "@mui/material";
import { InvoiceContext } from "../data/invoiceContext";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const InvoiceErrorResponse = (props) => {
    const invoiceContext = useContext(InvoiceContext);
    const { invoiceStep2 } = invoiceContext;
    const { invoiceDataStep2 } = invoiceStep2 || {};

    const { formResponse } = invoiceDataStep2 || {};

    return (
        <Grid
            container
            className="invoice-response error"
            style={{ textAlign: "center" }}
        >
            <Grid item xs={12}>
                <HighlightOffIcon />
                <h2>Ocurrió un problema</h2>
                <div>{formResponse}</div>
                <a href="/factura">Reintentar</a>
            </Grid>
        </Grid>
    );
};

export default InvoiceErrorResponse;
