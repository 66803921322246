import { useContext } from "react";

import { Grid } from "@mui/material";

import { InvoiceContext } from "./data/invoiceContext";

import InvoiceContentComponent from "./components/InvoiceContentComponent";
import InvoiceNotFoundComponent from "./components/InvoiceNotFoundComponent";
import HelperLoaderPageComponent from "utils/helpers/loaders/pages/helper-loader-page-component";

import Footer from "./widgets/footer";

const InvoicePresentational = () => {
    const invoiceContext = useContext(InvoiceContext);
    const { invoiceData } = invoiceContext || {};
    // const { stateValues } = invoiceContext || {};
    const { pageLoaded } = invoiceData || {};
    return (
        <Grid container className={"invoice-wrapper"}>
            <Grid item xs={12}>
                {pageLoaded ? (
                    <InvoiceContentComponent />
                ) : pageLoaded === false ? (
                    <InvoiceNotFoundComponent />
                ) : (
                    <HelperLoaderPageComponent color={"white"} />
                )}
            </Grid>
            <Grid item xs={12}>
                {" "}
                <Footer />
            </Grid>
        </Grid>
    );
};

export default InvoicePresentational;
