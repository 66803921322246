import {
    POST_INVOICE_SIM_PURCHASE,
    POST_INVOICE_SIM_PURCHASE_ERROR,
} from "_models/redux/types";

import axiosAuthInstance from "services/api/authentication";

export const postInvoiceSimPurchaseAction = (params) => async (dispatch) => {
    const { postData } = params || {};

    try {
        const response = await axiosAuthInstance.post(
            `/api/transactions/invoices`,
            postData || {}
        );

        const { data, status } = response || {};

        dispatch({
            type: POST_INVOICE_SIM_PURCHASE,
            status: status || {},
            payload: data,
        });
    } catch (e) {
        console.error(e);

        const { response } = e || {};
        const { data, status } = response || {};

        dispatch({
            type: POST_INVOICE_SIM_PURCHASE_ERROR,
            status: status || {},
            payload: data || {},
        });
    }
};
