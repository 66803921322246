// Import: Middleware Action & Custom Types
import {
    FORGOT_PASSWORD_CLEAN,
    FORGOT_PASSWORD_CLEAN_ERROR,
} from "_models/redux/types";

// Action name
const action = "cleanForgotAction";

// Action function
export const cleanForgotAction = (params) => async (dispatch) => {
    // Endpoint: Try & Catch

    try {
        dispatch({
            type: FORGOT_PASSWORD_CLEAN,
            payload: [],
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: FORGOT_PASSWORD_CLEAN_ERROR,
            payload: [],
        });
    }
};
