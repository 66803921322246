import { PURCHASEREQUEST, PURCHASEREQUEST_ERROR } from "_models/redux/types";

import axiosTokenInstance from "services/api/service-client";

export const purchaseRequestAction = (params) => async (dispatch) => {
    const { prepaid, tokenView } = params || "";
    const { app_source_id, carrier_id, product_sku } = prepaid;
    try {
        const response = await axiosTokenInstance.post(
            `/api/prepaids/v3/purchase-request`,
            { prepaid }
        );

        dispatch({
            type: PURCHASEREQUEST,
            payload: response,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: PURCHASEREQUEST_ERROR,
            payload: e || {},
        });
    }
};
