import { useReducer, useEffect, useContext } from "react";

import { useLocation } from "react-router-dom";

import { useReducerHelper } from "utils/helpers/reducers/useReducerHelper";

import { LeadContext } from "../data/contexts/leadContext";

export const useLeadImeiHook = (props) => {
    const leadContext = useContext(LeadContext);

    /*** ***/
    const { dispatchLeadData } = leadContext || {};

    const {
        postLeadCheckImei,
        postLeadCheckImeiAction,
        formValues,
        setCallActionFlag,
        setReCaptchaAction,
        reCaptchaAction,
        token,
    } = props || {};

    //HOOKS VARIABLES
    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const lead = searchParams.get("l");
    const key = searchParams.get("x");

    const { getValues } = formValues;

    //HOOK REDUCERS

    const initialLeadImeiReducer = {
        //RECAPTCHA
        // Constants
        imeiRecaptchaKey: process.env.REACT_APP_RECAPTCHA_ENTERPRISE || "",
        imeiRecaptchaUseEnterprise: true,
        imeiRecaptchaBadge: "inline",
        imeiRecaptchaTheme: "default",
        // Custom
        imeiRecaptchaId: "imeiRecaptchaId",
        imeiRecaptchaAction: "recover_leads_check_imei",
        // Variables
        imeiRecaptchaEventFlag: undefined,
        imeiRecaptchaToken: undefined,

        //OTHERS
        loading: false,

        imeiValue: undefined,
    };

    const [leadImeiReducer, dispatchLeadImeiReducer] = useReducer(
        useReducerHelper,
        initialLeadImeiReducer
    );

    const {
        imeiRecaptchaKey,
        imeiRecaptchaUseEnterprise,
        imeiRecaptchaBadge,
        imeiRecaptchaTheme,

        imeiRecaptchaAction,
        imeiRecaptchaId,

        imeiRecaptchaEventFlag,
        imeiRecaptchaToken,

        imeiValue,
    } = leadImeiReducer || {};

    //HOOK FUNCTIONS

    const returnToCheckImei = (from) => {
        // console.log("|=> returnToCheckImei > from: ", from);
    };

    const searchCompatibility = () => {
        if (getValues("imei")) {
            dispatchLeadImeiReducer({
                property: "imeiValue",
                value: getValues("imei"),
            });

            dispatchLeadImeiReducer({
                property: "imeiRecaptchaEventFlag",
                value: true,
            });

            dispatchLeadImeiReducer({
                property: "loading",
                value: true,
            });

            if (reCaptchaAction === "recover_leads_check_imei") {
                setCallActionFlag(true);
            } else {
                setReCaptchaAction("recover_leads_check_imei");
            }
        } else {
        }
    };

    useEffect(() => {
        if (reCaptchaAction === "recover_leads_check_imei") {
            setCallActionFlag(true);
        }
    }, [reCaptchaAction]);

    const onSubmit = (e) => {};

    // HOOK USEEFFECTS

    useEffect(() => {
        const { loaded, state, status, payload } = postLeadCheckImei || {};
        const { imei, is_valid, supports_esim, contact_support } =
            payload || {};

        if (loaded) {
            dispatchLeadImeiReducer({
                property: "imeiRecaptchaEventFlag",
                value: undefined,
            });

            dispatchLeadImeiReducer({
                property: "imeiRecaptchaToken",
                value: undefined,
            });

            dispatchLeadImeiReducer({
                property: "loading",
                value: false,
            });

            if (status === 200) {
                if (supports_esim) {
                    dispatchLeadData({
                        property: "compatibility",
                        value: "esim",
                    });
                } else if (is_valid) {
                    dispatchLeadData({
                        property: "compatibility",
                        value: "sim",
                    });
                } else if (!contact_support && !supports_esim && !is_valid) {
                    dispatchLeadData({
                        property: "compatibility",
                        value: "incompatible",
                    });
                } else if (contact_support && !supports_esim && !is_valid) {
                    dispatchLeadData({
                        property: "compatibility",
                        value: "not-found",
                    });
                }
            } else if (status >= 400 && status < 500) {
                dispatchLeadData({
                    property: "compatibility",
                    value: "not-found",
                });
            }

            setCallActionFlag(false);
        }
    }, [postLeadCheckImei]);

    useEffect(() => {
        if (token && reCaptchaAction === "recover_leads_check_imei") {
            postLeadCheckImeiAction({
                lead_id: lead,
                lead_key: key,
                recaptcha: token,
                imei: imeiValue,
            });
        }
    }, [token]);

    const setTokenLeadImeiCallback = (recpatchToken) => {
        dispatchLeadImeiReducer({
            property: "imeiRecaptchaToken",
            value: recpatchToken,
        });
    };

    //HOOK OBJECTS

    const leadImeiRecaptchaProps = {
        recaptchaKey: imeiRecaptchaKey,
        recaptchaUseEnterprise: imeiRecaptchaUseEnterprise,
        recaptchaBadge: imeiRecaptchaBadge,
        recaptchaTheme: imeiRecaptchaTheme,

        recaptchaAction: imeiRecaptchaAction,
        recaptchaId: imeiRecaptchaId,

        recaptchaEventFlag: imeiRecaptchaEventFlag,
        recaptchaToken: imeiRecaptchaToken,

        dispatchReducer: dispatchLeadImeiReducer,

        setTokenCallback: setTokenLeadImeiCallback,
    };

    return {
        //Reducer
        leadImeiReducer,
        dispatchLeadImeiReducer,
        //Functions
        returnToCheckImei,
        searchCompatibility,
        onSubmit,
        //Objects
        leadImeiRecaptchaProps,
    };
};
