import React from "react";

//CSS:
import "assets/styles/css/utils/components/cards/card-no-plans/card-no-plans.css";

// Material Ui:
import { Grid, Box, Typography } from "@mui/material";

//Images:
import imgShareWifi from "assets/images/utils/components/cards/hotspot-share.svg";
import imgNoShareWifi from "assets/images/utils/components/cards/no-hotspot.svg";
import imgSocialmedia from "assets/images/utils/components/cards/social-media.svg";
import imgShadowCard from "assets/images/utils/components/cards/shadow-card.png";

//React Icons:
import { FaCheck } from "react-icons/fa6";

//Styles MUI:
import {
    boxContainerButton,
    boxContainerCardNoPlans,
    boxContainerImg,
    boxContainerMinAndSMS,
    boxContainerNoShareAndShareWifi,
    typographyBeforePrice,
    typographyBestSelling,
    typographyDuration,
    typographyNote,
    typographyPrice,
    typographyNoShareAndShareWifi,
    typographyPlusGB,
    typographyPricePerMonth,
} from "./styles-mui/cardNoPlansStylesMUI";

//Components:
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

/*Component properties: 

    element = {
      productName: (string) "Name of product",
      duration: (int),
      price: (int),
      decimals: (int),
      hotspot: (bool) true || false,
      note: (string) "The note in the footer",
      bestSelling: (bool) false || true
    } */

function CardNoPlansComponent({
    element,
    oneElement = false,
    bestSelling,
    routeRecarga,
    styleCard,
}) {
    //REVISAR
    const pricePer30Days = (element.offer / element.product_duration) * 30;
    const pricePerMonth = parseFloat(pricePer30Days.toFixed(2));
    const durationInMonths = Math.round(element.product_duration / 30);

    const totalGBSocialMedia =
        (element.social_network_data +
            element.social_network_data_roaming +
            element.social_network_data_throttling) /
        element.social_gigabyte_divisor;

    return (
        <Grid
            item
            xs={12}
            md={oneElement === true ? 12 : 5}
            lg={oneElement === true ? 12 : 3.6}
            xl={oneElement === true ? 12 : 2.5}
            className="container-card-no-plans"
            sx={{ textAlign: "start" }}
        >
            <Box
                className={`card-no-plans ${
                    bestSelling ? "card-no-plans-best-selling" : null
                }`}
                sx={boxContainerCardNoPlans(styleCard)}
            >
                {/* Titulo Más vendido */}
                {bestSelling ? (
                    <Typography
                        className="title-best-selling"
                        sx={typographyBestSelling}
                    >
                        MÁS VENDIDO
                    </Typography>
                ) : null}

                {/* Titulo meses */}
                <Typography sx={typographyDuration}>
                    {durationInMonths} MESES
                </Typography>

                <Typography sx={typographyBeforePrice}>
                    Pago de contado:
                </Typography>

                {/* Sección precio */}
                <Typography sx={typographyPrice}>
                    ${element.offer.toLocaleString()}
                </Typography>

                <Typography sx={typographyPricePerMonth}>
                    te saldría en ${pricePerMonth}/mes
                </Typography>

                {totalGBSocialMedia > 0 ? (
                    <>
                        {/* Sección más GB para redes sociales */}
                        <Typography sx={typographyPlusGB}>
                            + Redes Sociales
                        </Typography>

                        {/* Redes sociales */}
                        <Box sx={boxContainerImg}>
                            <img
                                src={imgSocialmedia}
                                alt="Redes sociales"
                                className="img-social-media-card-no-plans"
                            />
                        </Box>
                    </>
                ) : null}

                {/* No Share and Share Wifi */}
                <Box sx={boxContainerNoShareAndShareWifi}>
                    <img
                        src={element.hotspot ? imgShareWifi : imgNoShareWifi}
                        alt={`Imagen sobre una antena que ${
                            element.hotspot ? "" : "NO "
                        }comparte wifi`}
                        width={15}
                        height={15}
                        className="circular-icon-no-plans"
                    />
                    <Typography sx={typographyNoShareAndShareWifi}>
                        {element.hotspot
                            ? "SÍ comparte datos"
                            : "No comparte datos"}
                    </Typography>
                </Box>

                {/* Minutos y SMS */}
                <Box sx={boxContainerMinAndSMS}>
                    <FaCheck className="circular-icon-no-plans" />
                    <Typography>Minutos y SMS</Typography>
                </Box>

                {/* Botón */}
                <Box sx={boxContainerButton}>
                    <Box sx={{ width: "60%" }}>
                        <CustomButtonComponent
                            text={"¡Lo quiero!"}
                            height={50}
                            bgColor={"primary-pink"}
                            onClick={() => routeRecarga()}
                        />
                    </Box>
                </Box>

                {/* Nota */}
                <Typography sx={typographyNote}>
                    *Al adquirir este producto, estás comprando un{" "}
                    {element.name}
                </Typography>
            </Box>
            {/* <img
                src={imgShadowCard}
                alt="shadow"
                className="img-shadow-card-no-plans"
            /> */}
        </Grid>
    );
}

export default CardNoPlansComponent;
