import { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { leadsPurchaseRequestAction } from "_models/redux/leads-purchase-request/action";
import { cleanLeadsPurchaseRequestAction } from "_models/redux/leads-purchase-request/clean";

import ReactGA from "react-ga4";
import TiktokPixel from "tiktok-pixel";
import FacebookPixel from "react-facebook-pixel";
// import { clarity } from "react-microsoft-clarity";

const PaymentPackages = (props) => {
    const history = useNavigate();

    /*** Webcheckout Url ***/
    const webcheckoutUrl = process.env.REACT_APP_WEBCHECKOUT_URL;

    /*** tokenView ***/
    const { state } = useLocation();
    const tokenView = state?.tokenView;

    /*** Get Redux props ***/
    const {
        leadsPurchaseRequestAction,
        cleanLeadsPurchaseRequestAction,
        leadsPurchaseRequest,
    } = props || {};

    /*** DEBUG pixels flag ***/
    const debugPixels = (process.env?.REACT_APP_DEBUG_PIXELS || "1") === "1";

    /*** GOOGLE pixels ***/
    const googlePixelId = process.env?.REACT_APP_COMPRA_GOOGLE_PIXEL || "";
    const googlePixelOptions = {
        debug_mode: debugPixels,
    };
    const googlePixelTagOptions = {
        debug_mode: debugPixels,
    };

    /*** ***/

    /*** TIKTOK pixels  ***/
    const tiktokPixelId = process.env?.REACT_APP_COMPRA_TIKTOK_PIXEL || "";
    const tiktokPixelAdvancedMatching = {};
    const tiktokPixelOptions = {
        debug: debugPixels,
    };

    /*** ***/

    /*** FACEBOOK pixels  ***/
    const facebookPixelId = process.env?.REACT_APP_COMPRA_FACEBOOK_PIXEL || "";
    const facebookPixelAdvancedMatching = {};
    const facebookPixelOptions = {
        autoConfig: true,
        debug: debugPixels,
    };

    /*** ***/

    /*** CLARITY initialize ***/
    // const bingPixelId = process.env?.REACT_APP_COMPRA_CLARITY_PIXEL;

    useEffect(() => {
        /*** GOOGLE initialize ***/
        ReactGA.initialize(
            googlePixelId,
            googlePixelOptions,
            googlePixelTagOptions
        );
        ReactGA.send("pageview");
        /*** ***/

        /*** TIKTOK initialize ***/
        TiktokPixel.init(
            tiktokPixelId,
            tiktokPixelAdvancedMatching,
            tiktokPixelOptions
        );
        TiktokPixel.pageView();
        /*** ***/

        /*** FACEBOOK initialize ***/
        FacebookPixel.init(
            facebookPixelId,
            facebookPixelAdvancedMatching,
            facebookPixelOptions
        );

        FacebookPixel.pageView();
        /*** ***/

        /*** CLARITY initialize ***/
        // clarity.init(bingPixelId);
        /*** ***/

        /*** Redux Get Info ***/

        leadsPurchaseRequestAction({ tokenView });

        return () => {
            cleanLeadsPurchaseRequestAction();
        };
    }, []);

    useEffect(() => {
        const { leadsPurchaseRequestObj } = leadsPurchaseRequest || {};
        const { data, response } = leadsPurchaseRequestObj || {};
        const { uuid } = data || {};

        const { context } = response?.data || {};

        if (uuid) {
            const purchaseUrl = webcheckoutUrl + "/compra?uuid=" + uuid;

            window.location.replace(purchaseUrl);
        } else if (context) {
            history("/compras");
        }
    }, [leadsPurchaseRequest]);

    return null;
};

const mapStateToProps = (state) => ({
    leadsPurchaseRequest: state?.leadsPurchaseRequest,
});
const mapDispatchToProps = {
    leadsPurchaseRequestAction,
    cleanLeadsPurchaseRequestAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPackages);
