import { usePrepaidAuthnInvoiceVariables } from "./usePrepaidAuthnInvoiceVariables_";

// import { usePrepaidAuthnInvoiceForm } from "./usePrepaidAuthnInvoiceForm";

export const usePrepaidAuthnInvoiceContainer = (props, id_transaction) => {
    const { variables_ } = usePrepaidAuthnInvoiceVariables(props);

    // const formHook = usePrepaidAuthnInvoiceForm(props);

    const prepaidAuthnInvoiceValues = {
        variables_,
        // formHook,
        id_transaction,
    };

    return { prepaidAuthnInvoiceValues };
};
