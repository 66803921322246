import { useState, useEffect, useReducer } from "react";

import { formInputs } from "../data/inputs/leadFormInputs";

import { getRegisterOptions } from "../data/options/leadFormRegisterOptions";

import { useLeadReducer } from "../data/reducers/useLeadReducer";

export const useFormInputsHook = ({
    formValues,
    staticStatesOptions,
    getLeadOffers,
}) => {
    const { errors } = formValues;

    const { registerOptions } = getRegisterOptions() || {};

    const initialSelectsReducer = {
        stateOptionSelected: {},
        municipalityOptionSelected: {},
        neighborhoodOptionSelected: {},
        offersOptionSelected: undefined,

        stateOptions: staticStatesOptions || [],
        municipalityOptions: [],
        neighborhoodOptions: [],
        offersOptions: [],
    };

    const [selectsReducer, dispatchSelectsReducer] = useReducer(
        useLeadReducer,
        initialSelectsReducer
    );

    const onFocusHandler = () => {};
    const onChangeHandler = () => {};

    useEffect(() => {
        const { payload } = getLeadOffers || {};
        const { products } = payload || {};

        dispatchSelectsReducer({
            property: "offersOptions",
            value: products,
        });
    }, [getLeadOffers]);

    const { inputs } = formInputs({
        errors,
        registerOptions,

        selectsReducer,
        dispatchSelectsReducer,

        handlers: {
            onFocusHandler,
            onChangeHandler,
        },
    });

    return { inputs };
};
