import {
    LEADS_PURCHASEREQUEST,
    LEADS_PURCHASEREQUEST_ERROR,
    LEADS_PURCHASEREQUEST_CLEAN,
    LEADS_PURCHASEREQUEST_ERROR_CLEAN,
} from "_models/redux/types";

const initialState = {
    leadsPurchaseRequestObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LEADS_PURCHASEREQUEST:
            return {
                ...state,
                leadsPurchaseRequestObj: action.payload,
                loading: true,
            };
        case LEADS_PURCHASEREQUEST_ERROR:
            return {
                ...state,
                leadsPurchaseRequestObj: action.payload,
                loading: false,
            };
        case LEADS_PURCHASEREQUEST_CLEAN:
        case LEADS_PURCHASEREQUEST_ERROR_CLEAN:
            return {
                ...state,
                leadsPurchaseRequestObj: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
