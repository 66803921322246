import React from "react";
import { useNavigate } from "react-router-dom";

import { Grid, Button } from "@mui/material";

const SignUpLinkComponent = () => {
    const history = useNavigate();
    const goToSignUp = () => {
        history("/sign-up");
    };
    return (
        <Grid container className={"signup-link-container"}>
            <p className={"signup-link-text"}>
                ¿No tienes una cuenta?
                <Button variant="text" onClick={goToSignUp}>
                    <span>Regístrate ahora.</span>
                </Button>
            </p>
        </Grid>
    );
};

export default SignUpLinkComponent;
