import { useEffect, useState, useRef } from "react";

//React Router Dom:
import { useLocation, useNavigate } from "react-router-dom";

//React Hook Form:
import { useForm } from "react-hook-form";

//Pixels:
import ReactGA from "react-ga4";
import TiktokPixel from "tiktok-pixel";
import FacebookPixel from "react-facebook-pixel";
// import { clarity } from "react-microsoft-clarity";

//React Scroll:
import { animateScroll as scroll } from "react-scroll";

function useCompatibilityPurchasePageHooks(props) {
    /*** DEBUG pixels flag ***/
    const debugPixels = (process.env?.REACT_APP_DEBUG_PIXELS || "1") === "1";
    /*** GOOGLE pixels ***/
    const googlePixelId = process.env?.REACT_APP_PURCHASE_GOOGLE_PIXEL || "";
    const googlePixelOptions = {
        debug_mode: debugPixels,
    };
    const googlePixelTagOptions = {
        debug_mode: debugPixels,
    };

    /*** ***/

    /*** TIKTOK pixels  ***/
    const tiktokPixelId = process.env?.REACT_APP_PURCHASE_TIKTOK_PIXEL || "";
    const tiktokPixelAdvancedMatching = {};
    const tiktokPixelOptions = {
        debug: debugPixels,
    };

    /*** ***/

    /*** FACEBOOK pixels  ***/
    const facebookPixelId =
        process.env?.REACT_APP_PURCHASE_FACEBOOK_PIXEL || "";
    const facebookPixelAdvancedMatching = {};
    const facebookPixelOptions = {
        autoConfig: true,
        debug: debugPixels,
    };

    /*** CLARITY initialize ***/
    // const bingPixelId = process.env?.REACT_APP_PURCHASE_CLARITY_PIXEL;

    /*** ***/

    const textFieldRef = useRef(null);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [checkboxError, setCheckboxError] = useState("");
    const [isModelsSelected, setIsModelsSelected] = useState(false);
    const [isBrandSelected, setIsBrandSelected] = useState(false);
    const [verifyButtonClicked, setVerifyButtonClicked] = useState(false);
    const [selectedBrandId, setSelectedBrandId] = useState(null);
    const [selectedModelId, setSelectedModelId] = useState("");
    const [imeiValue, setImeiValue] = useState("");
    const [imeiLoading, setImeiLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [imeiIsValid, setImeiIsValid] = useState(undefined);
    const [deviceIsValid, setDeviceIsValid] = useState(undefined);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState("");
    const [isOtherModelSelected, setIsOtherModelSelected] = useState(false);
    const [isOtherSelected, setIsOtherSelected] = useState(false);
    const [responseErrors, setResponseErrors] = useState();

    let navigate = useNavigate();
    const { state } = useLocation();
    const { dataToken } = state || undefined;
    const { checkImei, postCompatibleDevices } = props;
    const { imeiObj, loading } = checkImei || "undefined";
    let { data, response } = imeiObj || "undefined";
    const { compatibleDevicesObj } = postCompatibleDevices || {};

    const registerOptions = {
        imeiValue: {
            required: "IMEI es requerido",
            maxLength: 15,
            minLength: 15,
            pattern: {
                value: /[0-9]{15}/,
            },
        },
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmitByModel = (e) => {
        e.preventDefault();

        setVerifyButtonClicked(true);

        if (selectedBrandId && selectedModelId) {
            if (!isCheckboxChecked) {
                // Establece el mensaje de error
                setCheckboxError(
                    "Marca la casilla antes de enviar el formulario."
                );
                return;
            }

            // Limpia el mensaje de error si el checkbox está marcado
            setCheckboxError("");

            setIsLoading(true);
            const payload = {
                device_id: selectedModelId,
                dataToken: dataToken,
            };
            props.postCheckingCompatibleDevice(payload);
        }
    };

    const onSubmit = (e) => {
        setImeiLoading(true);
        const post = {
            imei: imeiValue,
            token: dataToken,
        };

        props.checkImeiAction(post);
        // props.postIncidence(post);
    };

    const handleBrandChange = (event) => {
        const selectedValue = event.target.value;

        if (selectedValue === "otro") {
            setIsOtherSelected(true);
            setIsPopoverOpen(true);
            textFieldRef.current.focus();
            setSelectedBrand("");
        } else {
            setIsOtherSelected(false);
            setSelectedBrand(selectedValue);
            setIsBrandSelected(!!selectedValue);
        }
    };

    const handleModelChange = (event) => {
        const selectedValue = event.target.value;

        if (selectedValue === "otro") {
            // Open the IMEI validation modal
            setIsPopoverOpen(true);
            // Optionally, perform other actions related to selecting "Other"
            setIsOtherModelSelected(true);
            // Clear selected model when "Other" is selected
            setSelectedModelId("");
        } else {
            setSelectedModelId(selectedValue);
            setIsModelsSelected(!!selectedValue);
            setIsOtherModelSelected(false);
        }
    };

    const scrollToTextField = () => {
        const element = document.getElementById("outlined-basic");
    
        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
            element.focus();
        }
    };

    const calculatePopoverPosition = () => {
        if (textFieldRef.current) {
            const textFieldRect = textFieldRef.current.getBoundingClientRect();
            return {
                top: textFieldRect.bottom + window.scrollY,
                left: textFieldRect.left + window.scrollX,
            };
        }
        return { top: 0, left: 0 };
    };

    const handleCheckboxChange = (event) => {
        setIsCheckboxChecked(event.target.checked);
    };

    useEffect(() => {
        /*** GOOGLE initialize ***/
        ReactGA.initialize(
            googlePixelId,
            googlePixelOptions,
            googlePixelTagOptions
        );
        ReactGA.send("pageview");
        /*** ***/

        /*** TIKTOK initialize ***/
        TiktokPixel.init(
            tiktokPixelId,
            tiktokPixelAdvancedMatching,
            tiktokPixelOptions
        );
        TiktokPixel.pageView();
        /*** ***/

        /*** FACEBOOK initialize ***/
        FacebookPixel.init(
            facebookPixelId,
            facebookPixelAdvancedMatching,
            facebookPixelOptions
        );
        FacebookPixel.pageView();

        /*** CLARITY initialize ***/
        // clarity.init(bingPixelId);
        /*** ***/
        /*** ***/
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        props.listBrandsDevices();
        if (dataToken === undefined) window.location.replace("/purchase");
        return () => {
            props.cleanCheckingDeviceAction();
            setImeiValue(undefined);
            props.cleanCheckImeiAction();
        };
    }, []);

    useEffect(() => {
        const { imeiObj, loading } = checkImei || {};
        const { response } = imeiObj || {};

        if (typeof imeiObj != "undefined") {
            if (typeof imeiObj.data != "undefined") {
                const { is_valid } = imeiObj.data || {};
                setImeiIsValid(is_valid);
            } else if (typeof response != "undefined") {
                const { data } = response || {};

                const { additional_details } = data || "undefined";
                const responseErrorsObj =
                    typeof additional_details != "undefined"
                        ? additional_details.errors
                        : [];

                setResponseErrors(responseErrorsObj);
            }
        }

        if (!loading) {
            setImeiIsValid(undefined);
        }
    }, [checkImei]);

    useEffect(() => {
        const { compatibleDevicesObj, loading } = postCompatibleDevices || {};
        const { data } = compatibleDevicesObj || {};
        const { is_valid } = data || {};

        if (typeof imeiObj != "undefined") {
            if (typeof is_valid != "undefined") {
                setDeviceIsValid(is_valid);
            } else if (typeof response != "undefined") {
                const { data } = response || {};

                const { additional_details } = data || "undefined";
                const responseErrorsObj =
                    typeof additional_details != "undefined"
                        ? additional_details.errors
                        : [];

                setResponseErrors(responseErrorsObj);
            }
        }

        if (!loading) {
            setDeviceIsValid(undefined);
        }
    }, [postCompatibleDevices]);

    useEffect(() => {
        let supportsDevices = data?.supports_esim;
        const supportsDevicesModels = compatibleDevicesObj?.data?.supports_esim;

        if (loading || postCompatibleDevices?.loading) {
            if (
                typeof imeiIsValid !== "undefined" ||
                typeof deviceIsValid !== "undefined"
            ) {
                if (imeiIsValid || deviceIsValid) {
                    // TODO: Get lead id

                    /*** GOOGLE pixel  ***/
                    // Track the "search" event
                    ReactGA.event("ecommerce", "search", {
                        search_term: "",
                    });
                    /*** ***/

                    /*** TIKTOK pixel  ***/
                    let ttParams = {};
                    let ttTrackingOpt = {
                        // event_id: "1234", //OPTIONAL
                    };
                    TiktokPixel.track("Search", ttParams, ttTrackingOpt);

                    /*** ***/

                    /*** FACEBOOK pixel  ***/
                    let fbParams = {};
                    FacebookPixel.track("Search", fbParams);

                    /*** ***/

                    navigate(`/purchase/products`, {
                        state: {
                            tokenView: dataToken,
                            supportsDevices,
                            supportsDevicesModels,
                        },
                    });
                } else if (data?.contact_support) {
                    navigate(`/purchase/comprobar-compatibilidad`, {
                        state: { token: dataToken, imeiValue },
                    });
                } else {
                    navigate(`/purchase/no-compatible`, {
                        state: { token: dataToken },
                    });
                }
            } else {
            }
        }
    }, [imeiIsValid, deviceIsValid]);

    useEffect(() => {
        if (response?.status >= 400) {
            setImeiLoading(false);
        }
        if (checkImei?.imeiObj?.response?.status === 401) {
            navigate(`/purchase`);
        }
    }, [checkImei]);

    useEffect(() => {
        if (compatibleDevicesObj?.response?.status >= 400) {
            setIsLoading(false);
        }
        if (compatibleDevicesObj?.response?.status === 401) {
            navigate(`/purchase`);
        }
    }, [compatibleDevicesObj]);

    useEffect(() => {
        if (isPopoverOpen) scrollToTextField();
        return () => {};
    }, [isPopoverOpen]);

    // Update the useEffect to conditionally call the listDevicesModels function
    useEffect(() => {
        if (selectedBrand !== "" && !isOtherSelected) {
            props.listDevicesModels(selectedBrand);
        }
    }, [selectedBrand, isOtherSelected]);

    useEffect(() => {
        if (isOtherModelSelected) {
            // Open the IMEI validation modal
            setIsPopoverOpen(true);

            // Optionally, perform other actions related to selecting "Other"
            // Set focus on the IMEI TextField
            textFieldRef.current.focus();
        }
    }, [isOtherModelSelected]);

    return {
        handleSubmit,
        onSubmit,
        selectedBrandId,
        verifyButtonClicked,
        isBrandSelected,
        setIsPopoverOpen,
        isPopoverOpen,
        handleBrandChange,
        setSelectedBrandId,
        selectedModelId,
        handleModelChange,
        isModelsSelected,
        isCheckboxChecked,
        handleCheckboxChange,
        checkboxError,
        onSubmitByModel,
        isLoading,
        imeiValue,
        setImeiValue,
        textFieldRef,
        response,
        responseErrors,
        imeiLoading,
        calculatePopoverPosition,
        register,
        errors,
        registerOptions,
    };
}

export default useCompatibilityPurchasePageHooks;
