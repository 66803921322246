import axiosAuthInstance from "../../../services/api/authentication";
import { VALIDATEPIN, VALIDATEPIN_ERROR } from "_models/redux/types";

// Action function
export const validatePinAction = (params) => async (dispatch) => {
    const { directNumber, code } = params || "undefined";

    // Endpoint: Try & Catch
    try {
        const response = await axiosAuthInstance.post(
            `/api/clients/${directNumber}/pin-password/${code}/validate`,
            { directNumber, code }
        );

        const { data, status } = response || {};

        dispatch({
            type: VALIDATEPIN,
            status: status || {},
            payload: data || {},
        });
    } catch (e) {
        const { response } = e || {};
        const { data, status } = response || {};

        dispatch({
            type: VALIDATEPIN_ERROR,
            status: status || {},
            payload: data || {},
        });
    }
};
