import { LOGIN, LOGIN_ERROR } from "_models/redux/types";

import axiosAuthInstance from "../../../services/api/authentication";

export const loginAction = (params) => async (dispatch) => {
    const { direct_number, password } = params;

    try {
        const response = await axiosAuthInstance.post(`/api/clients/login`, {
            direct_number,
            password,
        });

        const { data, status } = response;

        dispatch({
            type: LOGIN,
            payload: data,
            status: status,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: LOGIN_ERROR,
            payload: e || {},
        });
    }
};
