import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";

import { AppContext } from "_models/context/app";
import { LoginContext } from "./context";

import { useLogin } from "./hook";

import { loginAction } from "_models/redux/login/action";

import { setLoginDataHelper } from "_controllers/helpers/setLoginData";
import { setInfoDataHelper } from "_controllers/helpers/setInfoData";

//Components:
import LoginComponent from "./components/LoginComponent";

const LoginContainer = (props) => {
    const history = useNavigate();

    const appContext = useContext(AppContext);
    const { setUserName, setAppAccountInfo, setDirectNumber } =
        appContext || {};

    const {
        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const [loginResponse, setLoginResponse] = useState();
    const [loginResponseCls, setLoginResponseCls] = useState({
        container: "",
        text: "",
    });
    const [loading, setLoading] = useState(false);

    const [variant, setVariant] = useState("determinate");
    const [progress, setProgress] = useState(0);

    const { loginAction, login } = props;

    const { sendMethod } = useLogin({
        login,
        loginAction,
    });

    const sendLogin = (data) => {
        setLoading(true);

        setVariant("indeterminate");
        sendMethod(data);
    };

    const loginValues = {
        sendLogin,

        loginResponse,
        setLoginResponse,
        loginResponseCls,
        setLoginResponseCls,
        loading,
        setLoading,
        variant,
        setVariant,
        progress,
        setProgress,

        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        errors,
    };

    const setUserNameMethod = () => {
        if (typeof login.login == "undefined") {
            return false;
        }

        const { account_info } = login.login || {};

        if (typeof account_info != "undefined") {
            setAppAccountInfo(setAppAccountInfo);

            const { name, last_name, mothers_last_name, direct_number } =
                account_info || {
                    name: "",
                    last_name: "",
                    mothers_last_name: "",
                    direct_number: undefined,
                };

            setUserName(name + " " + last_name + " " + mothers_last_name);

            if (typeof direct_number != "undefined") {
                setDirectNumber(direct_number);
            }
        }
    };

    useEffect(() => {
        if (typeof login.login == "undefined") {
            return false;
        }
        const { token, expires_at, refresh_token, response } =
            login.login || {};
        if (
            typeof token != "undefined" &&
            typeof expires_at != "undefined" &&
            typeof refresh_token != "undefined"
        ) {
            setUserNameMethod();
            setLoginResponse("¡Bienvenido!");
            setLoginResponseCls({
                container: "success-container",
                text: "success-text",
            });
            setVariant("determinate");
            setLoginDataHelper(login.login, "LoginContainer");
            setInfoDataHelper(login.login, "LoginContainer");
            history("/dashboard");
        } else if (typeof response != "undefined") {
            const { data } = response || {};
            const { detail } =
                data || {};
            setLoginResponse(detail || "");
            setLoginResponseCls({
                container: "error-container",
                text: "error-text",
            });
            setVariant("determinate");
            setLoginDataHelper(
                { token: null, expires_at: null, refresh_token: null },
                "LoginContainer"
            );

            history("/login");
            setLoading(false);
        }
    }, [login]);

    return (
        <LoginContext.Provider value={loginValues}>
            <LoginComponent />
        </LoginContext.Provider>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    login: state.login,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    loginAction,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
