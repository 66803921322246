import {
    GET_PURCHASE_PAYMENT_STATUS,
    GET_PURCHASE_PAYMENT_STATUS_ERROR,
    CLEAN_GET_PURCHASE_PAYMENT_STATUS,
    CLEAN_GET_PURCHASE_PAYMENT_STATUS_ERROR,
} from "_models/redux/types";

const initialState = {
    purchasePaymentStatusObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PURCHASE_PAYMENT_STATUS:
            return {
                ...state,
                purchasePaymentStatusObj: action.payload,
                loading: true,
            };
        case GET_PURCHASE_PAYMENT_STATUS_ERROR:
            return {
                ...state,
                purchasePaymentStatusObj: action.payload,
                loading: false,
            };
        case CLEAN_GET_PURCHASE_PAYMENT_STATUS:
            return {
                ...state,
                purchasePaymentStatusObj: action.payload,
                loading: true,
            };
        case CLEAN_GET_PURCHASE_PAYMENT_STATUS_ERROR:
            return {
                ...state,
                purchasePaymentStatusObj: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}
