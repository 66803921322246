import { MUNICIPALITIES, MUNICIPALITIES_ERROR } from "_models/redux/types";

import axiosAuthInstance from "../../../services/api/authentication";

export const municipalitiesAction = (params) => async (dispatch) => {
    try {
        const response = await axiosAuthInstance.get(
            `/api/sepomex/states/${params}/municipalities`
        );

        dispatch({
            type: MUNICIPALITIES,
            payload: response,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: MUNICIPALITIES_ERROR,
            payload: e || {},
        });
    }
};
