import axiosTokenInstance from "services/api/service-client";

import { MYCARD_ACTION, MYCARD_ACTION_ERROR } from "_models/redux/types";

// Action function
export const postMyCardAction =
    (params = {}) =>
    async (dispatch) => {
        // Endpoint: Try & Catch
        try {
            const response = await axiosTokenInstance.post(
                `/api/clients/actions/cards`,
                params
            );

            const { data, status } = response || {};

            dispatch({
                type: MYCARD_ACTION,
                status: status || {},
                payload: data || {},
                method: "post",
            });
        } catch (e) {
            const { response } = e || {};
            const { data, status } = response || {};

            dispatch({
                type: MYCARD_ACTION_ERROR,
                status: status || {},
                payload: data || {},
            });
        }
    };
