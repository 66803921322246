import { connect } from "react-redux";

import { LeadContext } from "./data/contexts/leadContext";

import { useLeadContainer } from "./leadContainer";

import { getLeadDataAction } from "_models/redux/get-lead-data/action";
import { cleanGetLeadDataAction } from "_models/redux/get-lead-data/clean";

import { postLeadDataAction } from "_models/redux/post-lead-data/action";
import { cleanPostLeadDataAction } from "_models/redux/post-lead-data/clean";

import { postLeadCheckImeiAction } from "_models/redux/post-lead-check-imei/action";
import { cleanPostLeadCheckImeiAction } from "_models/redux/post-lead-check-imei/clean";

import { municipalitiesAction } from "_models/redux/municipalities/action";
import { cleanMunicipalitiesAction } from "_models/redux/municipalities/clean";

import { neighborhoodsAction } from "_models/redux/neighborhoods/action";
import { cleanNeighborhoodsAction } from "_models/redux/neighborhoods/clean";

import { getLeadOffersAction } from "_models/redux/get-lead-offers/action";
import { cleanGetLeadOffersAction } from "_models/redux/get-lead-offers/clean";

import getStaticStates from "modules/purchase/pages/widgets/states";

import LeadPresentational from "./LeadPresentational";

const LeadPage = (props) => {
    const staticStatesOptions = getStaticStates();
    const leadProps = { ...props, staticStatesOptions };

    const { leadValues } = useLeadContainer(leadProps);

    return (
        <LeadContext.Provider value={leadValues}>
            <LeadPresentational />
        </LeadContext.Provider>
    );
};

const mapStateToProps = (state) => ({
    getLeadData: state?.getLeadData,

    postLeadData: state?.postLeadData,
    postLeadCheckImei: state?.postLeadCheckImei,

    municipalitiesData: state?.municipalitiesData,
    neighborhoodsData: state?.neighborhoodsData,

    getLeadOffers: state?.getLeadOffers,
});

const mapDispatchToProps = {
    getLeadDataAction,
    cleanGetLeadDataAction,

    postLeadDataAction,
    cleanPostLeadDataAction,

    postLeadCheckImeiAction,
    cleanPostLeadCheckImeiAction,

    getLeadOffersAction,
    cleanGetLeadOffersAction,

    municipalitiesAction,
    cleanMunicipalitiesAction,

    neighborhoodsAction,
    cleanNeighborhoodsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadPage);
