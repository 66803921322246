import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import {
    Grid,
    Container,
    TextField,
    Checkbox,
    Button,
    CircularProgress,
    SvgIcon,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import PortalNavigation from "modules/portal/components/navigation";
import PortalFooter from "modules/portal/components/footer";

import { accountCardsTokensAction } from "_models/redux/account-cards-tokens/action";

import { cleanMyCardAction } from "_models/redux/my-card/clean-action";
import { deleteMyCardAction } from "_models/redux/my-card/delete-action";
import { postMyCardAction } from "_models/redux/my-card/post-action";
import { getMyCardAction } from "_models/redux/my-card/action";
import { getMyCardsAction } from "_models/redux/my-cards/action";
import { cleanMyCardsAction } from "_models/redux/my-cards/clean-action";

import Card from "assets/images/my-cards/tarjeta.svg";
import GenericCard from "assets/images/my-cards/generica.svg";

import Visa from "assets/images/my-cards/visa.svg";
import Amex from "assets/images/my-cards/amex.svg";
import Mastercard from "assets/images/my-cards/mastercard.svg";

import CheckImage from "assets/images/my-cards/success.svg";
import ErrorImage from "assets/images/my-cards/error.svg";

import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { ReactComponent as AddSvgIcon } from "assets/images/my-cards/agregar.svg";

const MyCardsContent = (props) => {
    const {
        myCard,
        deleteMyCardAction,
        postMyCardAction,
        getMyCardAction,
        getMyCardsAction,
        cleanMyCardsAction,
        cleanMyCardAction,
        accountCardsTokensAction,
        accountCardsToken,
    } = props || {};

    const { accountCardsTokenObj } = accountCardsToken || {};

    const { cards } = accountCardsTokenObj || {};

    const [loading, setLoading] = useState(false);

    const [cardSelectedImg, setCardSelectedImg] = useState();
    const [cardSelectedNetwork, setCardSelectedNetwork] = useState();
    const [cardSelectedNumber, setCardSelectedNumber] = useState();
    const [cardSelectedId, setCardSelectedId] = useState();

    const [selectedItem, setSelectedItem] = useState();
    const [selectedDefault, setSelectedDefault] = useState("");

    const [open, setOpen] = useState(false);
    const [response, setResponse] = useState();

    const [successModalResponse, setSuccessModalResponse] = useState("");
    const [openSuccessModal, setOpenSuccessModal] = useState();

    const [openCustomModal, setOpenCustomModal] = useState(false);
    const [customModalResponse, setCustomModalResponse] = useState();
    const [customModalIcon, setCustomModalIcon] = useState();

    const [isAmex, setIsAmex] = useState(false);

    const registerOptions = {
        firstNameInput: {
            required: "El nombre es requerido",
        },
        lastNameInput: {
            required: "El apellido es requerido",
        },
        cardNumberInput: {
            required: "El número de tarjeta es requerido",
        },
        amexZipCodeInput: {
            required: "El código postal es requerido",
        },
        amexAddressInput: {
            required: "La dirección es requerida",
        },
        amexEmailInput: {
            required: "El correo electrónico es requerido",
        },
        amexPhoneInput: {
            required: "El teléfono es requerido",
        },
        monthExpireInput: {
            required: "El mes de expiración es requerido",
        },
        yearExpireInput: {
            required: "El año de expiración es requerido",
        },
        cvvExpireInput: {
            required: "El cvv es requerido",
        },
    };

    const {
        reset,
        value,
        setFocus,
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        accountCardsTokensAction();

        return () => {
            cleanMyCardsAction();
            cleanMyCardAction();
        };
    }, []);

    const onSubmit = async (e) => {
        setLoading(true);

        if (isAmex) {
            postMyCardAction({
                card_number: e?.cardNumberInput || undefined,
                cvv: e?.cvvExpireInput || undefined,
                exp_month: e?.monthExpireInput || undefined,
                exp_year: e?.yearExpireInput || undefined,
                first_name: e?.firstNameInput || undefined,
                last_name: e?.lastNameInput || undefined,
                is_amex: isAmex || undefined,
                amex: {
                    postalcode: e?.amexZipCodeInput || undefined,
                    address: e?.amexAddressInput || undefined,
                    email_address: e?.amexEmailInput || undefined,
                    phone_number: e?.amexPhoneInput || undefined,
                },
            });
        } else {
            postMyCardAction({
                card_number: e?.cardNumberInput || undefined,
                cvv: e?.cvvExpireInput || undefined,
                exp_month: e?.monthExpireInput || undefined,
                exp_year: e?.yearExpireInput || undefined,
                first_name: e?.firstNameInput || undefined,
                last_name: e?.lastNameInput || undefined,
                is_amex: isAmex || false,
            });
        }
    };

    const AmexSection = () => {
        return (
            <Grid
                container
                className={"data-container amex-section"}
                rowSpacing={2}
                spacing={3}
            >
                <Grid item xs={4}>
                    <label>Código postal</label>
                    <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        placeholder="Código postal"
                        id="amexZipCodeInput"
                        name="amexZipCodeInput"
                        {...register(
                            "amexZipCodeInput",
                            registerOptions?.amexZipCodeInput
                        )}
                        onChange={(e) => inputChange(e)}
                        className="m-tb-20 form-control data-input"
                    />

                    <div>
                        <span>
                            {errors?.amexZipCodeInput && (
                                <p className="required-incidence m-0">
                                    {" "}
                                    {errors?.amexZipCodeInput.message}
                                </p>
                            )}
                        </span>
                    </div>
                </Grid>
                <Grid item xs={8}>
                    <label>Dirección</label>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Dirección"
                        id="amexAddressInput"
                        name="amexAddressInput"
                        {...register(
                            "amexAddressInput",
                            registerOptions?.amexAddressInput
                        )}
                        onChange={(e) => inputChange(e)}
                        className="m-tb-20 form-control data-input"
                    />

                    <div>
                        <span>
                            {errors?.amexAddressInput && (
                                <p className="required-incidence m-0">
                                    {" "}
                                    {errors?.amexAddressInput.message}
                                </p>
                            )}
                        </span>
                    </div>
                </Grid>
                <Grid item xs={7}>
                    <label>Correo electrónico</label>
                    <TextField
                        fullWidth
                        type="email"
                        variant="outlined"
                        placeholder="Correo electrónico"
                        id="amexEmailInput"
                        name="amexEmailInput"
                        {...register(
                            "amexEmailInput",
                            registerOptions?.amexEmailInput
                        )}
                        onChange={(e) => inputChange(e)}
                        className="m-tb-20 form-control data-input"
                    />

                    <div>
                        <span>
                            {errors?.amexEmailInput && (
                                <p className="required-incidence m-0">
                                    {" "}
                                    {errors?.amexEmailInput.message}
                                </p>
                            )}
                        </span>
                    </div>
                </Grid>
                <Grid item xs={5}>
                    <label>Número telefónico</label>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Número telefónico"
                        id="amexPhoneInput"
                        name="amexPhoneInput"
                        {...register(
                            "amexPhoneInput",
                            registerOptions?.amexPhoneInput
                        )}
                        onChange={(e) => inputChange(e)}
                        className="m-tb-20 form-control data-input"
                    />

                    <div>
                        <span>
                            {errors?.amexPhoneInput && (
                                <p className="required-incidence m-0">
                                    {" "}
                                    {errors?.amexPhoneInput.message}
                                </p>
                            )}
                        </span>
                    </div>
                </Grid>
            </Grid>
        );
    };

    const inputChange = (e) => {
        setValue(e?.target?.name, e?.target?.value);

        if (e?.target?.name == "cardNumberInput") {
            setIsAmex(e?.target?.value?.startsWith("3"));
            // setFocus("cardNumberInput");
        }
    };

    useEffect(() => {
        setFocus("cardNumberInput");
    }, [isAmex]);

    const AddForm = () => {
        return (
            <Container className={"my-cards-form-container"}>
                <h1>Agrega una tarjeta de crédito o débito</h1>
                <form
                    className={"my-cards-form"}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Grid container className={"data-container"} spacing={3}>
                        <Grid item xs={6} className={"data-item"}>
                            <label>Nombre</label>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Nombre"
                                id="firstNameInput"
                                name="firstNameInput"
                                {...register(
                                    "firstNameInput",
                                    registerOptions?.firstNameInput
                                )}
                                onChange={(e) => inputChange(e)}
                                className="m-tb-20 form-control data-input"
                            />

                            <div>
                                <span>
                                    {errors?.firstNameInput && (
                                        <p className="required-incidence m-0">
                                            {" "}
                                            {errors?.firstNameInput.message}
                                        </p>
                                    )}
                                </span>
                            </div>
                        </Grid>

                        <Grid item xs={6} className={"data-item"}>
                            <label>Apellido</label>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Apellido"
                                id="lastNameInput"
                                name="lastNameInput"
                                {...register(
                                    "lastNameInput",
                                    registerOptions?.lastNameInput
                                )}
                                onChange={(e) => inputChange(e)}
                                className="m-tb-20 form-control data-input"
                            />

                            <div>
                                <span>
                                    {errors?.lastNameInput && (
                                        <p className="required-incidence m-0">
                                            {" "}
                                            {errors?.lastNameInput.message}
                                        </p>
                                    )}
                                </span>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container className={"data-container"}>
                        <Grid item xs={12} className={"data-item"}>
                            <label>Número de la tarjeta</label>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Número que aparece en la tarjeta"
                                id="cardNumberInput"
                                name="cardNumberInput"
                                {...register(
                                    "cardNumberInput",
                                    registerOptions?.cardNumberInput
                                )}
                                onChange={(e) => inputChange(e)}
                                className="m-tb-20 form-control data-input"
                            />
                        </Grid>

                        <div>
                            <span>
                                {errors?.cardNumberInput && (
                                    <p className="required-incidence m-0">
                                        {" "}
                                        {errors?.cardNumberInput.message}
                                    </p>
                                )}
                            </span>
                        </div>
                    </Grid>

                    {isAmex ? <AmexSection /> : null}

                    <Grid container className={"data-container"} spacing={3}>
                        <Grid item xs={4} className={"data-item"}>
                            <label>Mes de expiración</label>
                            <TextField
                                fullWidth
                                type="number"
                                variant="outlined"
                                placeholder="MM"
                                id="monthExpireInput"
                                name="monthExpireInput"
                                {...register(
                                    "monthExpireInput",
                                    registerOptions?.monthExpireInput
                                )}
                                onChange={(e) => inputChange(e)}
                                className="m-tb-20 form-control data-input"
                            />

                            <div>
                                <span>
                                    {errors?.monthExpireInput && (
                                        <p className="required-incidence m-0">
                                            {" "}
                                            {errors?.monthExpireInput.message}
                                        </p>
                                    )}
                                </span>
                            </div>
                        </Grid>

                        <Grid item xs={4} className={"data-item"}>
                            <label>Año de expiración</label>
                            <TextField
                                fullWidth
                                type="number"
                                variant="outlined"
                                placeholder="AA"
                                id="yearExpireInput"
                                name="yearExpireInput"
                                {...register(
                                    "yearExpireInput",
                                    registerOptions?.yearExpireInput
                                )}
                                onChange={(e) => inputChange(e)}
                                className="m-tb-20 form-control data-input"
                            />

                            <div>
                                <span>
                                    {errors?.yearExpireInput && (
                                        <p className="required-incidence m-0">
                                            {" "}
                                            {errors?.yearExpireInput.message}
                                        </p>
                                    )}
                                </span>
                            </div>
                        </Grid>

                        <Grid item xs={4} className={"data-item"}>
                            <label>
                                CVV <br />
                            </label>
                            <TextField
                                fullWidth
                                type="number"
                                variant="outlined"
                                placeholder="CVV"
                                id="cvvExpireInput"
                                name="cvvExpireInput"
                                {...register(
                                    "cvvExpireInput",
                                    registerOptions?.cvvExpireInput
                                )}
                                onChange={(e) => inputChange(e)}
                                className="m-tb-20 form-control data-input"
                            />

                            <div>
                                <span>
                                    {errors?.cvvExpireInput && (
                                        <p className="required-incidence m-0">
                                            {" "}
                                            {errors?.cvvExpireInput.message}
                                        </p>
                                    )}
                                </span>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container className={"data-container"}>
                        <Grid
                            item
                            xs={12}
                            className={"data-item centered my-card-add-button"}
                        >
                            <LoadingButton
                                className={"button"}
                                color="primary"
                                variant="contained"
                                type="submit"
                                loading={loading}
                                loadingPosition="end"
                            >
                                Guardar tarjeta
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        );
    };

    const selectItem = (index, item) => {
        setSelectedItem(index);
        setSelectedDefault("");

        const { id, masked_pan, issuing_network_name } = item;

        setCardSelectedImg(issuing_network_name);
        setCardSelectedNetwork(issuing_network_name);
        setCardSelectedNumber(masked_pan?.split("*****")[1] || "---");
        setCardSelectedId(id);
    };

    const selectDefaultItem = () => {
        if (selectedDefault == "") {
            setSelectedDefault("selected");
            setSelectedItem(undefined);
        }
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        // border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseSuccessModal = () => {
        setOpenSuccessModal(false);
        accountCardsTokensAction();

        selectDefaultItem();
    };

    const handleCloseCustomModal = () => {
        setOpenCustomModal(false);
    };

    const CustomModal = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Modal
                        open={openCustomModal}
                        onClose={handleCloseCustomModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className={"custom-modal my-cards-modal"}
                    >
                        <Box sx={style}>
                            <Grid
                                container
                                className={"my-cards-modal-content centered"}
                            >
                                <Grid item xs={12} className={"icon-centered"}>
                                    {customModalIcon}
                                </Grid>
                                <Typography
                                    id="modal-modal-description"
                                    variant="h6"
                                    component="h2"
                                >
                                    <span className={"modal-modal-text"}>
                                        {customModalResponse}
                                    </span>
                                </Typography>
                            </Grid>
                        </Box>
                    </Modal>
                </Grid>
            </Grid>
        );
    };

    const SuccessModal = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Modal
                        open={openSuccessModal}
                        onClose={handleCloseSuccessModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className={"custom-modal my-cards-modal"}
                    >
                        <Box sx={style}>
                            <Grid
                                container
                                className={"my-cards-modal-content centered"}
                            >
                                <Grid item xs={12} className={"icon-centered"}>
                                    <img src={CheckImage} />
                                </Grid>
                                <Typography
                                    id="modal-modal-description"
                                    variant="h6"
                                    component="h2"
                                >
                                    <span className={"modal-modal-text"}>
                                        {successModalResponse}
                                    </span>
                                </Typography>
                            </Grid>
                        </Box>
                    </Modal>
                </Grid>
            </Grid>
        );
    };

    const DeleteModal = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className={"custom-modal my-cards-modal"}
                    >
                        <Box sx={style}>
                            <Grid
                                container
                                className={"my-cards-modal-content"}
                            >
                                <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                >
                                    <span className={"modal-modal-text"}>
                                        Eliminar tarjeta
                                    </span>
                                </Typography>
                                <Typography
                                    id="modal-modal-description"
                                    variant="h6"
                                    component="h2"
                                >
                                    <span className={"modal-modal-text"}>
                                        La tarjeta se eliminará de tus tarjetas
                                        guardadas
                                    </span>
                                </Typography>
                                <Grid
                                    container
                                    className={"modal-modal-buttons"}
                                    sx={{ mt: 2 }}
                                >
                                    <Button
                                        onClick={handleClose}
                                        className={
                                            "modal-modal-button modal-button-back"
                                        }
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        onClick={deleteCard}
                                        className={
                                            "modal-modal-button modal-button-delete"
                                        }
                                    >
                                        Eliminar
                                    </Button>
                                </Grid>
                                <Typography
                                    id="modal-modal-response"
                                    sx={{ mt: 2 }}
                                >
                                    {response}
                                </Typography>
                            </Grid>
                        </Box>
                    </Modal>
                </Grid>
            </Grid>
        );
    };

    const CardItems = () => {
        return (
            <>
                {cards?.map((card, index) => (
                    <Grid
                        key={index}
                        container
                        className={
                            selectedItem == index
                                ? "my-cards-item selected item-" + index
                                : "my-cards-item item-" + index
                        }
                        onClick={() => selectItem(index, card)}
                    >
                        <Grid
                            item
                            xs={2}
                            className={"my-cards-item-image first"}
                        >
                            {card?.issuing_network_name?.toLowerCase() ==
                            "visa" ? (
                                <img src={Visa} />
                            ) : card?.issuing_network_name?.toLowerCase() ==
                              "mastercard" ? (
                                <img src={Mastercard} />
                            ) : card?.issuing_network_name?.toLowerCase() ==
                              "amex" ? (
                                <img src={Amex} />
                            ) : (
                                <img src={GenericCard} />
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            className={"my-cards-item-text second"}
                        >
                            {card?.issuing_network_name || "---"}
                        </Grid>

                        <Grid
                            item
                            xs={4}
                            className={"my-cards-item-text third"}
                        >
                            {card?.masked_pan?.split("*****")[1] || "---"}
                        </Grid>
                    </Grid>
                ))}
            </>
        );
    };

    const CardsList = () => {
        return (
            <Grid container className={"my-cards-wrapper"}>
                <Grid item xs={12} md={5} className={"my-cards-content"}>
                    <Grid item xs={12} className={"my-cards-items"}>
                        <CardItems />
                        <Grid
                            container
                            className={
                                "my-cards-item item-default " + selectedDefault
                            }
                            onClick={() => selectDefaultItem()}
                        >
                            <Grid
                                item
                                xs={2}
                                className={"my-cards-item-image first add-icon"}
                            >
                                <AddCircleOutlineIcon />
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid
                                item
                                xs={9}
                                className={"my-cards-item-text second"}
                            >
                                Agregar nueva tarjeta
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={7}
                    className={"my-cards-content cards-right-container"}
                >
                    {selectedItem == undefined ? <AddForm /> : <CardSelected />}
                </Grid>
            </Grid>
        );
    };

    useEffect(() => {
        const { payload, loaded, state, status, method } = myCard || {};
        const { message, context, detail, title, type, additional_details } =
            payload || {};

        if (message == "success") {
            cleanMyCardAction();

            setOpen(false);
            setOpenSuccessModal(true);

            let actionMessage = "eliminado";
            if (method == "post") {
                actionMessage = "agregado";
                reset();
            }

            setSuccessModalResponse(
                <span>
                    La tarjeta se ha <b>{actionMessage}</b> con éxito
                </span>
            );
        } else if (status >= 400 && status < 500) {
            cleanMyCardAction();

            setOpen(false);
            setOpenCustomModal(true);

            setCustomModalResponse(
                <Grid
                    container
                    className={"error-custom-modal-message-container"}
                >
                    <p className={"error-custom-modal-message-title"}>
                        {title}
                    </p>
                    <br />
                    <p className={"error-custom-modal-message-detail"}>
                        {detail}
                    </p>
                </Grid>
            );
            setCustomModalIcon(<img src={ErrorImage} />);
        }
        setLoading(false);
    }, [myCard]);

    const deleteCard = () => {
        // getMyCardAction({ card_id: cardSelectedId });
        deleteMyCardAction({ card_id: cardSelectedId });
    };

    const openDeleteCard = () => {
        setOpen(true);
    };

    const CardSelected = () => {
        return (
            <Grid item xs={8} className={"selected-card-wrapper"}>
                <Grid
                    container
                    className={"my-cards-content selected-card-container"}
                >
                    <Grid container className={"selected-card-content"}>
                        <Grid>
                            {cardSelectedImg?.toLowerCase() == "visa" ? (
                                <img src={Visa} />
                            ) : cardSelectedImg?.toLowerCase() ==
                              "mastercard" ? (
                                <img src={Mastercard} />
                            ) : cardSelectedImg?.toLowerCase() == "amex" ? (
                                <img src={Amex} />
                            ) : (
                                <img src={GenericCard} />
                            )}
                        </Grid>

                        <Grid className="flex-horizontal-start">
                            <Button onClick={openDeleteCard}>
                                <DeleteIcon /> Eliminar
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container className={"selected-card-content"}>
                        <Grid className={"selected-card-text first"}>
                            {cardSelectedNetwork}
                        </Grid>

                        <Grid className={"selected-card-text last"}>
                            {cardSelectedNumber}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <div>
            <PortalNavigation />

            <Container maxWidth="lg" className="container-min-height-500">
                <Grid container className={"my-cards-container"}>
                    <Grid container className={"my-cards-header"}>
                        <Grid item xs={12} className={"my-cards-title"}>
                            <h3>Mis tarjetas</h3>
                        </Grid>

                        {cards?.length < 1 ? (
                            <Grid item xs={12} className={"my-cards-content"}>
                                <AddForm />
                            </Grid>
                        ) : (
                            <CardsList />
                        )}
                    </Grid>
                </Grid>
            </Container>

            <PortalFooter />
            <SuccessModal />
            <CustomModal />
            <DeleteModal />
        </div>
    );
};

/** Redux map state to props **/
const mapStateToProps = (state) => ({
    myCard: state?.myCard,
    portability: state?.portability,
    accountCardsToken: state.accountCardsToken,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    cleanMyCardAction,
    deleteMyCardAction,
    postMyCardAction,
    getMyCardAction,
    getMyCardsAction,
    cleanMyCardsAction,
    accountCardsTokensAction,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(MyCardsContent);
