import ServiceClass from "./ServiceClass";

import backofficeAxios from "./api/service-client";

class MiddlewareAction extends ServiceClass {
    constructor(props) {
        super(props);
    }

    async requestService(refreshToken = true, from = "default") {
        if (typeof this.props === "undefined") {
            return false;
        }

        this.setApiInitialization();

        try {
            switch (this.props.request || "none") {
                case "get":
                    this.response = await backofficeAxios.get(
                        this.apiInitialize.url,
                        this.apiInitialize.auth
                    );
                    break;
                case "post":
                    this.response = await backofficeAxios.post(
                        this.apiInitialize.url,
                        this.apiInitialize.data,
                        this.apiInitialize.auth
                    );
                    break;
                case "put":
                    this.response = await backofficeAxios.put(
                        this.apiInitialize.url,
                        this.apiInitialize.data,
                        this.apiInitialize.auth
                    );
                    break;

                case "patch":
                    this.response = await backofficeAxios.patch(
                        this.apiInitialize.url,
                        this.apiInitialize.data,
                        this.apiInitialize.auth
                    );
                    break;
                default:
                    break;
            }
            this.setTry();
        } catch (e) {
            this.response = this.errorResponse(e);

            this.setCatch();
        }
        this.setApiResponse();
    }

    errorResponse(e = undefined) {
        const { name } = typeof e.name !== "undefined" ? e : { name: false };
        const { response } =
            typeof e.response !== "undefined"
                ? e
                : {
                      response: {
                          data: {
                              additional_details: {},
                              context: "error",
                              title: "Error",
                              detail: "",
                              type: "",
                          },
                      },
                  };
        const { message } =
            typeof e.message !== "undefined" ? e : { message: "error" };
        return {
            data: {
                error: e || {},
                response: response || {},
                status: name || "code-error",
                message: message || "Code Error",
            },
        };
    }
}

export default MiddlewareAction;
