import React, { useContext, useEffect, useState } from "react";

import { Grid, Avatar } from "@mui/material";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { connect } from "react-redux";

import { TicketContext } from "modules/support/tickets/context";
import { getTickets } from "_models/redux/tickets/action";

const TicketsDatagrid = (props) => {
    const ticketContext = useContext(TicketContext);
    const { sendMyMethod } = ticketContext || {};

    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        generateColumns();
        props.getTickets();
    }, []);
    useEffect(() => {
        generateRows();
    }, [props.tickets]);

    const generateColumns = () => {
        setColumns([
            { field: "id", headerName: "ID", width: 90, hide: true },
            {
                field: "title",
                headerName: " Título ",
                width: 400,
                editable: true,
            },
            {
                field: "ticket",
                headerName: " Ticket ",
                width: 150,
                editable: true,
            },
            {
                field: "phone",
                headerName: " Teléfono ",
                width: 150,
                editable: true,
            },
            {
                field: "from",
                headerName: "Última actividad",
                width: 150,
                editable: true,
            },
            {
                field: "since",
                headerName: "Creado",
                width: 150,
                editable: true,
            },
            {
                field: "status",
                headerName: "Status",
                className: "status",
                width: 110,
                editable: true,
            },
            {
                field: "avatar",
                headerName: "Avatar",
                // width: 110,
                editable: true,
            },
        ]);
    };

    const generateRows = () => {
        const { tickets } = props.tickets || { tickets: undefined };

        const rows = tickets.tickets;

        let elements = [];
        if (typeof rows != "undefined" && Array.isArray(rows)) {
            rows.forEach((element) => {
                let ele = [];
                ele["id"] = element.id || 0;
                ele["title"] = element.title || "title ok";
                ele["phone"] = element.phone || "";
                ele["status"] = element.status_label || "";
                ele["ticket"] = element.assigned_id || 0;
                ele["from"] = element.lastopened || 0;
                ele["since"] = element.created || 0;
                ele["avatar"] = element.reported_by_name || "";
                elements.push(ele);
            });
        }
        elements = [
            {
                id: 1,
                title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                phone: "777 465 34 23",
                status: true,
                ticket: 24234,
                from: "septiembre",
                since: "agosto",
                avatar: "image.jpg",
            },
            {
                id: 2,
                title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                phone: "777 465 34 23",
                status: true,
                ticket: 13123,
                from: "septiembre",
                since: "agosto",
                avatar: "image.jpg",
            },
            {
                id: 3,
                title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                phone: "777 465 34 23",
                status: true,
                ticket: 46345,
                from: "septiembre",
                since: "agosto",
                avatar: "image.jpg",
            },
            {
                id: 4,
                title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                phone: "777 465 34 23",
                status: true,
                ticket: 89345,
                from: "septiembre",
                since: "agosto",
                avatar: "image.jpg",
            },
        ];

        setRows(elements);
    };

    return (
        // <Grid container>
        //     <Grid item xs={12} className={"support-tickets-user-datagrid"}>
        //         <DataGrid
        //             rows={rows}
        //             columns={columns}
        //             pageSize={5}
        //             rowsPerPageOptions={[5]}
        //             hideFooter={true}
        //             disableSelectionOnClick
        //         />
        //     </Grid>
        //     </Grid>

        <Grid container spacing={2} className={"list-box"}>
            <Grid container className="row">
                <Grid container spacing={2} xs={6} className={"section-one"}>
                    <Grid item xs={12}>
                        <h4>Fuera de cobertura</h4>
                        <p>
                            <ConfirmationNumberIcon className="svg" />
                            <span>ticket</span>
                            <LocalPhoneIcon className="svg" />
                            777 884 15 20
                        </p>
                    </Grid>
                </Grid>
                <Grid container spacing={2} xs={6} className={"section-two"}>
                    <Grid item xs={5}>
                        <Grid item className="dropdown-status">
                            Status
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Avatar className={"avatar"}>TI</Avatar>
                    </Grid>
                    <Grid item xs={5} className={"dates"}>
                        <p>
                            Última actualización: <span>19/09/2022</span>
                        </p>
                        <p>
                            Creado: <span>05/08/2022</span>
                        </p>
                    </Grid>
                </Grid>
            </Grid>
            <divider className="divider"></divider>
            <Grid container className="row">
                <Grid container spacing={2} xs={6} className={"section-one"}>
                    <Grid item xs={12}>
                        <h4>Fuera de cobertura</h4>
                        <p>
                            <ConfirmationNumberIcon className="svg" />
                            <span>ticket</span>
                            <LocalPhoneIcon className="svg" />
                            777 884 15 20
                        </p>
                    </Grid>
                </Grid>
                <Grid container spacing={2} xs={6} className={"section-two"}>
                    <Grid item xs={5}>
                        <Grid item className="dropdown-status">
                            Status
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Avatar className={"avatar"}>TI</Avatar>
                    </Grid>
                    <Grid item xs={5} className={"dates"}>
                        <p>
                            Última actualización: <span>19/09/2022</span>
                        </p>
                        <p>
                            Creado: <span>05/08/2022</span>
                        </p>
                    </Grid>
                </Grid>
            </Grid>
            <divider className="divider"></divider>
            <Grid container className="row last">
                <Grid container spacing={2} xs={6} className={"section-one"}>
                    <Grid item xs={12}>
                        <h4>Fuera de cobertura</h4>
                        <p>
                            <ConfirmationNumberIcon className="svg" />
                            <span>ticket</span>
                            <LocalPhoneIcon className="svg" />
                            777 884 15 20
                        </p>
                    </Grid>
                </Grid>
                <Grid container spacing={2} xs={6} className={"section-two"}>
                    <Grid item xs={5}>
                        <Grid item className="dropdown-status">
                            Status
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Avatar className={"avatar"}>TI</Avatar>
                    </Grid>
                    <Grid item xs={5} className={"dates"}>
                        <p>
                            Última actualización: <span>19/09/2022</span>
                        </p>
                        <p>
                            Creado: <span>05/08/2022</span>
                        </p>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({ tickets: state.tickets });

const mapDispatchToProps = { getTickets };

export default connect(mapStateToProps, mapDispatchToProps)(TicketsDatagrid);
