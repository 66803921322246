import { useEffect } from "react";

export const useProductsCardsHook = (props) => {
    const {
        products,
        classicSorted,
        setClassicSorted,
        plusSorted,
        setPlusSorted,
        shareSorted,
        setShareSorted,
        notShareSorted,
        setNotShareSorted,
    } = props || {};

    useEffect(() => {
        products?.forEach((element, i) => {
            const sumGigaBytes =
                element.mobile_data +
                element.mobile_data_roaming +
                element.mobile_data_throttling;
            const gigaBytes = sumGigaBytes / element.gigabyte_divisor;

            /*** NO Planes - CLÁSICOS ***/
            if (
                element.is_monthly &&
                gigaBytes >= 40 &&
                element.hotspot === false
            ) {
                if (
                    !classicSorted.find(
                        (ele) => ele.offer_id === element.offer_id
                    )
                ) {
                    setClassicSorted((classicSortedArray) => [
                        ...classicSortedArray,
                        element,
                    ]);
                }
            }

            /*** NO Planes - PLUS ***/

            if (element.is_monthly && gigaBytes >= 40 && element.hotspot) {
                if (
                    !plusSorted.find((ele) => ele.offer_id === element.offer_id)
                ) {
                    setPlusSorted((plusSortedArray) => [
                        ...plusSortedArray,
                        element,
                    ]);
                }
            }

            if (!element.is_monthly && element.hotspot === true) {
                if (
                    !shareSorted.find(
                        (ele) => ele.offer_id === element.offer_id
                    )
                ) {
                    setShareSorted((shareSorted) => [...shareSorted, element]);
                }
            }

            if (!element.is_monthly && element.hotspot === false) {
                if (
                    !notShareSorted.find(
                        (ele) => ele.offer_id === element.offer_id
                    )
                ) {
                    setNotShareSorted((notShareSorted) => [
                        ...notShareSorted,
                        element,
                    ]);
                }
            }
        });
    }, [products]);

    return {};
};
