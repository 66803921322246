import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import Store from "./_models/redux/store";

import App from "App";

import "assets/css/styles.css";

//Components:
import ThemeMui from "./utils/components/theme-mui/ThemeMui";

//CSS:
import "./assets/styles/css/index.css";

//Sentry:
import * as Sentry from "@sentry/react";

//Google Tag Manager:
import TagManager from "react-gtm-module";

const appEnv = process.env.REACT_APP_ENV;
let googleTagManagerENV = process.env.REACT_APP_GOOGLE_TAG_MANAGER;

if (appEnv !== "TEST") {
    const tagManagerArgs = {
        gtmId: googleTagManagerENV,
    };

    TagManager.initialize(tagManagerArgs);
}

if (appEnv === "PROD") {
    Sentry.init({
        dsn: "https://b0d6378a224504dd99fb4d5306e362db@o4505885589962752.ingest.sentry.io/4506814345248768",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            "localhost",
            /^https:\/\/yourserver\.io\/api/,
        ],
        // Session Replay
        replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // NOTE: Prevent double render
    // <React.StrictMode>
    <Provider store={Store}>
        <ThemeMui>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ThemeMui>
    </Provider>
    // </React.StrictMode>
);
