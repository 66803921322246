import React from "react";
import RouterContainer from "../container";

const AllRoutes = ({ children }) => {
    return (
        <RouterContainer
            loggedChildren={children}
            unloggedChildren={children}
        />
    );
};

export default AllRoutes;
