import React, { useState, useEffect } from "react";

//CSS:
import "../../../../../../assets/styles/css/portal/pages/home/sections/header/home-header.css";

//Material UI:
import { Grid, Container, Typography, Box } from "@mui/material";

//Redux:
import { connect } from "react-redux";

//Images:
import headerImage from "assets/images/home/header-image.png";

//React Router Dom:
import { useNavigate } from "react-router-dom";

//Components:
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

//Styles MUI:
import {
    boxBr,
    containerHomeHeader,
    gridContainerHomeHeader,
    gridItemContainerImage,
    spanPackagesActive,
} from "./styles-mui/homeHeaderStylesMUI";

const HomeHeaderComponent = (props) => {
    const { activeOffers } = props;
    const history = useNavigate();

    const [itemsNumber, setItemsNumber] = useState("...");

    useEffect(() => {
        const { activeOffersdObj } = activeOffers || {};
        const { active_offers } = activeOffersdObj || {};

        if (typeof active_offers != "undefined") {
            if (Array.isArray(active_offers)) {
                if (active_offers.length !== "undefined") {
                    setItemsNumber(active_offers.length);
                } else {
                    setNoItems();
                }
            } else {
                setNoItems();
            }
        }
    }, [activeOffers]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const setNoItems = () => {
        setItemsNumber(0);
    };

    return (
        <Container
            maxWidth={false}
            className={"container-home-header"}
            sx={containerHomeHeader}
        >
            <Container>
                <Grid container sx={gridContainerHomeHeader}>
                    <Grid item xs={11} md={5}>
                        <Typography variant="h1" sx={{ my: { xs: 3, md: 5 } }}>
                            Hola, {localStorage.getItem("user_name")}
                        </Typography>

                        <Typography sx={{ mb: 5, fontSize: 28 }}>
                            Cuentas con
                            <Box sx={boxBr}>
                                <br></br>
                            </Box>{" "}
                            <Typography variant="span" sx={spanPackagesActive}>
                                {itemsNumber}{" "}
                                {itemsNumber === 1 ? "paquete" : "paquetes"}
                            </Typography>{" "}
                            {itemsNumber === 1 ? "activo" : "activos"}
                        </Typography>

                        <CustomButtonComponent
                            text={"Dale más megas a tu fon"}
                            bgColor={"pink"}
                            height={60}
                            onClick={() => history("/paquetes")}
                        />
                    </Grid>
                    <Grid item md={6} sx={gridItemContainerImage}>
                        <img
                            src={headerImage}
                            className={"header-image"}
                            alt="Conversación de emojis con un celular de fondo y el logo de Dalefon."
                        />
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
};

/** Redux map state to props **/
const mapStateToProps = (state) => ({
    activeOffers: state.activeOffers,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeHeaderComponent);
