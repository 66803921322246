import {
    PURCHASEREQUEST_CLEAN,
    PURCHASEREQUEST_ERROR_CLEAN,
} from "_models/redux/types";

export const cleanPurchaseRequestAction = () => async (dispatch) => {
    try {
        dispatch({
            type: PURCHASEREQUEST_CLEAN,
            payload: [],
        });
    } catch (e) {
        dispatch({
            type: PURCHASEREQUEST_ERROR_CLEAN,
            payload: [],
        });
    }
};
