import { useState, useEffect, useContext } from "react";

import { Grid } from "@mui/material";
import GiftFormComponent from "./gift-form-component";
import GiftFormRecaptchaComponent from "./gift-form-recaptcha-component";
import giftImage from "assets/images/landings/gift/bun.png";
import Logo from "assets/images/logo.png";

const GiftComponent = (props) => {
    return (
        <Grid container className={"gift-wrapper"}>
            <Grid item xs={12} className="gift-content">
                <Grid item xs={12} md={7} className="gift-header">
                    <img src={giftImage} className={"gift-img"} />
                </Grid>
                <Grid
                    container
                    spacing={{ xs: 1, lg: 5 }}
                    display={"flex"}
                    alignItems={"flex-end"}
                >
                    <Grid item xs={12} lg={7} className="gift-text">
                        <img src={Logo} className={"logo"} />
                        <h1>Envía tu eSIM de regalo.</h1>
                        <p>
                            Solo completa los datos de esa persona especial y
                            ¡listo!
                        </p>

                        <p>
                            Recuérdale que tiene <span>30 días</span> {""}para
                            activar su <span>eSIM</span> {""}una vez que la
                            reciba por email.
                        </p>
                    </Grid>
                    <Grid item xs={12} lg={5} className={"gift-form"} mt={5}>
                        <GiftFormComponent />
                        <GiftFormRecaptchaComponent />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default GiftComponent;
