import { useEffect } from "react";

import { Grid } from "@mui/material";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const InvoiceNotFoundComponent = (props) => {
    useEffect(() => {
        return () => {};
    }, []);

    return (
        <Grid container className="invoice-not-found-container">
            <Grid item xs={12}>
                <h1 className="invoice-not-found-legend">
                    <WarningAmberIcon /> No se encontró la página
                </h1>
            </Grid>
        </Grid>
    );
};

export default InvoiceNotFoundComponent;
