import React, { useState } from "react";
import { Grid, CircularProgress } from "@mui/material";
import { Message, WhatsApp, Email } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";

const CodeOptionsView = ({ handleButtonClick }) => {
    const [localLoadingButton, setLocalLoadingButton] = useState(null);

    const handleButtonClickWithAction = async (type) => {
        setLocalLoadingButton(type);
        await handleButtonClick(type);

        setLocalLoadingButton(null);
    };

    return (
        <Grid container spacing={2}>
            {/* Buttons with pink background */}
            <Grid item xs={12}>
                <LoadingButton
                    variant="contained"
                    color="secondary"
                    loading={localLoadingButton === "sms"}
                    onClick={() => handleButtonClickWithAction("sms")}
                    startIcon={
                        localLoadingButton === "sms" ? (
                            <CircularProgress
                                size={20}
                                style={{ color: "#3D3440" }}
                            />
                        ) : (
                            <Message />
                        )
                    }
                    sx={{
                        width: "100%",
                        height: "60px",
                        background:
                            "transparent linear-gradient(180deg, #FAF5FF 0%, #F0ECFF 100%) 0% 0% no-repeat padding-box",
                        color: "#3D3440",
                    }}
                >
                    SMS
                </LoadingButton>
            </Grid>

            <Grid item xs={12}>
                <LoadingButton
                    variant="contained"
                    color="secondary"
                    loading={localLoadingButton === "whatsapp"}
                    onClick={() => handleButtonClickWithAction("whatsapp")}
                    startIcon={
                        localLoadingButton === "whatsapp" ? (
                            <CircularProgress
                                size={20}
                                style={{ color: "#3D3440" }}
                            />
                        ) : (
                            <WhatsApp />
                        )
                    }
                    sx={{
                        width: "100%",
                        height: "60px",
                        background:
                            "transparent linear-gradient(180deg, #FAF5FF 0%, #F0ECFF 100%) 0% 0% no-repeat padding-box",
                        color: "#3D3440",
                    }}
                >
                    WhatsApp
                </LoadingButton>
            </Grid>

            <Grid item xs={12}>
                <LoadingButton
                    variant="contained"
                    color="secondary"
                    // loading={localLoadingButton === "email"}
                    onClick={() => handleButtonClickWithAction("email")}
                    startIcon={
                        localLoadingButton === "email" ? (
                            <CircularProgress
                                size={20}
                                style={{ color: "#3D3440" }}
                            />
                        ) : (
                            <Email />
                        )
                    }
                    sx={{
                        width: "100%",
                        height: "60px",
                        background:
                            "transparent linear-gradient(180deg, #FAF5FF 0%, #F0ECFF 100%) 0% 0% no-repeat padding-box",
                        color: "#3D3440",
                    }}
                >
                    Correo electrónico
                </LoadingButton>
            </Grid>
        </Grid>
    );
};

export default CodeOptionsView;
