import React from "react"; 

import { Button, Grid, SvgIcon } from "@mui/material";

import FormFooterComponent from "widgets/form-footer/FormFooterComponent"; 


import { ReactComponent as ActivationErrorIcon } from "assets/icons/login/error.svg";


const ErrorActivationPage = () => {
    return (
        <Grid container className={"main-page"}>
            <Grid item className={"main-component-container"}>
                <Grid container className={"main-component centered-component"}>
                    <Grid item className={"main-form"}>                        
                        <Grid item xs={12} className="activation-form error">
                            <form
                            >
                                <Grid
                                    item
                                    xs={12}
                                    className="centered"
                                    >                                                                               
                                        <SvgIcon
                                            className={
                                                "main-icon error-icon red"
                                            }
                                            component={ActivationErrorIcon}
                                            viewBox="0 0 60 60"
                                        />
                                        <h3 className="red">Algo salió mal</h3>
                                        <p>Hubo un error al procesar la activación</p>
                                </Grid>

                                <Grid
                                        container
                                        className={
                                            "purchase-error-button-container"
                                        }
                                    >
                                        <Button
                                            variant="text"
                                            className={"purchase-error-button"} 
                                        >
                                            Reintentar
                                        </Button>
                                    </Grid>
                            </form>
                        </Grid>
                    </Grid>
                    <FormFooterComponent />
                </Grid> 
            </Grid>
        </Grid>
    );
};

export default ErrorActivationPage;
