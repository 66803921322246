import React from "react";

function useCardPrepaidHook(element) {
    const totalGB =
        (element?.mobile_data +
            element?.mobile_data_roaming +
            element?.mobile_data_throttling) /
        element?.gigabyte_divisor;

    const totalGBSocialMedia =
        (element?.social_network_data +
            element?.social_network_data_roaming +
            element?.social_network_data_throttling) /
        element?.social_gigabyte_divisor;

    function messageFreeSIMAndShipping() {
        if (element?.freeSim === false && element?.freeShipping === false) {
            return "No incluye eSIM/SIM ni envío";
        }
        if (element?.freeSim === false) {
            return "No incluye eSIM/SIM";
        }
        if (element?.freeShipping === false) {
            return "No incluye envío";
        }
    }

    return {
        totalGB,
        totalGBSocialMedia,
        messageFreeSIMAndShipping,
    };
}

export default useCardPrepaidHook;
