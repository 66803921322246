export const boxContainerNewDataInvoice = {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #E1E1E1",
    borderRadius: "10px",
    p: { xs: 2, md: 3 },
    pb: { xs: 3, md: 4 },
    textAlign: "left",
};

export const typographyTitleCard = {
    fontSize: { xs: 16, md: 20 },
    fontFamily: "Mulish-Bold",
};

export const typographyItem = {
    mt: { xs: 2, md: 4 },
    fontSize: 13,
    color: "#3D3D3D",
    mb: 1.5,
    fontWeight: "bold",
};

export const textFieldItemValue = { mb: 1 };