import {
    PURCHASEREQUEST,
    PURCHASEREQUEST_ERROR,
    PURCHASEREQUEST_CLEAN,
    PURCHASEREQUEST_ERROR_CLEAN,
} from "_models/redux/types";

const initialState = {
    purchaseRequestObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PURCHASEREQUEST:
            return {
                ...state,
                purchaseRequestObj: action.payload,
                loading: true,
            };
        case PURCHASEREQUEST_ERROR:
            return {
                ...state,
                purchaseRequestObj: action.payload,
                loading: false,
            };
        case PURCHASEREQUEST_CLEAN:
        case PURCHASEREQUEST_ERROR_CLEAN:
            return {
                ...state,
                purchaseRequestObj: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
