export const gridItemContainer = (cardItem) => {
    return {
        position:
            cardItem.freeSim === false || cardItem.freeShipping === false
                ? "relative"
                : "static",
        overflow:
            cardItem.freeSim === false || cardItem.freeShipping === false
                ? "hidden"
                : "",
        textAlign: "start",
    };
};

export const boxContainer = {
    textAlign: "center",
    pt: 3,
    pb: 1,
    my: 1,
};

export const typographyBestSelling = {
    textAlign: "center",
    mt: -7,
    mx: { xs: 8, md: 8, lg: 10 },
    py: 1.5,
    px: 2,
    fontWeight: "bold",
    position: "relative",
    zIndex: 1,
};

export const boxContainerMessageFreeSIMAndShipping = {
    position: "absolute",
    top: 60,
    right: 8,
    width: 100,
};

export const typographyPrice = { fontSize: 35, fontWeight: "bold" };

export const typographyGB = {
    fontSize: 80,
    fontWeight: "bold",
    lineHeight: 1.2,
};

export const typographyGBSpan = {
    fontSize: 20,
    top: -40,
    position: "relative",
};

export const typographyPlusGB = {
    fontSize: 25,
    lineHeight: 1.2,
    fontWeight: "500",
};

export const boxContainerImg = {
    textAlign: "right",
    p: 0,
    mr: { xs: -2.8, md: -3 },
    my: -5.5,
};

export const TypographyValidity = {
    fontSize: 15,
    fontWeight: "500",
    mb: 2,
};

export const boxContainerNoShareAndShareWifi = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 1,
    my: 1.5,
};

export const typographyNoShareAndShareWifi = {
    fontSize: 14,
    color: "white",
};

export const boxContainerMinAndSMS = {
    display: "flex",
    justifyContent: "center",
    gap: 1,
    alignItems: "center",
    mb: 4,
    fontSize: 14,
};

export const boxContainerButton = {
    display: "flex",
    justifyContent: "center",
    mb: 2,
};

export const typographyNote = { fontSize: 9, mb: 2, mt: 1, mx: 2 };
