import { INITREGISTER, INITREGISTER_ERROR } from "_models/redux/types";

import axiosAuthInstance from "../../../services/api/authentication";

export const initRegisterAction = (params) => async (dispatch) => {
    try {
        const response = await axiosAuthInstance.post(
            `/api/leads/steps/create-lead`,
            params
        );

        dispatch({
            type: INITREGISTER,
            payload: response,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: INITREGISTER_ERROR,
            payload: e || {},
        });
    }
};
