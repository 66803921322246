import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Grid } from "@mui/material";
// import { useTicketsHook } from "./hook";
import TicketsComponent from "./component";
import { TicketContext } from "./context";

const TicketContainer = (props) => {
    // const { myProperty } = props;

    // const { myMethod } = useTicketsHook({
    //     myProperty,
    // });

    const sendMyMethod = (data) => {
        // myMethod(data);
    };

    // const myValues = {
    //     sendMyMethod,
    // };

    useEffect(() => {
        sendMyMethod({ sample: "new" });
    }, []);

    return (
        <Grid container xs={12}>
            <TicketContext.Provider>
                <TicketsComponent />
            </TicketContext.Provider>
        </Grid>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({});

/** Redux map dispatch to props **/
const mapDispatchToProps = {};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(TicketContainer);
