export const gridContainerMenu = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

export const imageStyles = {
    display: { xs: "none", md: "flex" },
    justifyContent: "center",
    alignItems: "center",
    p: 3,
};

export const containerMenu = {
    mt: 2,
    mb: { xs: 0, md: 6 },
    color: "white",
    p: { xs: 2, md: 3 },
};

export const typographyTitle = {
    fontSize: { xs: 22, md: 45 },
    fontWeight: "600",
    letterSpacing: 1.2,
    textAlign: "center",
    color: "white",
    my: { xs: 1, md: 0 },
};

export const typographyTitleCard = {
    fontSize: { xs: 26, md: 35 },
    fontWeight: "bold",
};

export const typographyDescription = {
    mt: 3,
    fontSize: { xs: 15, md: 18 },
    mb: { xs: 4, md: 6 },
    fontWeight: "100",
};

export const gridItemCard = {
    textAlign: {
        xs: "center",
        md: "start",
    },
    px: { xs: 2, md: 4 },
    py: { xs: 2, md: 4 },
    display: "flex",
    justifyContent: "center",
};
