import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { AppContext } from "_models/context/app";

import { purchaseRequestAction } from "_models/redux/purchase-request/action.js";
import { cleanPurchaseRequestAction } from "_models/redux/purchase-request/clean";

import CardPrepaidComponent from "utils/components/cards/card-prepaid/CardPrepaidComponent";
import CardNoPlansComponent from "utils/components/cards/card-no-plans/CardNoPlansComponent";

const ProductCardsItemPrepaid = (props) => {
    const appContext = useContext(AppContext);
    const { setPaymentObject } = appContext || {};

    const webcheckoutUrl = process.env.REACT_APP_WEBCHECKOUT_URL;

    const { category, element, colorClass, purchaseRequest, recommendCls } =
        props;

    const styleCard =
        colorClass === 0 ? "Lite" : colorClass === 1 ? "Classic" : "Plus";

    const { purchaseRequestObj } = purchaseRequest || [];
    const { data } = purchaseRequestObj || [];
    const { uuid } = data || [];

    let navigate = useNavigate();

    const routeRecarga = () => {
        setPaymentObject(element);
        const prepaid = {
            app_source_id: 4,
            product_sku: props.element?.sku,
        };
        props.purchaseRequestAction({ prepaid });
    };

    useEffect(() => {
        const { response } = purchaseRequestObj || [];
        const { context } = response?.data || {};

        if (uuid !== undefined) {
            const purchaseUrl = webcheckoutUrl + `/paquete?uuid=` + uuid;
            window.location.replace(purchaseUrl);
        } else if (context) {
            navigate("/dashboard");
        }
    }, [purchaseRequest]);

    useEffect(() => {
        return () => {
            props.cleanPurchaseRequestAction();
        };
    }, []);

    return element.is_monthly === false ? (
        <CardPrepaidComponent
            element={element}
            bestSelling={element.name === "Dale 239 Plus" ? true : false}
            routeRecarga={routeRecarga}
        />
    ) : element.is_monthly === true ? (
        <CardNoPlansComponent
            element={element}
            bestSelling={element.name === "Dale 3M Clásico" ? true : false}
            routeRecarga={routeRecarga}
            styleCard={styleCard}
        />
    ) : null;
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    purchaseRequest: state.purchaseRequest,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    purchaseRequestAction,
    cleanPurchaseRequestAction,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(ProductCardsItemPrepaid);
