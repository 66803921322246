import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

/**
 * Componente personalizado con opciones configurables para mostrar cuadros de diálogo.
 *
 * @component
 * @param {string} title - Título del cuadro de diálogo.
 * @param {Component} content - Contenido del cuadro de diálogo.
 * @param {Function} onClose - Función a ejecutar al cerrar el cuadro de diálogo.
 * @param {boolean} open - Indica si el cuadro de diálogo está abierto o cerrado.
 * @param {boolean} [fullWidth] - Indica si el cuadro de diálogo debe ocupar todo el ancho.
 * @param {string|false} [maxWidth] - Ancho máximo del cuadro de diálogo (puede ser 'xs', 'sm', 'md', 'lg', 'xl' o `false`).
 * @param {Array<Object>} [extraButtons] - Botones adicionales a mostrar en el cuadro de diálogo.
 * @param {boolean} [showCloseButton] - Indica si se debe mostrar el botón de cierre.
 * @param {string} [buttonPosition] - Controla la posición del botón de acción ("center", "start", "end").
 *
 * @example
 * // Ejemplo de uso:
 * <CustomDialog
 *     title="Título del cuadro de diálogo"
 *     content={<p>Contenido personalizado</p>}
 *     open={isDialogOpen}
 *     onClose={() => setIsDialogOpen(false)}
 *     fullWidth
 *     maxWidth="sm"
 *     extraButtons={[
 *         {
 *             label: "Aceptar",
 *             variant: "contained",
 *             onClick: handleCustomAction,
 *             buttonColor: "purple", // Color de fondo del botón (opcional)
 *             textColor: "white", // Color de texto del botón (opcional)
 *         },
 *     ]}
 * />
 */
const CustomDialog = ({
    wrapperCallback,
    title,
    content,
    onClose,
    open,
    fullWidth,
    maxWidth,
    extraButtons = [],
    showCloseButton = true,
    buttonPosition = "",
    backgroundColor,
}) => {
    const buttonsToRender = [...extraButtons];

    const buttonStyle = {
        display: "block",
        marginLeft: buttonPosition === "end" ? "auto" : 0,
        marginRight: buttonPosition === "start" ? "auto" : 0,
        textAlign: buttonPosition === "center" ? "center" : "right", // Cambiado a "right"
    };

    const DialogWrapper = () => {
        return (
            <>
                <DialogTitle id="customized-dialog-title">
                    {title}
                    {showCloseButton && (
                        <CloseIcon
                            style={{ cursor: "pointer", float: "right" }}
                            onClick={onClose}
                        />
                    )}
                </DialogTitle>
                <DialogContent>
                    <Typography gutterBottom>{content}</Typography>
                </DialogContent>
                <DialogActions style={buttonStyle}>
                    {buttonsToRender.map((button, index) => (
                        <Button
                            variant={button.variant}
                            key={index}
                            onClick={button.onClick}
                            style={{
                                backgroundColor:
                                    button.buttonColor || "default-color", // Color de fondo configurado o predeterminado
                                color: button.textColor || "default-text-color", // Color de texto configurado o predeterminado
                            }}
                            type={button.type}
                        >
                            {button.label}
                        </Button>
                    ))}
                </DialogActions>
            </>
        );
    };

    return (
        <Dialog
            onClose={onClose}
            open={open}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            PaperProps={{
                style: {
                    background: backgroundColor || "transparent",
                },
            }}
        >
            {wrapperCallback ? (
                wrapperCallback(<DialogWrapper />)
            ) : (
                <DialogWrapper />
            )}
        </Dialog>
    );
};

export default CustomDialog;
