export const gridContainerCodeOptionsToVerifyPin = {
    pb: { xs: 0, md: 2 },
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
}

export const containerCodeOptionsToVerifyPinForm = {
    mt: { xs: 5, md: 2 },
    backgroundColor: "white",
    borderRadius: 10,
    px: { xs: 4, md: 6 },
};

export const boxContainerImage = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
}

export const boxFormTitle = {
    display: "flex",
    direction: "row",
    alignItems: "center",
    mt: 3,
};

export const iconArrowBackIos = { fontSize: 17, color: "#3f3851", mt: 1 };

export const typographyFormTitle = {
    ml: 1,
    color: "#434049",
    fontSize: { xs: 24, md: 28 },
    fontWeight: "bold",
    textAlign: "start"
};

export const formControlLabel = {
    ".MuiFormControlLabel-asterisk": {
        display: "none",
    },
};

export const boxCustomRecaptcha= {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    my: 2,
}