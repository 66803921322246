"use client";
import React from "react";

//CSS:
import "../../../assets/styles/css/fonts.css";

//Material UI:
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
    typography: {
        fontFamily: "Poppins-Regular",
    },
    MuiFormControlLabel: {
        styleOverrides: {
          asterisk: {
            display: 'none',
          },
        },
    },
    palette: {
        primary: {
            main: '#51328B', 
        },
        secondary: {
            main: '#FD45D9',
        },
        tertiary: {
            main: '#2F2B31',
        },
    },
});

const ThemeMui = ({ children }) => {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeMui;
