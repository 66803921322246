import {
    CLEAN_POST_GIFT_FORM,
    CLEAN_POST_GIFT_FORM_ERROR,
} from "_models/redux/types";

export const cleanPostGiftFormAction = () => async (dispatch) => {
    try {
        dispatch({
            type: CLEAN_POST_GIFT_FORM,
            payload: [],
        });
    } catch (e) {
        dispatch({
            type: CLEAN_POST_GIFT_FORM_ERROR,
            payload: [],
        });
    }
};
