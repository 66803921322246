export const gridContainerAddPhone = {
    pb: { xs: 0, md: 2 },
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
}

export const containerImageAddPhone = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
};

export const containerFormAddPhone = {
    my: { xs: 3, md: 2 },
    backgroundColor: "white",
    borderRadius: 10,
    px: { xs: 4, md: 6 },
    width: {xs: "100%", md: 800},
};

export const boxFormTitle = {
    display: "flex",
    direction: "row",
    alignItems: "center",
    textAlign: "start",
    mt: 3,
};

export const iconArrowBackIos = { fontSize: 17, color: "#3f3851", mt: 1 };

export const typographyFormTitle = {
    ml: 1,
    color: "#434049",
    fontSize: { xs: 22, md: 28 },
    fontWeight: "bold",
};