import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid, TextField } from "@mui/material";
import { setPasswordAction } from "../../_models/redux/set-password/action";
import LoadingButton from "@mui/lab/LoadingButton";
import PasswordStrengthBar from "react-password-strength-bar";
import FormFooterComponent from "widgets/form-footer/FormFooterComponent";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { InputAdornment, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FormRecaptchaComponent from "../../utils/helpers/recaptcha/form-recaptcha-component";
import loginImage from "assets/images/login/login-image.png";
import logoImage from "assets/images/logo.png";
import { useLocation } from "react-router-dom";

const ResetPasswordPage = (props) => {
    let navigate = useNavigate();
    const location = useLocation();
    const { contactPhone, pin } = location.state || {};

    const { setPassword } = props;
    const { newPasswordObj } = setPassword || "undefined";
    const { response, message } = newPasswordObj;
    const { data, status } = response || "undefined";
    const { detail } = data || "undefined";
    const [loading, setLoading] = useState(false);
    const [confirmPwd, createSetPassword] = useState("");
    const [password, createPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
    const [reCaptchaToken, setReCaptchaToken] = useState();
    const [reCaptchaSubmit, setReCaptchaSubmit] = useState();
    const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_ENTERPRISE || "";
    const reCaptchaAction = "set_password";
    const reCaptchaValues = {
        reCaptchaToken,
        setReCaptchaToken,
        reCaptchaSubmit,
        setReCaptchaSubmit,
        reCaptchaKey,
        reCaptchaAction,
    };

    useEffect(() => {
        setReCaptchaSubmit();
        setReCaptchaToken();
    }, [newPasswordObj]);

    const handleClickShowPasswordRepeat = () =>
        setShowPasswordRepeat(!showPasswordRepeat);
    const handleMouseDownPasswordRepeat = () =>
        setShowPasswordRepeat(!showPasswordRepeat);

    if (message === "success") {
        // Borrar 'textFieldValue' del localStorage
        localStorage.removeItem("textFieldValue");

        // Navegar a la nueva ruta
        let setPasswordPath = `/reset-password-success-page`;
        navigate(setPasswordPath);
    }

    const formSchema = Yup.object().shape({
        password: Yup.string()
            .required("La contraseña es obligatoria")
            .min(8, "La contraseña debe tener 8 caracteres"),
        confirmPwd: Yup.string()
            .required("La contraseña es obligatoria")
            .oneOf([Yup.ref("password")], "Las contraseñas no coinciden")
            .matches(
                /^(?=.*\d).{8,}$/,
                "Mín. 8 caracteres entre números y letras"
            ),
    });
    const formOptions = { resolver: yupResolver(formSchema) };
    const { register, handleSubmit, value, getValues, formState } =
        useForm(formOptions);

    const { errors } = formState;

    const onSubmit = (creatingPassword) => {
        setLoading(true);
        //   creatingPassword.preventDefault();
        setReCaptchaSubmit({ status: "clicked", e: creatingPassword });
    };

    useEffect(() => {
        const { e } = reCaptchaSubmit || {};

        if (reCaptchaToken && e) {
            const { confirmPwd } = e;
            const post = {
                direct_number: contactPhone,
                pin: parseInt(pin),
                new_password: confirmPwd,
                recaptcha_token: reCaptchaToken,
            };

            props.setPasswordAction(post);
        }
    }, [reCaptchaToken]);

    useEffect(() => {
        if (status >= 400) {
            setLoading(false);
        }
    }, [setPassword]);

    return (
        <Grid container className={"signup-page"}>
            <Grid container className={"signup-component-container"}>
                <Grid container className={"signup-component"}>
                    <Grid item xs={12} mb={2}>
                        {" "}
                        <img
                            src={logoImage}
                            className={"logo-dalefon-image left"}
                        />
                    </Grid>
                    <Grid container display={"flex"} alignItems={"center"}>
                        <Grid item md={12} lg={6}>
                            <img
                                title="Sign Up Image"
                                alt="Sign Up Image"
                                src={loginImage}
                                className={"login-img"}
                            />
                        </Grid>
                        <Grid
                            item
                            md={12}
                            lg={6}
                            mt={{ xs: 5, md: 2 }}
                            className={"signup-form"}
                        >
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid
                                    container
                                    className={"signup-title-container flex"}
                                >
                                    <Grid item xs={12}>
                                        <h2 className={"set-password-title"}>
                                            Contraseña
                                        </h2>
                                        <p>
                                            Ingresa la nueva contraseña que
                                            utilizarás para iniciar sesión.
                                        </p>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    className={"signup-instruction-container"}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        className={"signup-field-container"}
                                    >
                                        <TextField
                                            {...register("password")}
                                            fullWidth
                                            helperText="Mín. 8 caracteres entre números y letras"
                                            id="password"
                                            label="Contraseña"
                                            variant="outlined"
                                            name="password"
                                            value={password}
                                            // type="password"
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            onChange={(e) => {
                                                createPassword(e.target.value);
                                            }}
                                            className={`form-control ${
                                                errors.password
                                                    ? "is-invalid"
                                                    : ""
                                            }`}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={
                                                                handleClickShowPassword
                                                            }
                                                            onMouseDown={
                                                                handleMouseDownPassword
                                                            }
                                                        >
                                                            {showPassword ? (
                                                                <VisibilityIcon />
                                                            ) : (
                                                                <VisibilityOffIcon />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <div className="invalid-feedback">
                                            {errors.password?.message}
                                        </div>
                                        {/* <span>
                                            {errors?.confirmPwd && (
                                                <p className="required-incidence">
                                                    {" "}
                                                    {errors.confirmPwd.message}
                                                </p>
                                            )}
                                        </span> */}
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        className={"signup-field-container"}
                                    >
                                        <TextField
                                            {...register("confirmPwd")}
                                            fullWidth
                                            id="confirmPassword"
                                            label="Confirmar contraseña"
                                            name="confirmPwd"
                                            value={confirmPwd}
                                            // type="password"
                                            type={
                                                showPasswordRepeat
                                                    ? "text"
                                                    : "password"
                                            }
                                            onChange={(e) => {
                                                createSetPassword(
                                                    e.target.value
                                                );
                                            }}
                                            className={`form-control ${
                                                errors.confirmPwd
                                                    ? "is-invalid"
                                                    : ""
                                            }`}
                                            InputProps={{
                                                // <-- This is where the toggle button is added.
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={
                                                                handleClickShowPasswordRepeat
                                                            }
                                                            onMouseDown={
                                                                handleMouseDownPasswordRepeat
                                                            }
                                                        >
                                                            {showPasswordRepeat ? (
                                                                <VisibilityIcon />
                                                            ) : (
                                                                <VisibilityOffIcon />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <div className="invalid-feedback">
                                            {errors.confirmPwd?.message}
                                        </div>
                                        <PasswordStrengthBar
                                            password={password}
                                            minLength={3}
                                            // type={
                                            //     showPasswordRepeat
                                            //         ? "text"
                                            //         : "password"
                                            // }
                                            onChangeScore={(
                                                score,
                                                feedback
                                            ) => {}}
                                        />
                                        {/* <span>
                                            {errors?.confirmPassword && (
                                                <p className="required-incidence">
                                                    {" "}
                                                    {
                                                        errors.confirmPassword
                                                            .message
                                                    }
                                                </p>
                                            )}
                                        </span> */}
                                    </Grid>
                                    {detail}
                                </Grid>
                                {/* <Grid container className={"signup-loader"}>
                        <CircularProgress
                            variant={variant}
                            value={progress}
                        />
                    </Grid> */}
                                <Grid item xs={12}>
                                    <LoadingButton
                                        className={"signup-button"}
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        //onClick={() => onSubmit(setLoading(true))}
                                        loading={loading}
                                        loadingPosition="center"
                                    >
                                        Continuar
                                    </LoadingButton>
                                    {/* <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        className={"signup-button"}
                                        // onClick={signUpContext.sendResetPassword}
                                    >
                                        Continuar
                                    </Button> */}
                                </Grid>
                                <FormRecaptchaComponent
                                    reCaptchaValues={reCaptchaValues}
                                />
                                {/* <Grid
                        item
                        container
                        xs={12}
                        className={"signup-response"}
                    >
                        <span
                            className={
                                signUpContext
                                    .signUpResponseCls.container ||
                                ""
                            }
                        >
                            <h5
                                className={
                                    signUpContext
                                        .signUpResponseCls.text || ""
                                }
                            >
                                {signUpContext.signUpResponse ||
                                    ""}
                            </h5>
                        </span>
                    </Grid> */}
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <FormFooterComponent />
        </Grid>
    );
};

// export default Modal;
const mapStateToProps = (state) => ({
    setPassword: state.setPassword,
});
const mapDispatchToProps = {
    setPasswordAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
