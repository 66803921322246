import { useEffect } from "react";

import { closeLoggedSession } from "_controllers/helpers/closeLoggedSession";

const LogoutPage = () => {
    useEffect(() => {
        closeLoggedSession("LogoutPage");
        return () => {};
    }, []);
    return null;
};

export default LogoutPage;
