import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Grid, SvgIcon, Button } from "@mui/material";

import signUpImage from "assets/images/login/signin-image.png";
import FormFooterComponent from "widgets/form-footer/FormFooterComponent";
import logoImage from "assets/images/logo.png";

import { ReactComponent as forgotPasswordErrorIcon } from "assets/icons/login/error.svg";

const ErrorSignUpPage = (props) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [errorNext, setErrorNext] = useState("");
    const [errorLast, setErrorLast] = useState("");

    const history = useNavigate();
    useEffect(() => {
        return () => {
            //action del clean
        };
    }, []);
    const goToSignUp = () => {
        history("/sign-up");
    };

    useEffect(() => {
        const { from } = props || { from: undefined };
        if (from == "signup-expired") {
            setErrorMessage("La liga expiró");
            setErrorNext("");
            setErrorLast("Ya puedes cerrar esta ventana");
        } else {
            setErrorMessage("No se pudo procesar la solicitud");
            setErrorNext("Inténtalo nuevamente");
            setErrorLast("");
        }
        return () => {
            //action del clean
        };
    }, []);
    return (
        <Grid container className={"signup-page"}>
            <Grid container className={"signup-component-container"}>
                <Grid
                    container
                    className={"signup-component"}
                    display={"flex"}
                    alignItems={"center"}
                >
                    <Grid item xs={12} mb={2}>
                        {" "}
                        <img
                            src={logoImage}
                            className={"logo-dalefon-image left"}
                        />
                    </Grid>
                    <Grid item md={12} lg={6}>
                        <img
                            title="Sign Up Error Image"
                            alt="Sign Up Password Error Image"
                            src={signUpImage}
                            className={"signup-img"}
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        lg={6}
                        mt={{ xs: 5, md: 2 }}
                        className={"signup-component-container"}
                    >
                        <Grid item xs={12} className={"signup-component"}>
                            <Grid container className={"signup-form no-form"}>
                                <Grid
                                    container
                                    className={"signup-icon-container"}
                                >
                                    <SvgIcon
                                        className={"signup-icon error-icon"}
                                        component={forgotPasswordErrorIcon}
                                        viewBox="0 0 60 60"
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    className={"signup-title-container"}
                                >
                                    <h3 className={"response-title error"}>
                                        {errorMessage}
                                    </h3>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className={"signup-text-container"}
                                >
                                    <p className={"signup-text custom-text"}>
                                        {errorNext}
                                    </p>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    className={
                                        "signup-text-container custom-text-container"
                                    }
                                >
                                    <p className={"signup-text"}>{errorLast}</p>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    className={"signup-button-container"}
                                >
                                    <Button
                                        variant="text"
                                        className={"signup-button"}
                                        onClick={goToSignUp}
                                    >
                                        Crear cuenta
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <FormFooterComponent />
            </Grid>
        </Grid>
    );
};

export default ErrorSignUpPage;
