import axiosTokenInstance from "services/api/service-client";

import {
    GET_PREPAID_CONFIRMATION,
    GET_PREPAID_CONFIRMATION_ERROR,
} from "_models/redux/types";

export const getPrepaidConfirmationAction = (params) => async (dispatch) => {
    const { uuid } = params || {};

    try {
        const response = await axiosTokenInstance.get(
            `/api/prepaids/v3/${uuid}/payment-status`
        );

        const { data } = response;

        dispatch({
            type: GET_PREPAID_CONFIRMATION,
            payload: data,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: GET_PREPAID_CONFIRMATION_ERROR,
            payload: e || {},
        });
    }
};
