import { GET_LEAD_OFFERS, GET_LEAD_OFFERS_ERROR } from "_models/redux/types";

import axiosAuthInstance from "services/api/authentication";

export const getLeadOffersAction = (params) => async (dispatch) => {
    try {
        const { lead_id } = params || {};

        const response = await axiosAuthInstance.get(
            `/api/recover/v2/leads/${lead_id}/get-offers`
        );

        const { data, status } = response || {};

        dispatch({
            type: GET_LEAD_OFFERS,
            status: status || {},
            payload: data,
        });
    } catch (e) {
        console.error(e);

        const { response } = e || {};
        const { data, status } = response || {};

        dispatch({
            type: GET_LEAD_OFFERS_ERROR,
            status: status || {},
            payload: data || {},
        });
    }
};
