import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Promotions from "./widgets/promotions";

const PromotionsPage = (props) => {
    return (
        <div>
            <Promotions />
        </div>
    );
};
export default PromotionsPage;
