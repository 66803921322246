import { NEWPASSWORD, NEWPASSWORD_ERROR } from "_models/redux/types";

const initialState = {
    newPasswordObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case NEWPASSWORD:
            return {
                ...state,
                newPasswordObj: action.payload,
                loading: true,
            };
        case NEWPASSWORD_ERROR:
            return {
                ...state,
                newPasswordObj: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}
