import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";

import { Grid, Button, TextField, CircularProgress } from "@mui/material";

import { VerificationPin, StatusType } from "react-verification-pin";
import NewPin from "../../../modules/sign-up/pages/new-pin";
import miniLogo from "assets/images/mini-logo.png";
import signUpImage from "assets/images/login/signin-image.png";
import FormFooterComponent from "widgets/form-footer/FormFooterComponent";
import { useForm } from "react-hook-form";
import { AppContext } from "_models/context/app";
import { validatePinAction } from "../../../_models/redux/validate-pin/action";
import { useNavigate } from "react-router-dom";
import logoImage from "assets/images/logo.png";

const SecurityCodeSignUpPage = (props) => {
    let navigate = useNavigate();
    const { validatePin } = props;
    const { validatePinObj } = validatePin || "undefined";
    const { response, message } = validatePinObj || "undefined";
    const { data } = response || "undefined";
    const { detail } = data || "undefined";
    const appContext = useContext(AppContext);
    const { pinCodeNumber, directNumber } = appContext;
    const { user_phone_number } = directNumber || "undefined";

    const [status, setStatus] = useState("process");
    const [pinCode, createPinCode] = useState("");

    if (message === "success") {
        let pathSuccess = `/sign-up/set-password`;
        navigate(pathSuccess);
    }

    const handleOnFinish = (code = pinCodeNumber) => {
        setStatus("wait");
        const post = {
            code,
            // directNumber,
            // user_phone_number,
        };
        if (user_phone_number != "undefined") {
            post.directNumber = directNumber;
        } else {
            post.user_phone_number = user_phone_number;
        }

        // setIsLoading(true);

        // simulating a search on your api
        if (code == pinCodeNumber) {
            setTimeout(() => {
                setStatus("success");
            }, 3000);
        } else {
            setTimeout(() => {
                setStatus("error");
            }, 3000);
        }
        props.validatePinAction(post);
    };

    const {
        register,
        handleSubmit,
        value,
        getValues,
        formState: { errors },
    } = useForm();

    const registerOptions = {
        pinCode: { required: "El pin es requerido" },
    };

    const onSubmit = (e) => {
        //   e.preventDefault();
        const post = {
            pinCode: pinCode,
        };
        // setIsLoading(true);
        // props.validatePinAction(post);
        // props.postIncidence(post);
    };

    const muestra = (e) => {};

    return (
        <Grid container className={"signup-page"}>
            <Grid container className={"signup-component-container"}>
                <Grid
                    container
                    className={"signup-component"}
                    display={"flex"}
                    alignItems={"center"}
                >
                    <Grid item xs={12} mb={2}>
                        {" "}
                        <img
                            src={logoImage}
                            className={"logo-dalefon-image left"}
                        />
                    </Grid>
                    <Grid item md={12} lg={6}>
                        <img
                            title="Security Code Image"
                            alt="Security Code Image"
                            src={signUpImage}
                            className={"signup-img"}
                        />
                    </Grid>

                    <Grid
                        item
                        md={12}
                        lg={6}
                        mt={{ xs: 5, md: 2 }}
                        className={"signup-form "}
                    >
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid
                                item
                                xs={12}
                                className={"signup-text-container set-password"}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    className={"signup-field-container pin"}
                                >
                                    <VerificationPin
                                        {...register(
                                            "pinCode",
                                            registerOptions.pinCode
                                        )}
                                        fullWidth
                                        type="number"
                                        inputsNumber={5}
                                        name="pinCode"
                                        status={status}
                                        value={pinCode}
                                        title="Código de seguridad"
                                        subTitle="Ingresa el
                                            código que hemos enviado a tu número
                                            celular."
                                        onChange={muestra}
                                        onFinish={handleOnFinish}
                                    />
                                    <Grid item xs={12}>
                                        <span>
                                            {errors?.pinCode && (
                                                <p className="required-incidence">
                                                    {" "}
                                                    {errors.pinCode.message}
                                                </p>
                                            )}
                                        </span>
                                    </Grid>
                                    <Grid container className="messages">
                                        <Grid item xs={12} md={8}>
                                            <span>
                                                <p className="required-incidence">
                                                    {detail}
                                                </p>
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <span>
                                                {status === "error" ? (
                                                    <p className="required-incidence">
                                                        <NewPin
                                                            phone={directNumber}
                                                        />
                                                    </p>
                                                ) : null}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} mb={2}>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    className={"signup-button"}
                                    // onClick={handleOnFinish}
                                >
                                    Continuar
                                </Button>
                            </Grid>
                        </form>
                    </Grid>
                    <FormFooterComponent />
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    validatePin: state.validatePin,
});
const mapDispatchToProps = {
    validatePinAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SecurityCodeSignUpPage);
