import { SHIPPINGADDRESS, SHIPPINGADDRESS_ERROR } from "_models/redux/types";

import axiosAuthInstance from "../../../services/api/authentication";

export const shippinghAddressAction = (params) => async (dispatch) => {
    const { address, contact_phone, neighborhood_id, tokenView } = params || "";

    try {
        const response = await axiosAuthInstance.patch(
            `/api/leads/steps/shipping-address`,
            { address, contact_phone, neighborhood_id },
            {
                headers: {
                    Authorization: `Bearer ${tokenView}`,
                },
            }
        );

        dispatch({
            type: SHIPPINGADDRESS,
            payload: response,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: SHIPPINGADDRESS_ERROR,
            payload: e || {},
        });
    }
};
