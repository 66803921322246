export const boxContainerTitle = {
    color: "#1e023e",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    mb: { xs: 1, md: 2 },
};

export const typographyTitle = {
    textAlign: "center",
    fontSize: { xs: 30, md: 50 },
    fontWeight: "bold",
    ml: { xs: 0.5, md: 1 },
    color: "white"
};

export const gridContainerButton = {
    mt: { xs: 1, md: 2 },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

export const typographyTermsAndConditions = {
    fontSize: { xs: 11, md: 14 },
    textAlign: "center",
};
