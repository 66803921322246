import React from "react";
import CodeOptionsToVerifyPinComponent from "utils/components/Common/CodeOptionsToVerifyPinComponent";
import { connect } from "react-redux";
import { forgotPasswordAction } from "_models/redux/forgot-password/action";
import { cleanForgotAction } from "_models/redux/forgot-password/clean";
import { useLocation } from "react-router-dom";
import { loginAccountsAction } from "_models/redux/login-account/action";
import {cleanAccountAction} from "_models/redux/login-account/clean";
export const ComponentselectsPinChannel = ({
    cleanAccountAction,
    forgotPasswordAction,
    forgotPassword,
    loginAccountsAction,
    loginAccount,
    cleanForgotAction,
}) => {
    const location = useLocation();
    const { textFieldValue, controllerNameStorage } = location.state || {};

    const controllerName = location.state ? location.state.controller : null;
    return (
        <>
            <CodeOptionsToVerifyPinComponent
                cleanAccountAction={cleanAccountAction}
                forgotPassword={forgotPassword}
                forgotPasswordAction={forgotPasswordAction}
                loginAccountsAction={loginAccountsAction}
                loginAccount={loginAccount}
                cleanForgotAction={cleanForgotAction}
                textFieldValue={textFieldValue}
                controllerName={controllerName}
                controllerNameStorage={controllerNameStorage}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    forgotPassword: state.forgotPassword,
    loginAccount: state.loginAccount,
});

const mapDispatchToProps = {
    forgotPasswordAction,
    cleanForgotAction,
    loginAccountsAction,
    cleanAccountAction
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComponentselectsPinChannel);
