import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Grid, SvgIcon, Button } from "@mui/material";

import logoImage from "assets/images/logo.png";
import signUpImage from "assets/images/login/signin-image.png";
import FormFooterComponent from "widgets/form-footer/FormFooterComponent";

import { ReactComponent as signUpSuccessIcon } from "assets/icons/login/success.svg";

const SuccessSignUpPage = () => {
    const history = useNavigate();
    useEffect(() => {
        return () => {
            //action del clean
        };
    }, []);
    const goToLogin = () => {
        history("/login");
    };
    return (
        <Grid container className={"signup-page"}>
            <Grid container className={"signup-component-container"}>
                <Grid
                    container
                    className={"signup-component"}
                    display={"flex"}
                    alignItems={"center"}
                >
                    <Grid item xs={12} mb={2}>
                        {" "}
                        <img
                            src={logoImage}
                            className={"logo-dalefon-image left"}
                        />
                    </Grid>
                    <Grid item md={12} lg={6}>
                        <img
                            title="Sign Up Success Image"
                            alt="Sign Up Success Image"
                            src={signUpImage}
                            className={"signup-img"}
                        />
                    </Grid>

                    <Grid
                        item
                        md={12}
                        lg={6}
                        mt={{ xs: 5, md: 2 }}
                        className={"signup-component-container"}
                    >
                        <Grid item xs={12} className={"signup-component"}>
                            <Grid container className={"signup-form no-form"}>
                                <Grid
                                    container
                                    className={"signup-icon-container"}
                                >
                                    <SvgIcon
                                        className={"signup-icon success-icon"}
                                        component={signUpSuccessIcon}
                                        viewBox="0 0 60 60"
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    className={"signup-title-container"}
                                >
                                    <h3 className={"response-title success"}>
                                        Tu cuenta se ha creado con éxito
                                    </h3>
                                </Grid>
                                <Grid
                                    container
                                    className={"signup-text-container"}
                                >
                                    <p className={"signup-text"}>
                                        Disfruta de los beneficios que Dalefon
                                        tiene para ti.
                                    </p>
                                </Grid>

                                <Grid
                                    container
                                    className={"signup-button-container"}
                                >
                                    <Button
                                        variant="text"
                                        className={"login-button"}
                                        onClick={goToLogin}
                                    >
                                        Iniciar sesión
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <FormFooterComponent />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SuccessSignUpPage;
