import {
    CLEAN_PROFILE_UPDATED,
    CLEAN_PROFILE_UPDATED_ERROR,
} from "_models/redux/types";

export const cleanCheckImeiAction = () => async (dispatch) => {
    try {
        dispatch({
            type: CLEAN_PROFILE_UPDATED,
            payload: [],
        });
    } catch (e) {
        dispatch({
            type: CLEAN_PROFILE_UPDATED_ERROR,
            payload: [],
        });
    }
};
