export const containerHomeHeader = {
    p: { xs: "0px 20px 0", md: "70px 100px 0" },
    textAlign: { xs: "center", md: "left" },
    color: "white",
};

export const gridContainerHomeHeader = {
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    pb: 5,
};

export const boxBr = {
    mb: -5,
    display: { xs: "block", lg: "none" },
};

export const spanPackagesActive = {
    color: "#34007a",
    fontWeight: "bold",
    background: "#ffffff",
    padding: "2px 16px 4px",
    borderRadius: "30px",
};

export const gridItemContainerImage = {
    display: { xs: "none", lg: "flex" },
    alignContent: "center",
    justifyContent: "center",
};
