import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import { Button, Grid } from "@mui/material";

import miniLogo from "assets/images/mini-logo.png";
import purchaseImage from "assets/images/purchase/purchase.png";
import FormFooterComponent from "widgets/form-footer/FormFooterComponent";
import { cleanCompleteProcessAction } from "../../../_models/redux/complete-process/clean";
import { completeProcessAction } from "../../../_models/redux/complete-process/action";
import LoadingButton from "@mui/lab/LoadingButton";

const ConfirmationPurchasePage = (props) => {
    let navigate = useNavigate();
    const { state } = useLocation();
    const { tokenView } = state || {};
    const { elementSelect, supportsDevices } = state || {};
    const { completeProcess, completeProcessAction } = props || {};
    const { completeProcessObj } = completeProcess || {};
    const errorMessageResponse = completeProcessObj?.response?.data;
    const successMessageData = completeProcessObj?.data;

    const [elementSelected, setElementSelected] = useState();
    const [loading, setLoading] = useState(false);
    const confirmPayment = () => {
        if (typeof completeProcessAction == "function") {
            completeProcessAction(state);
        }

        setLoading(true);
    };

    useEffect(() => {
        const { token, prepaidDataView, elementSelect } = state || {};

        const stateToken = typeof token != "undefined" ? token.state : {};

        setElementSelected(elementSelect);
    }, [state]);

    useEffect(() => {
        const { completeProcessObj, loading } = completeProcess || {};

        if (loading === true) {
            const { status } = completeProcessObj || {};

            goToByResponse("if(loading === true)", status, completeProcessObj);
        } else if (loading === false && completeProcessObj.length !== 0) {
            const { response } = completeProcessObj || {};
            const { status } = response || {};

            goToByResponse("if(loading === false)", status, completeProcessObj);
        } else {
        }
    }, [completeProcess]);

    const goToByResponse = (
        from = undefined,
        status = undefined,
        response = undefined
    ) => {
        setLoading(false);

        if (typeof status != "undefined" && status >= 200 && status < 300) {
            navigate(`/purchase/success`, {
                state: { successMessageData, supportsDevices },
            });
        } else if (typeof status != "undefined") {
            navigate(`/purchase/error`, {
                state: {
                    errorMessageResponse,
                    tokenView,
                    elementSelect,
                    supportsDevices,
                },
            });
        } else {
        }
    };

    useEffect(() => {
        return () => {
            props.cleanCompleteProcessAction();
        };
    }, []);

    return (
        <Grid container className={"main-page"}>
            <Grid item className={"main-component-container"}>
                <Grid container className={"main-component"}>
                    <Grid item className={"main-form"}>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            className={"main-image-container"}
                        >
                            <img src={purchaseImage} className={"main-image"} />
                        </Grid>
                        <Grid item xs={12} md={6} className="purchase-form">
                            <form>
                                <Grid
                                    item
                                    xs={12}
                                    className={"purchase-title-container flex"}
                                >
                                    <Grid item container xs={10}>
                                        <h2>Confirmación de compra</h2>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={2}
                                        className={"main-logo-container"}
                                    >
                                        <img
                                            title="Mini Logo"
                                            alt="Mini Logo"
                                            src={miniLogo}
                                            className={"main-logo"}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    className="centered confirmation-box"
                                >
                                    <div>
                                        <h3>Resumen</h3>
                                    </div>
                                    <Grid container className="line">
                                        <Grid item xs={6}>
                                            <span>Producto</span>
                                        </Grid>
                                        <Grid item xs={6} alignItems="end">
                                            <p>
                                                {typeof elementSelected !=
                                                "undefined"
                                                    ? elementSelected.name
                                                    : ""}
                                            </p>
                                        </Grid>
                                    </Grid>
                                    <Grid container className="line">
                                        <Grid item xs={6}>
                                            <span>Monto</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <p>
                                                ${" "}
                                                {typeof elementSelected !=
                                                "undefined"
                                                    ? elementSelected.amount
                                                    : ""}
                                            </p>
                                        </Grid>
                                    </Grid>

                                    <Grid container className="total line">
                                        <Grid item xs={6}>
                                            <span>Total:</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <p>
                                                ${" "}
                                                {typeof elementSelected !=
                                                "undefined"
                                                    ? elementSelected.amount
                                                    : ""}
                                            </p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        className={"signup-button"}
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        onClick={confirmPayment}
                                        loading={loading}
                                        loadingPosition="center"
                                    >
                                        Pagar
                                    </LoadingButton>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                    <FormFooterComponent />
                </Grid>
            </Grid>
        </Grid>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    completeProcess: state.completeProcess,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    completeProcessAction,
    cleanCompleteProcessAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfirmationPurchasePage);
