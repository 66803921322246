import React, { useEffect } from "react";

//Css:
import "assets/styles/css/portal/pages/invoice/invoice-download-prepaid.css";

//Material-UI:
import { Container, Grid, Typography } from "@mui/material";

//Images:
import imgPDF from "assets/images/portal/pages/invoice/invoice-download-prepaid/pdf.png";
import imgXML from "assets/images/portal/pages/invoice/invoice-download-prepaid/xml.png";

//React Router Dom:
import { useNavigate, useLocation } from "react-router-dom";

//Styles MUI:
import {
    containerFiles,
    containerInvoiceDownloadPrepaid,
    gridContainerButton,
    gridContainerFiles,
    gridItemFiles,
    typographySubtitle,
    typographyTitle,
} from "./styles-mui/invoiceDownloadPrepaidStylesMUI";

//Components:
import PortalFooter from "modules/portal/components/footer";
import PortalNavigation from "modules/portal/components/navigation";
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

function InvoiceDownloadPrepaidComponent() {
    let navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <PortalNavigation />
            <Container
                sx={containerInvoiceDownloadPrepaid}
                maxWidth={false}
                className="container-invoice-download-prepaid"
            >
                <Container>
                    <Grid container sx={{ textAlign: "center" }}>
                        <Grid item xs={12} sx={{ mb: 5 }}>
                            <Typography sx={typographyTitle}>
                                ¡Factura exitosa!
                            </Typography>
                            <Typography sx={typographySubtitle}>
                                Selecciona el formato en que deseas descargarla
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        sx={containerFiles}
                        className="container-files"
                    >
                        {state !== null
                            ? state.links.map((element, index) => (
                                  <Grid item xs={12} md={4} key={index}>
                                      <Grid container sx={gridContainerFiles}>
                                          <Grid item xs={6} sx={gridItemFiles}>
                                              <a href={element.pdf_url}>
                                                  <img
                                                      src={imgPDF}
                                                      className="img-file"
                                                      alt="Hoja en blanco con una etiqueta de PDF"
                                                  />
                                              </a>
                                          </Grid>
                                          <Grid item xs={6} sx={gridItemFiles}>
                                              <a href={element.xml_url}>
                                                  <img
                                                      src={imgXML}
                                                      className="img-file"
                                                      alt="Hoja en blanco con una etiqueta de XML"
                                                  />
                                              </a>
                                          </Grid>
                                      </Grid>
                                  </Grid>
                              ))
                            : null}
                    </Grid>

                    <Grid container sx={gridContainerButton}>
                        <Grid item xs={12} md={2.5}>
                            <CustomButtonComponent
                                text={"Volver a inicio"}
                                bgColor={"violet"}
                                height={"50px"}
                                onClick={() => navigate("/dashboard")}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Container>
            <PortalFooter />
        </>
    );
}

export default InvoiceDownloadPrepaidComponent;
