import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Grid, SvgIcon } from "@mui/material";
import logoImage from "assets/images/logo.png";
import resetPasswordExpiredImage from "assets/images/login/login-image.png";
import FormFooterComponent from "widgets/form-footer/FormFooterComponent";

import { ReactComponent as resetPasswordExpiredIcon } from "assets/icons/login/expired.svg";

const ExpiredResetPasswordPage = () => {
    const history = useNavigate();
    useEffect(() => {
        return () => {
            //action del clean
        };
    }, []);
    const goToForgotPassword = () => {
        history("/forgot-password");
    };
    return (
        <Grid item container xs={12} className={"reset-password-page"}>
            <Grid container className={"reset-password-component-container"}>
                <Grid
                    container
                    className={"reset-password-component"}
                    display={"flex"}
                    alignItems={"center"}
                >
                    <Grid item xs={12} mb={2}>
                        {" "}
                        <img
                            src={logoImage}
                            className={"logo-dalefon-image left"}
                        />
                    </Grid>
                    <Grid itemmd={12} lg={6}>
                        <img
                            title="Reset Password Expired Image"
                            alt="Reset Password Expired Image"
                            src={resetPasswordExpiredImage}
                            className={"login-img"}
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        lg={6}
                        mt={{ xs: 5, md: 2 }}
                        className={"reset-password-component-container"}
                    >
                        <Grid
                            item
                            xs={12}
                            className={"reset-password-component"}
                        >
                            <Grid
                                container
                                className={"reset-password-form no-form"}
                            >
                                <Grid
                                    container
                                    className={"reset-password-icon-container"}
                                >
                                    <SvgIcon
                                        className={
                                            "reset-password-icon expired-icon"
                                        }
                                        component={resetPasswordExpiredIcon}
                                        viewBox="0 0 30 30"
                                    />
                                </Grid>

                                <Grid
                                    container
                                    className={"reset-password-title-container"}
                                >
                                    <h3
                                        className={
                                            "reset-password-title response-title"
                                        }
                                    >
                                        ¡Ups!
                                    </h3>
                                </Grid>
                                <Grid
                                    container
                                    className={"response-text-container"}
                                >
                                    <p className={"reset-password-text"}>
                                        Tu enlace ha caducado, inténtalo
                                        nuevamente.
                                    </p>
                                </Grid>
                                <Grid
                                    container
                                    className={
                                        "reset-password-button-container"
                                    }
                                >
                                    <Button
                                        variant="text"
                                        className={"reset-password-button"}
                                        onClick={goToForgotPassword}
                                    >
                                        Reintentar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <FormFooterComponent />
            </Grid>
        </Grid>
    );
};

export default ExpiredResetPasswordPage;
