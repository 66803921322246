import { useState } from "react";
import { useForm } from "react-hook-form";

export const useGiftHook = (props) => {
    const { postGiftFormAction, cleanPostGiftFormAction, postGiftForm } =
        props || {};

    const reduxValues = {
        postGiftFormAction,
        cleanPostGiftFormAction,
        postGiftForm,
    };

    const {
        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const [customError, setCustomError] = useState();

    const formValues = {
        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        errors,

        customError,
        setCustomError,
    };

    const [pageLoaded, setPageLoaded] = useState();
    const stateValues = { pageLoaded, setPageLoaded };

    const reCaptchaCallback = () => {};

    const [reCaptchaToken, setReCaptchaToken] = useState();
    const [reCaptchaSubmit, setReCaptchaSubmit] = useState();

    const reCaptchaValues = {
        reCaptchaToken,
        setReCaptchaToken,
        reCaptchaSubmit,
        setReCaptchaSubmit,
        reCaptchaCallback,
    };

    const [formData, setFormData] = useState();

    const [loading, setLoading] = useState(false);

    const [formResponse, setFormResponse] = useState();

    const [loaderResponse, setLoaderResponse] = useState();

    const [sectionResponse, setSectionResponse] = useState("form");

    const responseValues = {
        formResponse,
        setFormResponse,
        sectionResponse,
        setSectionResponse,
    };

    const giftValues = {
        reduxValues,
        formValues,
        stateValues,

        reCaptchaValues,
        responseValues,

        loaderResponse,
        setLoaderResponse,

        formData,
        setFormData,

        loading,
        setLoading,
    };

    return { giftValues };
};
