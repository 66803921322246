import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { offerSelectionAction } from "_models/redux/offer-selection/action.js";
import { cleanOfferSelectionAction } from "_models/redux/offer-selection/clean";

import CardPrepaidComponent from "utils/components/cards/card-prepaid/CardPrepaidComponent";
import CardNoPlansComponent from "utils/components/cards/card-no-plans/CardNoPlansComponent";

import FacebookPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import TiktokPixel from "tiktok-pixel";
import { clarity } from "react-microsoft-clarity";

const ProductCardsItem = (props) => {
    /*** DEBUG pixels flag ***/
    const debugPixels = (process.env?.REACT_APP_DEBUG_PIXELS || "1") === "1";

    /*** GOOGLE pixels ***/
    const googlePixelId = process.env?.REACT_APP_PURCHASE_GOOGLE_PIXEL || "";
    const googlePixelOptions = {
        debug_mode: debugPixels,
    };
    const googlePixelTagOptions = {
        debug_mode: debugPixels,
    };

    /*** ***/

    /*** TIKTOK pixels  ***/
    const tiktokPixelId = process.env?.REACT_APP_PURCHASE_TIKTOK_PIXEL || "";
    const tiktokPixelAdvancedMatching = {};
    const tiktokPixelOptions = {
        debug: debugPixels,
    };

    /*** ***/

    /*** FACEBOOK pixels  ***/
    const facebookPixelId =
        process.env?.REACT_APP_PURCHASE_FACEBOOK_PIXEL || "";
    const facebookPixelAdvancedMatching = {};
    const facebookPixelOptions = {
        autoConfig: true,
        debug: debugPixels,
    };

    /*** ***/

    // /*** CLARITY initialize ***/
    // const bingPixelId = process.env?.REACT_APP_PURCHASE_CLARITY_PIXEL;

    let navigate = useNavigate();
    const { state } = useLocation();
    // FIX: supportsDevices -> supportsESim
    const { tokenView, supportsDevices, supportsDevicesModels } = state;

    const { offerSelection } = props;
    const { offerSelectionObj } = offerSelection || "undefined";
    const { data } = offerSelectionObj || "undefined";
    const { message } = data || "undefined";

    // FIX: Remove colorClass, recommendCls from parent
    const { category, element, colorClass, recommendCls } = props;
    const { sku, name, amount } = element;

    const styleCard =
        colorClass === 0 ? "Lite" : colorClass === 1 ? "Classic" : "Plus"; 

    useEffect(() => {
        if (tokenView === undefined) window.location.replace("/purchase");

        /*** GOOGLE initialize ***/
        ReactGA.initialize(
            googlePixelId,
            googlePixelOptions,
            googlePixelTagOptions
        );
        ReactGA.send("pageview");
        /*** ***/

        /*** TIKTOK initialize ***/
        TiktokPixel.init(
            tiktokPixelId,
            tiktokPixelAdvancedMatching,
            tiktokPixelOptions
        );
        TiktokPixel.pageView();
        /*** ***/

        /*** FACEBOOK initialize ***/
        FacebookPixel.init(
            facebookPixelId,
            facebookPixelAdvancedMatching,
            facebookPixelOptions
        );
        FacebookPixel.pageView();
        /*** ***/

        // /*** CLARITY initialize ***/
        // clarity.init(bingPixelId);
        // /*** ***/
    }, []);

    const [elementSelect, setElementSelect] = useState();

    useEffect(() => {
        if (offerSelection?.offerSelectionObj?.response?.status === 401) {
            navigate(`/purchase`);
        }
        goToNextStep();
    }, [offerSelection]);

    useEffect(() => {
        if (typeof elementSelect !== "undefined") {
            props.offerSelectionAction({ tokenView, sku });
        }
    }, [elementSelect]);

    const routeRecarga = () => {
        setElementSelect(element);
    };

    const goToNextStep = () => {
        if (message === "success") {
            if (typeof elementSelect != "undefined") {
                /*** GOOGLE pixel  ***/
                // Track the "add_to_cart" event
                ReactGA.event("ecommerce", "add_to_cart", {
                    currency: "MXN",
                    value: amount,
                    items: [
                        {
                            item_id: sku,
                            item_name: name,
                            price: amount,
                            quantity: 1,
                        },
                    ],
                });
                /*** ***/

                /*** TIKTOK pixel  ***/
                let ttParams = {
                    content_type: "product",
                    quantity: 1,
                    description: name,
                    content_id: sku,
                    currency: "MXN",
                    value: amount,
                };
                let ttTrackingOpt = {
                    // event_id: "1234", //OPTIONAL
                };
                TiktokPixel.track("AddToCart", ttParams, ttTrackingOpt);
                /*** ***/

                /*** FACEBOOK pixel  ***/
                let fbParams = {
                    // content_type: "product",
                    contents: 1,
                    content_category: name,
                    content_ids: sku,
                    currency: "MXN",
                    value: amount,
                };
                FacebookPixel.track("AddToCart", fbParams);

                /*** ***/

                if (supportsDevices || supportsDevicesModels === true) {
                    navigate(`/purchase/payment-method`, {
                        state: {
                            tokenView,
                            elementSelect,
                            supportsDevices,
                            supportsDevicesModels,
                        },
                    });
                } else {
                    navigate(`/purchase/user-address`, {
                        state: {
                            tokenView,
                            elementSelect,
                            supportsDevices,
                            supportsDevicesModels,
                        },
                    });
                }
            }
        } else {
            // FIX: Not implemented
        }
    };

    useEffect(() => {
        return () => {
            props.cleanOfferSelectionAction();
        };
    }, []);

    return element.is_monthly === false ? (
        <CardPrepaidComponent
            element={element}
            bestSelling={element.name === "Dale 239 Plus" ? true : false}
            routeRecarga={routeRecarga}
        />
    ) : element.is_monthly === true ? (
        <CardNoPlansComponent
            element={element}
            bestSelling={element.name === "Dale 3M Clásico" ? true : false}
            routeRecarga={routeRecarga}
            styleCard={styleCard}
        />
    ) : null;
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    offerSelection: state.offerSelection,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    offerSelectionAction,
    cleanOfferSelectionAction,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(ProductCardsItem);
