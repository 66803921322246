import { LISTRFC_CLEAN, LISRFC_CLEAN_ERROR } from "_models/redux/types";

export const cleanGetListRfcAction = () => async (dispatch) => {
    try {
        dispatch({
            type: LISTRFC_CLEAN,
            payload: [],
        });
    } catch (e) {
        dispatch({
            type: LISRFC_CLEAN_ERROR,
            payload: [],
        });
    }
};
