import React from "react";

import CustomRecaptchaComponent from "utils/recaptchaProvider";

import { useForgotPasswordController } from "./hook/useForgotPasswordController";
import { useLocation } from "react-router-dom";

//Material UI:
import { Box, Grid, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

//Images:
import forgotPasswordImage from "assets/images/login/login-image.png";

//Styles MUI:
import {
    boxContainerImage,
    boxCustomRecaptcha,
    boxFormTitle,
    containerCodeOptionsToVerifyPinForm,
    gridContainerCodeOptionsToVerifyPin,
    iconArrowBackIos,
    typographyFormTitle,
} from "./styles-mui/codeOptionsToVerifyPin";

//Components:
import CodeOptionsView from "./CodeOptionsComponent";
import BaseFirstStepsComponent from "../base-first-steps/BaseFirstStepsComponent";

const CodeOptionsToVerifyPinComponent = ({
    cleanAccountAction,
    loginAccountsAction,
    forgotPasswordAction,
    forgotPassword,
    cleanForgotAction,
    controllerName,
    controllerNameStorage,
    loginAccount,
}) => {
    const location = useLocation();
    const { state } = location || {};
    const { data, contactPhone, gettingType } = state || {};
    const { handleButtonClick, containerProps, isLoading } =
        useForgotPasswordController({
            cleanAccountAction,
            gettingType,
            forgotPasswordAction,
            forgotPassword,
            cleanForgotAction,
            textFieldValue123: contactPhone,
            loginAccountsAction,
            loginAccount,
            controllerName,
            controllerNameStorageNew: controllerNameStorage,
            data,
            contactPhone,
        });

    return (
        <BaseFirstStepsComponent>
            <Grid container sx={gridContainerCodeOptionsToVerifyPin}>
                <Grid item md={12} lg={"auto"}>
                    <Box sx={boxContainerImage}>
                        <img
                            title="Forgot Password Image"
                            alt="Forgot Password"
                            src={forgotPasswordImage}
                            className={"login-img"}
                        />
                    </Box>
                </Grid>

                <Grid
                    item
                    md={12}
                    lg={6}
                    xl={4}
                    sx={containerCodeOptionsToVerifyPinForm}
                >
                    <form noValidate autoComplete="off">
                        <Box sx={boxFormTitle}>
                            <a href="/sign-up">
                                <ArrowBackIosIcon sx={iconArrowBackIos} />
                            </a>
                            <Typography sx={typographyFormTitle}>
                                Recibe tu código de verificación
                            </Typography>
                        </Box>

                        <Typography sx={{ my: { xs: 2, md: 3 }, textAlign: "start" }}>
                            Elige cómo deseas recibir tu código.
                        </Typography>

                        <Grid container>
                            {isLoading ? (
                                <CircularProgress />
                            ) : (
                                <CodeOptionsView
                                    handleButtonClick={handleButtonClick}
                                />
                            )}
                            <span style={{ color: "red" }}>
                                {forgotPassword?.forgotPassword?.response?.data
                                    ?.detail ||
                                    loginAccount?.loginAccountObj?.response
                                        ?.data?.detail}
                            </span>
                        </Grid>

                        <Box sx={boxCustomRecaptcha}>
                            <CustomRecaptchaComponent
                                containerProps={containerProps}
                            />
                        </Box>
                    </form>
                </Grid>
            </Grid>
        </BaseFirstStepsComponent>
    );
};

export default CodeOptionsToVerifyPinComponent;
