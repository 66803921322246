import React from "react";
import { useNavigate } from "react-router-dom";

import { Grid, Button } from "@mui/material";

const LoginLinkComponent = () => {
    const history = useNavigate();
    const goToLogin = () => {
        history("/login");
    };

    return (
        <Grid container className={"login-link-container"}>
            <p className={"login-link-text"}>
                Si ya tienes una cuenta,
                <Button variant="text" onClick={goToLogin}>
                    <span>inicia sesión.</span>
                </Button>
            </p>
        </Grid>
    );
};

export default LoginLinkComponent;
