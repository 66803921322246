import React from "react";
import { Grid, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";

function Modal({ setOpenModal }) {
    return (
        <Grid container className="modalBackground">
            <Grid container xs={4} className="modalContainer">
                <Grid item xs={6} className="title">
                    <h3>Nueva incidencia</h3>
                </Grid>
                <Grid item xs={6} className="titleCloseBtn">
                    <button
                        onClick={() => {
                            setOpenModal(false);
                        }}
                    >
                        <CloseIcon />
                    </button>
                </Grid>

                <Grid item className="body">
                    <Box
                        component="form"
                        sx={{
                            "& .MuiTextField-root": {
                                m: 1,
                                width: "32ch",
                                margin: "25px 0",
                            },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                id="outlined-textarea"
                                label="Teléfono"
                                placeholder="Número Dalefon a 10"
                                multiline
                                type="tel"
                            />
                        </div>
                    </Box>
                </Grid>
                <Grid item className="footer">
                    <button
                        onClick={() => {
                            setOpenModal(false);
                        }}
                        id="cancelBtn"
                    >
                        Cancelar
                    </button>
                    <button>Crear</button>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Modal;
