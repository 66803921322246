import {
    CLEAN_INVOICE_AUTENTIFICATION,
    CLEAN_INVOICE_AUTENTIFICATION_ERROR,
} from "_models/redux/types";

export const cleanInvoiceAutentificationAction = () => async (dispatch) => {
    try {
        dispatch({
            type: CLEAN_INVOICE_AUTENTIFICATION,
            payload: [],
        });
    } catch (e) {
        dispatch({
            type: CLEAN_INVOICE_AUTENTIFICATION_ERROR,
            payload: [],
        });
    }
};
