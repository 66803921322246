export const failImageStyle = {
    width: "160px",
    height: "auto",
};

export const styleContainer = {
    color: "white",
    py: 5,
};

export const titleStyle = {
    fontFamily: "Poppins-Bold",
    fontSize: { xs: 30, md: 38 },
    my: 3,
};

export const legendStyle = {
    fontSize: 16,
    fontFamily: "Poppins-Regular",
    my: 4,
};

export const messageStyle = (style) => {
    let backgroundColor, color, border;

    if (style === "FAIL") {
        backgroundColor = "rgb(255, 235, 235)";
        color = "rgb(192, 19, 19)";
        border = "1px solid rgb(192, 19, 19)";
    } else if (style === "REQUESTED") {
        backgroundColor = "#FCF0D7";
        color = "#DBA24C";
        border = "1px solid #DBA24C";
    }

    return {
        backgroundColor,
        color,
        border,
        borderRadius: "10px",
        fontSize: "17px",
        fontFamily: "Poppins-Regular",
        padding: "25px",
        maxWidth: "80%",
        textAlign: "center",
    };
};

export const boxMessageContainer = {
    display: "flex",
    justifyContent: "center",
    mb: 4,
};
