import React from "react";

//Material UI:
import { Box, Typography } from "@mui/material";
import CopyrightIcon from "@mui/icons-material/Copyright";

//Css:
import "../../assets/styles/css/widgets/form-footer/form-footer.css";

const FormFooterComponent = () => {
    return (
        <Box
            className={"container-form-footer-text"}
            sx={{
                p: "40px 0px 10px 0px",
                textAlign: "center",
                width: "100%",
                bottom: 0,
            }}
        >
            <Typography
                sx={{ color: "white", fontSize: 14 }}
                className={"form-footer-text"}
            >
                <CopyrightIcon className={"copyright-icon"} />
                Dalefon {new Date().getFullYear()} Todos los derechos reservados
            </Typography>
        </Box>
    );
};

export default FormFooterComponent;
