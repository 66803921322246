import React from "react";
import { Grid } from "@mui/material";
import Logo from "../../../assets/images/logo.png";

const PortalFooter = () => {
    const yearNow = new Date().getFullYear();

    return (
        <Grid container>
            <Grid item>
                <a href="/login">
                    <img src={Logo} className={"invoice-logo"} />
                </a>
            </Grid>
        </Grid>
    );
};

export default PortalFooter;
