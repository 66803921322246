import {
    PREPAID_SUBSCRIPTION,
    PREPAID_SUBSCRIPTION_ERROR,
} from "_models/redux/types";

const initialState = {
    prepaidSubscriptiongObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PREPAID_SUBSCRIPTION:
            return {
                ...state,
                prepaidSubscriptiongObj: action.payload,
                loading: true,
            };
        case PREPAID_SUBSCRIPTION_ERROR:
            return {
                ...state,
                prepaidSubscriptiongObj: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}
