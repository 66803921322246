import React from "react";
import { Grid } from "@mui/material";
import Logo from "assets/images/logo.png";

const PortalFooter = () => {
    return (
        <Grid container>
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: {xs: "center", md: "start"},
                }}
            >
                <a href="/login">
                    <img src={Logo} className={"lead-logo"} alt="Logo de Dalefon"/>
                </a>
            </Grid>
        </Grid>
    );
};

export default PortalFooter;
