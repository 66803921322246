export const formInputs = (params) => {
    const {
        errors,
        registerOptions,
        handlers,
        cfdiUses,
        taxRegimes,
        taxTypeCardList,
    } = params || {};

    const { onFocusHandler, onChangeHandler } = handlers || {};
    const { cfdiUseOptions, cfdiOptionSelected, setcfdiOptionSelected } =
        cfdiUses || {};
    const { taxRegimeOptions, taxRegimeSelected, setTaxRegimeSelected } =
        taxRegimes || {};
    const { taxTypeCardOptions, taxTypeCardSelected, setTaxTypeCardSelected } =
        taxTypeCardList || {};

    return {
        inputs: [
            // {
            //     type: "text",
            //     id: "purchase_order_number",
            //     label: "Número de orden *",
            //     placeholder: "Número de orden",
            //     containerCls: "payment-input m-t-20",
            //     error: errors?.purchase_order_number,
            //     validations: registerOptions?.purchase_order_number,
            //     onFocusHandler: onFocusHandler,
            //     onChangeHandler: onChangeHandler,
            // },
            {
                type: "text",
                id: "receiver_legal_id",
                label: "RFC del receptor *",
                placeholder: "RFC",
                containerCls: "payment-input m-t-20",
                error: errors?.receiver_legal_id,
                validations: registerOptions?.receiver_legal_id,
                onFocusHandler: onFocusHandler,
                onChangeHandler: onChangeHandler,
            },
            {
                type: "text",
                id: "receiver_legal_name",
                label: "Razón social *",
                placeholder: "Razón social sin regimen capital",
                containerCls: "labels m-t-20",
                error: errors?.receiver_legal_name,
                validations: registerOptions?.receiver_legal_name,
                onFocusHandler: onFocusHandler,
                onChangeHandler: onChangeHandler,
            },
            {
                type: "select",
                cls: "form-control",
                id: "receiver_tax_regime",
                label: "Régimen fiscal *",
                placeholder: "Régimen fiscal",
                containerCls: "p-tb-20",
                onFocusHandler: onFocusHandler,
                onChangeHandler: onChangeHandler,
                validations: registerOptions?.receiver_tax_regime,
                error: errors?.receiver_tax_regime,
                options: taxRegimeOptions,
                value: taxRegimeSelected,
            },
            {
                type: "text",
                id: "receiver_legal_zipcode",
                label: "Código postal *",
                placeholder: "Código postal",
                containerCls: "labels m-tb-0",
                onFocusHandler: onFocusHandler,
                onChangeHandler: onChangeHandler,
                validations: registerOptions?.receiver_legal_zipcode,
                error: errors?.receiver_legal_zipcode,
            },
            {
                type: "select",
                cls: "form-control",
                id: "cfdi_use",
                label: "Uso de CFDI *",
                placeholder: "Uso de CFDI",
                containerCls: "p-tb-20",
                onFocusHandler: onFocusHandler,
                onChangeHandler: onChangeHandler,
                validations: registerOptions?.cfdi_use,
                error: errors?.cfdi_use,
                options: cfdiUseOptions,
                value: cfdiOptionSelected,
            },
            {
                type: "select",
                cls: "form-control",
                id: "payment_method_id",
                label: "Selecciona la forma de pago *",
                placeholder: "Selecciona la forma de pago",
                containerCls: "p-tb-20",
                onFocusHandler: onFocusHandler,
                onChangeHandler: onChangeHandler,
                validations: registerOptions?.receiver_tax_regime,
                error: errors?.receiver_tax_regime,
                options: taxTypeCardOptions,
                value: taxTypeCardSelected,
            },
            {
                type: "email",
                cls: "form-control",
                id: "email",
                label: "Correo electrónico",
                placeholder: "Correo electrónico (opcional)",
                containerCls: "labels m-b-30",
                onFocusHandler: onFocusHandler,
                onChangeHandler: onChangeHandler,
                validations: registerOptions?.email,
                error: errors?.email,
            },
        ],
    };
};

export default formInputs;
