import React, { useContext, useEffect } from "react";

// Material Ui:
import { Grid, Box, Typography, Container } from "@mui/material";

//recaptcha
import {
  GoogleReCaptchaProvider
} from "react-google-recaptcha-v3";
import ComponentHelp from "./ComponentHelp";

function ComponentShowInvoices() {
  const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_ENTERPRISE;

  return (
    <Container maxWidth="xxl" disableGutters>
      <GoogleReCaptchaProvider
        reCaptchaKey={reCaptchaKey}
        useEnterprise={true}
        container={{
          element: "recaptcha-show-invoices",
          parameters: {
            badge: "inline",
            theme: "dark",
          },
        }}
      >
        <ComponentHelp />
      </GoogleReCaptchaProvider>
    </Container>
  );
}

export default ComponentShowInvoices;
