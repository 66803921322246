import { useEffect, useState } from "react";

//React Router Dom:
import { useNavigate, useParams } from "react-router-dom";

//Images:
import imgFail from "assets/images/portal/pages/wbc-purchase/error-payment.png";
import imgPending from "assets/images/portal/pages/wbc-purchase/pago-pendiente.png";
import imgSuccess from "assets/images/portal/pages/wbc-purchase/success-payment.png";

//Pixels:
import FacebookPixel from "react-facebook-pixel";
import TiktokPixel from "tiktok-pixel";
import ReactGA from "react-ga4";

function useWbcPurchasePageHook(props) {
    const history = useNavigate();
    const params = useParams(props);

    const { uuid } = params || {};
    const { purchasePaymentStatus, getPurchasePaymentStatusAction } =
        props || {};
    const { purchasePaymentStatusObj } = purchasePaymentStatus || {};
    const { status, sim_type_id, landing_id } = purchasePaymentStatusObj || {};
    const {
        context,
        detail,
        title,
        additional_details,
        /* SUCCESS:  */
        product_name,
        total_amount,
        order_number,
    } = purchasePaymentStatusObj || {};
    const { exception, payment_order_status } = additional_details || {};
    const currency = "MXN";
    const infoProduct = [
        {
            title: "No. de orden",
            value: order_number,
        },
    ];

    const [loading, setLoading] = useState(true);
    const [dataContentWBCPurchase, setDataContentWBCPurchase] = useState({
        img: null,
        alt: "",
        note: "",
        id: "",
    });

    function changeContent(img, alt, note, id) {
        setDataContentWBCPurchase({
            img: img,
            alt: alt,
            note: note,
            id: id,
        });
        setLoading(false);
    }

    const renderPixels = (google, tiktok, facebook) => {
        if (google && tiktok && facebook) {
            /*** GOOGLE initialize ***/
            ReactGA.initialize(
                google.googlePixelId,
                google.googlePixelOptions,
                google.googlePixelTagOptions
            );

            ReactGA.send("pageview");
            /*** ***/

            /*** TIKTOK initialize ***/
            TiktokPixel.init(
                tiktok.tiktokPixelId,
                tiktok.tiktokPixelAdvancedMatching,
                tiktok.tiktokPixelOptions
            );
            TiktokPixel.pageView();
            /*** ***/

            /*** FACEBOOK initialize ***/
            FacebookPixel.init(
                facebook.facebookPixelId,
                facebook.facebookPixelAdvancedMatching,
                facebook.facebookPixelOptions
            );

            // clarity.init(bingPixelId);

            FacebookPixel.pageView();
            /*** ***/

            if (purchasePaymentStatusObj.view_count === 1) {
                /*** GOOGLE pixel ***/

                // Track the "purchase" event
                ReactGA.event("ecommerce", "purchase", {
                    currency: currency,
                    transaction_id: purchasePaymentStatusObj.product_sku,
                    value: total_amount,
                    items: 1,
                });
                /*** ***/

                /*** TIKTOK pixel  ***/
                let ttParams = {
                    content_type: "product",
                    quantity: 1,
                    description: product_name,
                    currency: currency,
                    content_id: purchasePaymentStatusObj.product_sku, // sku,
                    value: total_amount, // amount,
                };
                let ttTrackingOpt = {
                    // event_id: "1234", //OPTIONAL
                };
                TiktokPixel.track("CompletePayment", ttParams, ttTrackingOpt);
                /*** ***/

                /*** FACEBOOK pixel  ***/
                let fbParams = {
                    content_type: "product",
                    num_items: 1,
                    content_name: product_name,
                    currency: currency,
                    content_ids: purchasePaymentStatusObj.product_sku, // sku,
                    value: total_amount, // amount,
                };
                FacebookPixel.track("Purchase", fbParams);

                /*** ***/
            }
        }
    };

    useEffect(() => {
        getPurchasePaymentStatusAction({ uuid });
    }, [getPurchasePaymentStatusAction, uuid]);

    useEffect(() => {
        if (status) {
            const debugPixels =
                (process.env?.REACT_APP_DEBUG_PIXELS || "1") === "1";

            if (landing_id === 2) {
                /*** GOOGLE pixels ***/
                const purchaseGooglePixelId =
                    process.env?.REACT_APP_PURCHASE_GOOGLE_PIXEL || "";
                const purchaseGooglePixelOptions = {
                    debug_mode: debugPixels,
                };
                const purchaseGooglePixelTagOptions = {
                    debug_mode: debugPixels,
                };
                /*** ***/

                /*** TIKTOK pixels  ***/
                const purchaseTiktokPixelId =
                    process.env?.REACT_APP_PURCHASE_TIKTOK_PIXEL || "";
                const purchaseTiktokPixelAdvancedMatching = {};
                const purchaseTiktokPixelOptions = {
                    debug: debugPixels,
                };
                /*** ***/

                /*** FACEBOOK pixels  ***/
                const purchaseFacebookPixelId =
                    process.env?.REACT_APP_PURCHASE_FACEBOOK_PIXEL || "";
                const purchaseFacebookPixelAdvancedMatching = {};
                const purchaseFacebookPixelOptions = {
                    autoConfig: true,
                    debug: debugPixels,
                };
                /*** ***/
                // /*** CLARITY initialize ***/
                // const bingPixelId =
                //     process.env?.REACT_APP_PURCHASE_CLARITY_PIXEL;

                const purchasePixels = {
                    tiktok: {
                        tiktokPixelId: purchaseTiktokPixelId,
                        tiktokPixelAdvancedMatching:
                            purchaseTiktokPixelAdvancedMatching,
                        tiktokPixelOptions: purchaseTiktokPixelOptions,
                    },
                    facebook: {
                        facebookPixelId: purchaseFacebookPixelId,
                        facebookPixelAdvancedMatching:
                            purchaseFacebookPixelAdvancedMatching,
                        facebookPixelOptions: purchaseFacebookPixelOptions,
                    },
                    google: {
                        googlePixelId: purchaseGooglePixelId,
                        googlePixelOptions: purchaseGooglePixelOptions,
                        googlePixelTagOptions: purchaseGooglePixelTagOptions,
                    },
                };

                renderPixels(
                    purchasePixels.google,
                    purchasePixels.tiktok,
                    purchasePixels.facebook
                );

                changeContent(
                    imgSuccess,
                    "Persona con los ojo iluminados por estrellas dando a entender que está asombrado",
                    "",
                    "SUCCESS"
                );
            } else if (landing_id === 3) {
                /*** GOOGLE pixels ***/
                const comprasGooglePixelId =
                    process.env?.REACT_APP_COMPRA_GOOGLE_PIXEL || "";
                const comprasGooglePixelOptions = {
                    debug_mode: debugPixels,
                };
                const comprasGooglePixelTagOptions = {
                    debug_mode: debugPixels,
                };
                /*** ***/

                /*** TIKTOK pixels  ***/
                const comprasTiktokPixelId =
                    process.env?.REACT_APP_COMPRA_TIKTOK_PIXEL || "";
                const comprasTiktokPixelAdvancedMatching = {};
                const comprasTiktokPixelOptions = {
                    debug: debugPixels,
                };
                /*** ***/

                /*** FACEBOOK pixels  ***/
                const comprasFacebookPixelId =
                    process.env?.REACT_APP_COMPRA_FACEBOOK_PIXEL || "";
                const comprasFacebookPixelAdvancedMatching = {};
                const comprasFacebookPixelOptions = {
                    autoConfig: true,
                    debug: debugPixels,
                };
                /*** ***/

                // /*** CLARITY initialize ***/
                // const bingPixelId = process.env?.REACT_APP_COMPRA_CLARITY_PIXEL;

                const comprasPixels = {
                    tiktok: {
                        tiktokPixelId: comprasTiktokPixelId,
                        tiktokPixelAdvancedMatching:
                            comprasTiktokPixelAdvancedMatching,
                        tiktokPixelOptions: comprasTiktokPixelOptions,
                    },
                    facebook: {
                        facebookPixelId: comprasFacebookPixelId,
                        facebookPixelAdvancedMatching:
                            comprasFacebookPixelAdvancedMatching,
                        facebookPixelOptions: comprasFacebookPixelOptions,
                    },
                    google: {
                        googlePixelId: comprasGooglePixelId,
                        googlePixelOptions: comprasGooglePixelOptions,
                        googlePixelTagOptions: comprasGooglePixelTagOptions,
                    },
                };

                renderPixels(
                    comprasPixels.google,
                    comprasPixels.tiktok,
                    comprasPixels.facebook
                );

                changeContent(
                    imgSuccess,
                    "Persona con los ojo iluminados por estrellas dando a entender que está asombrado",
                    "",
                    "SUCCESS"
                );
            } else if (landing_id === 4) {
                history(`/app/cerrar`, {
                    state: {
                        uuid,
                        successMessageData: purchasePaymentStatusObj || {},
                        supportsDevices: sim_type_id === 2,
                    },
                });
            } else if (landing_id === 5) {
                changeContent(
                    imgSuccess,
                    "Persona con los ojo iluminados por estrellas dando a entender que está asombrado",
                    "",
                    "SUCCESS"
                );
            } else {
                changeContent(
                    imgFail,
                    "Persona asombrado con un icono de error de fondo",
                    "Tú pago no pudo ser procesado:",
                    "FAIL"
                );
            }
        } else if (payment_order_status === "FAIL") {
            changeContent(
                imgFail,
                "Persona asombrado con un icono de error de fondo",
                "Tú pago no pudo ser procesado:",
                "FAIL"
            );
        } else if (
            payment_order_status === "REQUESTED" ||
            payment_order_status === "PENDING"
        ) {
            changeContent(
                imgPending,
                "Persona guiñando el ojo",
                "Tu pago se encuentra pendiente debido al siguiente motivo:",
                "REQUESTED"
            );
        } else if (context === "server_error") {
            changeContent(
                imgFail,
                "Persona asombrado con un icono de error de fondo",
                detail ||
                    "Hubo un error al procesar tu pago, comuníquese a Dalefon.",
                "FAIL"
            );
        }
    }, [purchasePaymentStatus]);

    return {
        loading,
        dataContentWBCPurchase,
        status,
        sim_type_id,
        product_name,
        total_amount,
        detail,
        title,
        history,
        payment_order_status,
        infoProduct,
    };
}

export default useWbcPurchasePageHook;
