import { PRODUCTITEM, PRODUCTITEM_ERROR } from "_models/redux/types";

const initialState = {
    productItemgObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PRODUCTITEM:
            return {
                ...state,
                productItemgObj: action.payload,
                loading: true,
            };
        case PRODUCTITEM_ERROR:
            return {
                ...state,
                productItemgObj: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}
