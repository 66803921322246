export const containerFormPasswordAccountView = {
    my: { xs: 3, md: 2 },
    backgroundColor: "white",
    borderRadius: 10,
    px: { xs: 4, md: 6 },
};

export const boxFormTitle = {
    display: "flex",
    direction: "row",
    alignItems: "center",
    mt: 3,
};

export const iconArrowBackIos = { fontSize: 17, color: "#3f3851", mt: 1 };

export const typographyFormTitle = {
    ml: 1,
    color: "#434049",
    fontSize: { xs: 24, md: 28 },
    fontWeight: "bold",
};

export const formControlLabel = {
    textAlign: "start",
    ".MuiFormControlLabel-asterisk": {
        display: "none",
    },
};
