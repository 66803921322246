import React from "react";

//CSS:
import "assets/styles/css/utils/components/flows-products/components/content-no-plans-products.css";

// Material Ui:
import { Box } from "@mui/material";

// Swiper:
import { SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

//Images:
import Classic from "assets/images/products/clasicos-white.png";
import Lite from "assets/images/products/lite-white.png";
import Plus from "assets/images/products/plus-white.png";

//Components:
import AProductCardsItem from "modules/purchase/pages/widgets/productCardsItem";
import BProductCardsItem from "modules/compras/pages/widgets/productCardsItem";
import SectionCarouselProductComponent from "utils/components/section-carousel-product/SectionCarouselProductComponent";
import ProductCardsItemPrepaid from "modules/portal/pages/products/widgets/ProductCardsItemPrepaid";

function ContentNoPlansProductsComponents({
    classicSorted,
    plusSorted,
    products,
    flow,
}) {
    const ProductCard =
        flow === "prepaid"
            ? ProductCardsItemPrepaid
            : flow === "purchase"
            ? AProductCardsItem
            : BProductCardsItem;

    const swiperStyle = {
        height: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };
    return (
        <Box className="container-content-no-plans-products">
            {/*** NO Planes - LITE ***/}
            <Box sx={{ mt: 5, textAlign: "center" }}>
                <img
                    src={Lite}
                    alt="Titulo de los paquetes Lite"
                    className="img-title-no-plans"
                />
            </Box>
            <SectionCarouselProductComponent showButton={false}>
                {products?.map(
                    (
                        {
                            mobile_data,
                            mobile_data_roaming,
                            mobile_data_throttling,
                            is_monthly,
                            gigabyte_divisor,
                            ...rest
                        },
                        i
                    ) => {
                        const sumGigaBytes =
                            mobile_data +
                            mobile_data_roaming +
                            mobile_data_throttling;
                        const gigaBytes = sumGigaBytes / gigabyte_divisor;

                        return is_monthly && gigaBytes <= 15 ? (
                            <SwiperSlide key={rest.name} style={swiperStyle}>
                                <ProductCard
                                    key={i}
                                    index={i}
                                    colorClass={0}
                                    element={{
                                        mobile_data,
                                        mobile_data_roaming,
                                        mobile_data_throttling,
                                        is_monthly,
                                        ...rest,
                                    }}
                                    gigaBytesParent={gigaBytes}
                                    recommendCls={""}
                                />
                            </SwiperSlide>
                        ) : null;
                    }
                )}
            </SectionCarouselProductComponent>

            {/*** NO Planes - CLÁSICOS ***/}
            <Box sx={{ mt: 10, textAlign: "center" }}>
                <img
                    src={Classic}
                    alt="Titulo de los paquetes Classic"
                    className="img-title-no-plans"
                />
            </Box>
            <SectionCarouselProductComponent showButton={false}>
                {classicSorted?.map(
                    (
                        {
                            mobile_data,
                            mobile_data_roaming,
                            mobile_data_throttling,
                            ...rest
                        },
                        i
                    ) => {
                        const gigaBytes =
                            mobile_data +
                            mobile_data_roaming +
                            mobile_data_throttling;

                        return i === 0 ? (
                            <SwiperSlide key={rest.name} style={swiperStyle}>
                                <ProductCard
                                    key={i}
                                    index={i}
                                    colorClass={1}
                                    element={{
                                        mobile_data,
                                        mobile_data_roaming,
                                        mobile_data_throttling,
                                        ...rest,
                                    }}
                                    gigaBytesParent={gigaBytes}
                                    recommendCls=""
                                />
                            </SwiperSlide>
                        ) : null;
                    }
                )}

                {classicSorted?.map(
                    (
                        {
                            mobile_data,
                            mobile_data_roaming,
                            mobile_data_throttling,
                            product_duration,
                            ...rest
                        },
                        i
                    ) => {
                        const gigaBytes =
                            mobile_data +
                            mobile_data_roaming +
                            mobile_data_throttling;
                        const ProductCard =
                            flow === "prepaid"
                                ? ProductCardsItemPrepaid
                                : flow === "purchase"
                                ? AProductCardsItem
                                : BProductCardsItem;

                        return product_duration / 30 === 12 ? (
                            <SwiperSlide key={rest.name} style={swiperStyle}>
                                <ProductCard
                                    key={i}
                                    index={i}
                                    colorClass={1}
                                    element={{
                                        mobile_data,
                                        mobile_data_roaming,
                                        mobile_data_throttling,
                                        product_duration,
                                        ...rest,
                                    }}
                                    gigaBytesParent={gigaBytes}
                                    recommendCls="recommended"
                                />
                            </SwiperSlide>
                        ) : null;
                    }
                )}

                {classicSorted?.map(
                    (
                        {
                            mobile_data,
                            mobile_data_roaming,
                            mobile_data_throttling,
                            product_duration,
                            ...rest
                        },
                        i
                    ) => {
                        const gigaBytes =
                            mobile_data +
                            mobile_data_roaming +
                            mobile_data_throttling;
                        const ProductCard =
                            flow === "prepaid"
                                ? ProductCardsItemPrepaid
                                : flow === "purchase"
                                ? AProductCardsItem
                                : BProductCardsItem;

                        return product_duration / 30 !== 12 && i > 0 ? (
                            <SwiperSlide key={rest.name} style={swiperStyle}>
                                <ProductCard
                                    key={i}
                                    index={i}
                                    colorClass={1}
                                    element={{
                                        mobile_data,
                                        mobile_data_roaming,
                                        mobile_data_throttling,
                                        product_duration,
                                        ...rest,
                                    }}
                                    gigaBytesParent={gigaBytes}
                                    recommendCls=""
                                />
                            </SwiperSlide>
                        ) : null;
                    }
                )}
            </SectionCarouselProductComponent>

            {/*** NO Planes - PLUS ***/}
            <Box sx={{ mt: 10, textAlign: "center" }}>
                <img
                    src={Plus}
                    alt="Titulo de los paquetes Plus"
                    className="img-title-no-plans"
                />
            </Box>
            <SectionCarouselProductComponent showButton={false}>
                {plusSorted?.map(
                    (
                        {
                            mobile_data,
                            mobile_data_roaming,
                            mobile_data_throttling,
                            ...rest
                        },
                        i
                    ) => {
                        const gigaBytes =
                            mobile_data +
                            mobile_data_roaming +
                            mobile_data_throttling;
                        const ProductCard =
                            flow === "prepaid"
                                ? ProductCardsItemPrepaid
                                : flow === "purchase"
                                ? AProductCardsItem
                                : BProductCardsItem;

                        return i === 0 ? (
                            <SwiperSlide key={rest.name} style={swiperStyle}>
                                <ProductCard
                                    key={i}
                                    index={i}
                                    colorClass={2}
                                    element={{
                                        mobile_data,
                                        mobile_data_roaming,
                                        mobile_data_throttling,
                                        ...rest,
                                    }}
                                    gigaBytesParent={gigaBytes}
                                    recommendCls=""
                                />
                            </SwiperSlide>
                        ) : null;
                    }
                )}

                {plusSorted?.map(
                    (
                        {
                            mobile_data,
                            mobile_data_roaming,
                            mobile_data_throttling,
                            product_duration,
                            ...rest
                        },
                        i
                    ) => {
                        const gigaBytes =
                            mobile_data +
                            mobile_data_roaming +
                            mobile_data_throttling;
                        const ProductCard =
                            flow === "prepaid"
                                ? ProductCardsItemPrepaid
                                : flow === "purchase"
                                ? AProductCardsItem
                                : BProductCardsItem;

                        return product_duration / 30 === 12 ? (
                            <SwiperSlide key={rest.name} style={swiperStyle}>
                                <ProductCard
                                    key={i}
                                    index={i}
                                    colorClass={2}
                                    element={{
                                        mobile_data,
                                        mobile_data_roaming,
                                        mobile_data_throttling,
                                        product_duration,
                                        ...rest,
                                    }}
                                    gigaBytesParent={gigaBytes}
                                    recommendCls="recommended"
                                />
                            </SwiperSlide>
                        ) : null;
                    }
                )}

                {plusSorted?.map(
                    (
                        {
                            mobile_data,
                            mobile_data_roaming,
                            mobile_data_throttling,
                            product_duration,
                            ...rest
                        },
                        i
                    ) => {
                        const gigaBytes =
                            mobile_data +
                            mobile_data_roaming +
                            mobile_data_throttling;
                        const ProductCard =
                            flow === "prepaid"
                                ? ProductCardsItemPrepaid
                                : flow === "purchase"
                                ? AProductCardsItem
                                : BProductCardsItem;

                        return product_duration / 30 !== 12 && i > 0 ? (
                            <SwiperSlide key={rest.name} style={swiperStyle}>
                                <ProductCard
                                    key={i}
                                    index={i}
                                    colorClass={2}
                                    element={{
                                        mobile_data,
                                        mobile_data_roaming,
                                        mobile_data_throttling,
                                        product_duration,
                                        ...rest,
                                    }}
                                    gigaBytesParent={gigaBytes}
                                    recommendCls=""
                                />
                            </SwiperSlide>
                        ) : null;
                    }
                )}
            </SectionCarouselProductComponent>
        </Box>
    );
}

export default ContentNoPlansProductsComponents;
