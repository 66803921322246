import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Grid, SvgIcon } from "@mui/material";

import logoImage from "assets/images/logo.png";
import resetPasswordErrorImage from "assets/images/login/login-image.png";
import FormFooterComponent from "widgets/form-footer/FormFooterComponent";

import { ReactComponent as resetPasswordErrorIcon } from "assets/icons/login/error.svg";

const ErrorResetPasswordPage = () => {
    const history = useNavigate();
    useEffect(() => {
        return () => {
            //action del clean
        };
    }, []);
    const goToForgotPassword = () => {
        history("/forgot-password");
    };
    return (
        <Grid container className={"reset-password-page"}>
            <Grid container className={"reset-password-component-container"}>
                <Grid
                    container
                    className={"reset-password-component"}
                    display={"flex"}
                    alignItems={"center"}
                >
                    <Grid item xs={12} mb={2}>
                        {" "}
                        <img
                            src={logoImage}
                            className={"logo-dalefon-image left"}
                        />
                    </Grid>
                    <Grid item md={12} lg={6}>
                        <img
                            title="Reset Password Error Image"
                            alt="Reset Password Error Image"
                            src={resetPasswordErrorImage}
                            className={"login-img"}
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        lg={6}
                        mt={{ xs: 5, md: 2 }}
                        className={"reset-password-component-container"}
                    >
                        <Grid
                            item
                            xs={12}
                            className={"reset-password-component"}
                        >
                            <Grid
                                container
                                className={"reset-password-form no-form"}
                            >
                                <Grid
                                    container
                                    className={"reset-password-icon-container"}
                                >
                                    <SvgIcon
                                        className={
                                            "reset-password-icon error-icon"
                                        }
                                        component={resetPasswordErrorIcon}
                                        viewBox="0 0 60 60"
                                    />
                                </Grid>

                                <Grid
                                    container
                                    className={"reset-password-title-container"}
                                >
                                    <h3
                                        className={
                                            "reset-password-title response-title error red"
                                        }
                                    >
                                        Algo salió mal
                                    </h3>
                                </Grid>
                                <Grid
                                    container
                                    className={"response-text-container"}
                                >
                                    <p className={"reset-password-text"}>
                                        No se pudo restablecer la contraseña.
                                    </p>
                                </Grid>
                                <Grid
                                    container
                                    className={
                                        "reset-password-button-container"
                                    }
                                >
                                    <Button
                                        variant="text"
                                        className={"reset-password-button"}
                                        onClick={goToForgotPassword}
                                    >
                                        Reintentar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <FormFooterComponent />
            </Grid>
        </Grid>
    );
};

export default ErrorResetPasswordPage;
