import React from "react";

import {
    Button,
    Grid,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    TextField,
} from "@mui/material";

import miniLogo from "assets/images/mini-logo.png";
import activationImage from "assets/images/activation/activation.png";
import FormFooterComponent from "widgets/form-footer/FormFooterComponent";

const PortabilityActivationPage = () => {
    return (
        <Grid container className={"main-page"}>
            <Grid item className={"main-component-container"}>
                <Grid container className={"main-component"}>
                    <Grid item className={"main-form"}>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            className={"main-image-container"}
                        >
                            <img
                                src={activationImage}
                                className={"main-image"}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} className="activation-form">
                            <form>
                                <Grid
                                    item
                                    xs={12}
                                    className={
                                        "activation-title-container flex"
                                    }
                                >
                                    <Grid item container xs={10}>
                                        <h2>¿Deseas conservar tu número?</h2>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={2}
                                        className={"main-logo-container"}
                                    >
                                        <img
                                            title="Mini Logo"
                                            alt="Mini Logo"
                                            src={miniLogo}
                                            className={"main-logo"}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                        >
                                            <FormControlLabel
                                                value="no-portability"
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: "#434343",
                                                            "&.Mui-checked": {
                                                                color: "#FF28DD",
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="Quiero conservar mi número"
                                            />

                                            <div className="activation-text">
                                                <p>
                                                    {" "}
                                                    Con la SIM de tu compañía
                                                    anterior, marca al{" "}
                                                    <span>051</span> o envía un{" "}
                                                    <span>SMS</span> con la
                                                    palabra <span>NIP</span>.
                                                </p>
                                                <TextField
                                                    fullWidth
                                                    id="outlined-basic"
                                                    label="NIP"
                                                    variant="outlined"
                                                    className="m-tb-20"
                                                />
                                                <p>
                                                    Ingresa el número celular
                                                    que deseas conservar
                                                </p>
                                                <TextField
                                                    fullWidth
                                                    id="outlined-basic"
                                                    label="Número celular a 10 dígitos"
                                                    variant="outlined"
                                                    className="m-tb-20"
                                                />
                                            </div>

                                            <FormControlLabel
                                                value="portability"
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: "#434343",
                                                            "&.Mui-checked": {
                                                                color: "#FF28DD",
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="Quiero un número nuevo"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} className={"m-tb-20"}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        className={"main-button"}
                                    >
                                        Continuar
                                    </Button>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                    <FormFooterComponent />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PortabilityActivationPage;
