import { useEffect } from "react";

export const useLogin = (props = {}) => {
    const { login, loginAction } = props;

    const sendMethod = (data = {}) => {
        loginAction(data);
    };

    return { sendMethod };
};
