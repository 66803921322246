import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { Grid, CircularProgress } from "@mui/material";

import GiftActivationHeader from "../widgets/header";
import GiftIntroductionVideo from "../widgets/introduction";
import ActiveGift from "../widgets/active-gift";
import InactiveGift from "../widgets/inactive-gift";
import GiftSecondVideo from "../widgets/second-video";
import GiftActivationFooter from "../widgets/footer";
import GiftLoader from "../widgets/loader";

import { getLandingGiftAction } from "_models/redux/get-landing-gift/action";
import { cleanGetLandingGiftAction } from "_models/redux/get-landing-gift/clean";

const GiftContent = (props) => {
    const navigate = useNavigate();

    const { getLandingGift, getLandingGiftAction } = props || {};

    const { loaded, payload } = getLandingGift || {};

    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);

    const uuid = searchParams.get("uuid");
    const order = searchParams.get("order");

    const [activated, setActivated] = useState("none");

    useEffect(() => {
        if (uuid && order) {
            getLandingGiftAction({
                uuid,
                order,
            });
        }

        return () => {};
    }, []);

    useEffect(() => {
        if (loaded) {
            setActivated(true);

            if (!payload?.order_number) {
                navigate("/pagina-no-encontrada");

                navigate(`/pagina-no-encontrada`, {
                    state: {
                        payload,
                    },
                });
            }
        }
    }, [getLandingGift]);

    return (
        <Grid container className="gift-activation-container">
            {payload?.order_number ? (
                <Grid item xs={12}>
                    {activated !== "none" ? (
                        <>
                            <GiftActivationHeader giftDetails={payload} />
                        </>
                    ) : (
                        <GiftLoader />
                    )}
                </Grid>
            ) : uuid && order ? (
                <GiftLoader />
            ) : (
                <>
                    <h1>No se encontró la página</h1>
                </>
            )}
        </Grid>
    );
};

const mapStateToProps = (state) => ({ getLandingGift: state?.getLandingGift });

const mapDispatchToProps = {
    getLandingGiftAction,
    cleanGetLandingGiftAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftContent);
