export const usePrepaidAuthnInvoiceHandlers_ = ({
    dispatchPrepaidAuthnReducer,
}) => {
    console.log(
        "|=> usePrepaidAuthnInvoiceHandlers_ -> dispatchPrepaidAuthnReducer: ",
        dispatchPrepaidAuthnReducer
    );

    const cfdiUseOnFocusHandler = (e) => {
        console.log("|=> cfdiUseOnFocusHandler -> e: ", e);
    };
    const cfdiUseOnChangeHandler = (e) => {
        const { target } = e || {};
        const { value } = target || {};

        dispatchPrepaidAuthnReducer({
            property: "cdfiUseOptionSelected",
            value: value,
        });
    };

    const taxRegimeOnFocusHandler = (e) => {
        console.log("|=> taxRegimeOnFocusHandler -> e: ", e);
    };
    const taxRegimeOnChangeHandler = (e) => {
        const { target } = e || {};
        const { value } = target || {};

        dispatchPrepaidAuthnReducer({
            property: "taxRegimeOptionSelected",
            value: value,
        });
    };

    const cardTypeOnFocusHandler = (e) => {
        console.log("|=> cardTypeOnFocusHandler -> e: ", e);
    };
    const cardTypeOnChangeHandler = (e) => {
        const { target } = e || {};
        const { value } = target || {};

        dispatchPrepaidAuthnReducer({
            property: "cardTypeOptionSelected",
            value: value,
        });
    };

    const legalStoredOnFocusHandler = (e) => {
        console.log("|=> legalStoredOnFocusHandler -> e: ", e);
    };

    const legalStoredOnChangeHandler = (e) => {
        const { target } = e || {};
        const { value } = target || {};

        dispatchPrepaidAuthnReducer({
            property: "legalStoredSelected",
            value: value,
        });
    };

    return {
        cfdiUseOnFocusHandler,
        cfdiUseOnChangeHandler,
        taxRegimeOnFocusHandler,
        taxRegimeOnChangeHandler,
        cardTypeOnFocusHandler,
        cardTypeOnChangeHandler,

        legalStoredOnFocusHandler,
        legalStoredOnChangeHandler,

        onFocusHandler: () => {},
        onChangeHandler: () => {},
    };
};
