import React from "react";

//Material UI:
import {
    Grid,
    TextField,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Box,
    Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

//Redux:
import { connect } from "react-redux";
import { loginAccountsAction } from "../../../_models/redux/login-account/action";

//Styles MUI:
import {
    boxFormTitle,
    containerFormPasswordAccountView,
    formControlLabel,
    iconArrowBackIos,
    typographyFormTitle,
} from "./styles-mui/formPasswordAccountViewStyleMUI";

//CSS:
import "../../../assets/styles/css/sign-up/components/form-password-account-view.css";

//Components:
import LoginLinkComponent from "widgets/login/component";
import { usePasswordAcountController1 } from "../usePasswordAcountController";
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

function FormPasswordAccountViewComponent({
    loginAccount,
    loginAccountsAction,
}) {
    const { loginAccountObj } = loginAccount;
    const { response, data } = loginAccountObj || {};

    const {
        direct_number,
        name,
        last_name,
        mothers_last_name,
        email,
        loading,
        checkedConditions,
        dateOfBirth,
        gender,
        dateError,
        handleChangeConditions,
        registerOptions,
        setDirectNumber,
        setName,
        setMothers_last_name,
        setEmail,
        handleSubmit,
        onSubmit,
        errors,
        setDateOfBirth,
        setGender,
        setDateError,
        detail,
        register,
        createLast_name,
    } = usePasswordAcountController1({
        loginAccountObj,
        response,
        data,
        loginAccountsAction,
        loginAccount,
    });
    return (
        <Grid
            item
            xs={12}
            lg={6}
            xl={4}
            sx={containerFormPasswordAccountView}
            className={"container-form-password-account-view"}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={boxFormTitle}>
                    <a href="/client">
                        <ArrowBackIosIcon sx={iconArrowBackIos} />
                    </a>
                    <Typography sx={typographyFormTitle}>
                        Crea tu cuenta
                    </Typography>
                </Box>

                <Grid container>
                    <Typography sx={{ my: { xs: 2, md: 3 } }}>
                        Ingresa tus datos para{" "}
                        <Typography
                            variant="span"
                            sx={{ color: "#FD45D9", fontWeight: "bold" }}
                        >
                            registrarte
                        </Typography>{" "}
                        en Dalefon.
                    </Typography>
                    
                    <Grid item xs={12} sx={{ mb: 2 }}>
                        <TextField
                            fullWidth
                            {...register("name", registerOptions.name)}
                            id="outlined-textarea"
                            label="Nombre"
                            placeholder="Nombre"
                            value={name}
                            onChange={(event) => {
                                setName(event.target.value);
                            }}
                        />
                        <span>
                            {errors?.name && (
                                <p className="required-incidence">
                                    {" "}
                                    {errors.name.message}
                                </p>
                            )}
                        </span>
                    </Grid>

                    <Grid container spacing={{ xs: 0, md: 3 }}>
                        <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                            <TextField
                                fullWidth
                                {...register(
                                    "last_name",
                                    registerOptions.last_name
                                )}
                                id="outlined-required"
                                label="Apellido paterno"
                                placeholder="Apellido paterno"
                                value={last_name}
                                onChange={(event) => {
                                    createLast_name(event.target.value);
                                }}
                            />
                            <span>
                                {errors?.last_name && (
                                    <p className="required-incidence">
                                        {" "}
                                        {errors.last_name.message}
                                    </p>
                                )}
                            </span>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                            <TextField
                                {...register(
                                    "mothers_last_name",
                                    registerOptions.mothers_last_name
                                )}
                                fullWidth
                                id="outlined-required"
                                label="Apellido materno"
                                placeholder="Apellido materno"
                                value={mothers_last_name}
                                onChange={(event) => {
                                    setMothers_last_name(event.target.value);
                                }}
                            />
                            <span>
                                {errors?.mothers_last_name && (
                                    <p className="required-incidence">
                                        {" "}
                                        {errors.mothers_last_name.message}
                                    </p>
                                )}
                            </span>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ mb: 2 }}>
                        <TextField
                            fullWidth
                            {...register(
                                "direct_number",
                                registerOptions.direct_number
                            )}
                            id="outlined-required"
                            label="Número celular"
                            placeholder="Número Dalefon a 10 dígitos"
                            value={direct_number}
                            onChange={(event) => {
                                setDirectNumber(event.target.value);
                            }}
                        />
                        <span>
                            {errors?.direct_number && (
                                <p className="required-incidence">
                                    {" "}
                                    {errors.direct_number.message}
                                </p>
                            )}
                        </span>
                    </Grid>

                    <Grid container spacing={{ xs: 0, md: 3 }}>
                        <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                            <TextField
                                {...register(
                                    "dateOfBirth",
                                    registerOptions.dateOfBirth
                                )}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                id="date-of-birth"
                                label="Fecha de Nacimiento"
                                type="date"
                                value={dateOfBirth}
                                onChange={(event) => {
                                    const selectedDate = new Date(
                                        event.target.value
                                    );
                                    const maxDate = new Date();
                                    maxDate.setFullYear(
                                        maxDate.getFullYear() - 100
                                    );

                                    if (
                                        selectedDate > new Date() ||
                                        selectedDate < maxDate
                                    ) {
                                        setDateError("La fecha no es válida.");
                                        setDateOfBirth("");
                                    } else {
                                        setDateError("");
                                        setDateOfBirth(event.target.value);
                                    }
                                }}
                            />
                            <span>
                                {errors?.dateOfBirth && (
                                    <p className="required-incidence">
                                        {" "}
                                        {errors.dateOfBirth.message}
                                    </p>
                                )}
                            </span>
                            {dateError && (
                                <p className="required-incidence">
                                    {dateError}
                                </p>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                            <TextField
                                {...register("gender", registerOptions.gender)}
                                select
                                fullWidth
                                id="gender"
                                label="Género"
                                value={gender}
                                onChange={(event) =>
                                    setGender(event.target.value)
                                }
                            >
                                <MenuItem value="M">Masculino</MenuItem>
                                <MenuItem value="F">Femenino</MenuItem>
                                <MenuItem value="X">No binario</MenuItem>
                                <MenuItem value="-">
                                    Prefiero no responder
                                </MenuItem>
                            </TextField>
                            <span>
                                {errors?.gender && (
                                    <p className="required-incidence">
                                        {" "}
                                        {errors.gender.message}
                                    </p>
                                )}
                            </span>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ mb: 2 }}>
                        <TextField
                            fullWidth
                            {...register("email", registerOptions.email)}
                            id="outlined-textarea"
                            label="Correo electrónico (opcional)"
                            placeholder="Correo electrónico (opcional)"
                            value={email}
                            onChange={(event) => {
                                setEmail(event.target.value);
                            }}
                        />
                        <span>
                            {errors?.email && (
                                <p className="required-incidence">
                                    {" "}
                                    {errors.email.message}
                                </p>
                            )}
                        </span>
                    </Grid>

                    <FormControlLabel
                        required
                        sx={formControlLabel}
                        control={
                            <Checkbox
                                checked={checkedConditions}
                                onChange={handleChangeConditions}
                                color="secondary"
                            />
                        }
                        label={
                            <h4 className={"terms-conditions-form-password"}>
                                <span> Acepto los </span>
                                <a
                                    href="https://dalefon.mx/terminos-y-condiciones/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    términos y condiciones
                                </a>
                                <span> y el </span>
                                <a
                                    href="https://dalefon.mx/aviso-de-privacidad"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    aviso de privacidad*
                                </a>
                            </h4>
                        }
                    />

                    <span>
                        <p className="required-incidence">{detail}</p>
                    </span>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sx={{ mt: { xs: 0, md: 2 }, mb: { xs: 2, md: 2 } }}
                >
                    <CustomButtonComponent
                        async={true}
                        text="Continuar"
                        bgColor="violet"
                        height="60px"
                        loading={loading}
                    />
                </Grid>

                <LoginLinkComponent />
            </form>
        </Grid>
    );
}

// export default Modal;
const mapStateToProps = (state) => ({
    loginAccount: state.loginAccount,
});
const mapDispatchToProps = {
    loginAccountsAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormPasswordAccountViewComponent);
