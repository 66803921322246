import axiosTokenInstance from "services/api/service-client";

import { MYLEGALS_ACTION, MYLEGALS_ACTION_ERROR } from "_models/redux/types";

// Action function
export const getMyLegalsAction = () => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `/api/clients/invoices/legal-data`
        );

        const { data, status } = response || {};

        dispatch({
            type: MYLEGALS_ACTION,
            status: status || {},
            payload: data || {},
        });
    } catch (e) {
        const { response } = e || {};
        const { data, status } = response || {};

        dispatch({
            type: MYLEGALS_ACTION_ERROR,
            status: status || {},
            payload: data || {},
        });
    }
};
