import { POST_GIFT_FORM, POST_GIFT_FORM_ERROR } from "_models/redux/types";

import axiosAuthInstance from "services/api/authentication";

export const postGiftFormAction = (params) => async (dispatch) => {
    const { uuid, order, postData } = params || {};

    try {
        const response = await axiosAuthInstance.post(
            `/api/payments/${uuid}/orders/${order}/gifts/register`,
            postData || {}
        );

        const { data, status } = response || {};

        dispatch({
            type: POST_GIFT_FORM,
            status: status || {},
            payload: data,
        });
    } catch (e) {
        console.error(e);

        const { response } = e || {};
        const { data, status } = response || {};

        dispatch({
            type: POST_GIFT_FORM_ERROR,
            status: status || {},
            payload: data || {},
        });
    }
};
