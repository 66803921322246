import React, { useState } from "react";
import { AppContext } from "_models/context/app";
import RouterComponent from "router/component";

function App() {
    const [appUser, setAppUser] = useState();
    const [userName, setUserName] = useState();
    const [appAccountInfo, setAppAccountInfo] = useState();

    const [directNumber, setDirectNumber] = useState();
    const [pinCodeNumber, setpinCodeNumber] = useState();
    const [paymentObject, setPaymentObject] = useState();

    const [paymentResponse, setPaymentResponse] = useState();

    const [pressed, setPressed] = useState();

    const appValues = {
        appUser,
        setAppUser,

        userName,
        setUserName,

        appAccountInfo,
        setAppAccountInfo,

        directNumber,
        setDirectNumber,
        pinCodeNumber,
        setpinCodeNumber,
        paymentObject,
        setPaymentObject,

        paymentResponse,
        setPaymentResponse,

        pressed,
        setPressed,
    };

    const appEnv = process.env.REACT_APP_ENV;

    return (
        <AppContext.Provider value={appValues}>
            <div className="app">
                {appEnv !== "PROD" ? (
                    <div className="no-prod">
                        AMBIENTE &nbsp; <u>NO - PRODUCTIVO</u> &nbsp;
                        <i>({appEnv})</i>
                    </div>
                ) : null}

                <main className="app-main">
                    <header className="app-main-header"></header>
                    <section>
                        <RouterComponent />
                    </section>
                    <footer className="app-main-footer"></footer>
                </main>
            </div>
        </AppContext.Provider>
    );
}

export default App;
